import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileEventHeaderStyles = makeStyles((theme: any) =>
  createStyles({
    eventName: {
      color: "white",
      display: "inline-block",
      width: "90%",
      height: 50,
      position: "relative",
      top: -59,
      left: 61
    },
    eventNamePostIntake: {
      width: "75%"
    },
    textFit: {
      position: "relative",
      left: 68,
      maxHeight: 50,
      textAlign: "left",
      "& div": {
        maxHeight: 50,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    eventDate: {
      color: "white",
      fontSize: 14,
      top: 2,
      position: "relative",
      fontWeight: 500,
      zIndex: 10,
    },
    expandIconButton: {
      position: "absolute",
      right: 11,
    },
    errorMessageHolder: {
      width: "95%",
      backgroundColor: "black",
      minHeight: 50,
      height: "auto",
      display: "inline-block",
      textAlign: "center",
      position: "relative",
      top: 56,
      marginBottom: 3,
      zIndex: 10,
    },
    errorMessage: {
      fontSize: 14,
      position: "relative",
      top: 14,
      marginBottom: 24,
      marginRight: 15,
      marginLeft: 15,
    },
    errorIcon: {},
  })
);

export { MobileEventHeaderStyles };