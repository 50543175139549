import React, { useContext, useEffect, useState } from "react";
import ChatIcon from "@material-ui/icons/Chat";
import { Button, TextField, Typography } from "@material-ui/core";

import {
  MobileContext,
  MobileContextType,
} from "../../../../context/MobileContext";

import styles from "./MobileChatNewMessage.module.css";
import { MobileChatNewMessageStyles } from "./MobileChatNewMessageStyles";

type Props = {};

var Filter = require("bad-words");

export default function MobileChatNewMessage({}: Props) {
  const { setShowNewMessage, showNewMessage }: MobileContextType = useContext(MobileContext);

  const [chatMessage, setChatMessage] = useState("");

  let filter = new Filter();

  useEffect(() => {
    //If showNewMessage is false, ensure text field is not visible
    if (!showNewMessage) {
      let textField = document.getElementById("mobileNewMessageTextField");

      if (textField) {
        textField.style.visibility = "hidden";
      }
    }
  }, [showNewMessage])

  const handleChatMessageChange = (e) => {
    setChatMessage(e.target.value);
  };

  const handleChatMessageSubmit = (e) => {
    e.preventDefault();
    
    //Prevent submitting a default text field
    if (chatMessage === "") return;

    //Submit message to server
    SHOWBOAT.SocketIOController.SendEventAll("chat", {
      msg: filter.clean(chatMessage),
      color: SHOWBOAT.LocalAvatarDataManager.avatarData.color,
      face: SHOWBOAT.LocalAvatarDataManager.avatarData.face,
      name: `${SHOWBOAT.LocalAvatarDataManager.avatarData.firstName} ${SHOWBOAT.LocalAvatarDataManager.avatarData.lastName}`,
      date: new Date(),
    });

    setChatMessage("");

    //Un-focus the text field
    let textField = document.getElementById("mobileNewMessageTextField");
    if (textField) {
      textField.blur();
    }

    setShowNewMessage(false);
  };

  const handleDismissClick = () => {
    setChatMessage("");
    setShowNewMessage(false);
  };

  const classes = MobileChatNewMessageStyles();

  return (
    <div 
      className={styles.mobileChatNewMessageWrapper}
      style={{
        visibility: showNewMessage ? "visible" : "hidden"
      }}
    >
      <div className={styles.newMessageHeader}>
        <ChatIcon style={{ color: "white", marginRight: "14px" }} />

        <Typography className={classes.newMessageHeader}>
          NEW EVENT CHAT
        </Typography>
      </div>

      <Typography className={classes.newMessageHelperText}>
        Chat will be seen by everyone
      </Typography>

      <TextField
        multiline
        rows={3}
        value={chatMessage}
        variant="outlined"
        onChange={handleChatMessageChange}
        className={classes.chatTextField}
        placeholder="Send a message"
        inputProps={{
          maxLength: 240,
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleChatMessageSubmit(e);
          }
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.noBorder,
            root: classes.chatInput,
            inputAdornedEnd: classes.chatInputAdornedEnd,
          },
          id: "mobileNewMessageTextField",
          type: "text",
        }}
      />

      <Typography variant="body1" className={classes.characterCount}>
        {`${chatMessage.length} / 240`}
      </Typography>

      <div className={styles.buttonHolder}>
        <Button
          className={classes.chatButton}
          onClick={handleChatMessageSubmit}
        >
          SEND
        </Button>
        <Button
          className={`${classes.chatButton} ${classes.chatButtonDismiss}`}
          onClick={handleDismissClick}
        >
          DISMISS
        </Button>
      </div>
    </div>
  );
}
