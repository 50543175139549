import React, { useState, useRef, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { QAContext } from "../context/QAContext";
import TimeoutErrorPage from "../Intake/Pages/TimeoutErrorPage/TimeoutErrorPage";
import { AdvancedLoggingHelper } from "../Utilities/AdvancedLoggingHelper";
import { ARSHelper } from "../Utilities/ARSHelper";
import { MobilePresenterStreamHelper } from "../Utilities/MobilePresenterStreamHelper";
import { PresenterHelper } from "../Utilities/PresenterHelper";
import { ServerHelper } from "../Utilities/ServerHelper";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";
import { TestHelper } from "../Utilities/TestHelper";
import { TwoDHelper } from "../Utilities/TwoDHelper";
import { UIHelper } from "../Utilities/UIHelper";
import { UserPermissionsHelper } from "../Utilities/UserPermissionsHelper";

//Function for setting CSS rules
function setStyleRule(selector, rule, sheetName) {
  var sheets = document.styleSheets,
    stylesheet = sheets[sheets.length - 1];

  for (var i in document.styleSheets) {
    if (sheets[i].href && sheets[i].href.indexOf(sheetName + ".css") > -1) {
      stylesheet = sheets[i];
      break;
    }
  }

  if ((stylesheet as any).addRule) (stylesheet as any).addRule(selector, rule);
  else if ((stylesheet as any).insertRule)
    (stylesheet as any).insertRule(
      selector + " { " + rule + " }",
      (stylesheet as any).cssRules.length
    );
}

const useLoadApp = () => {
  const { handleGetAllQuestions, setAttendeesSeeAllQs, setAllowAnonymousQs } =
    useContext(QAContext);

  const [isIntakeCompleted, toggleIsIntakeCompleted] = useState(
    ServerHelper.useUIDevelopment ? true : false
  );
  const [isJoinCompleted, toggleIsJoinCompleted] = useState(
    ServerHelper.useUIDevelopment ? true : false
  );

  const isDroneModeActive = useRef(false);

  useEffect(() => {
    return function cleanup() {
      document.removeEventListener("keypress", handleDroneKeyPress);
    };
  }, []);

  const handleDroneKeyPress = (e) => {
    if (e.key === "=") {
      //Check if drone mode is allowed
      if (
        (window as any).allowDroneMode !== undefined &&
        (window as any).allowDroneMode === true
      ) {
        if (isDroneModeActive.current === false) {
          setStyleRule("*", "cursor: none !important", "App.css");

          isDroneModeActive.current = true;
        } else {
          setStyleRule("*", "cursor: auto !important", "App.css");

          isDroneModeActive.current = false;
        }
      }
    }
  };

  const loadApp = async () => {
    //toggle intake completed
    toggleIsIntakeCompleted(true);

    //Connect to the socket
    let connectionResult: boolean = false;
    try {
      connectionResult = await ServerHelper.Connect();
    } catch (err) {
      SHOWBOAT.Logger.Log(
        "Exception error connecting to showboat websocket server event"
      );
      SHOWBOAT.Logger.Error(err);

      UIHelper.SendErrorLog(
        "Exception error connecting to Showboat application server."
      );

      ReactDOM.render(
        <TimeoutErrorPage failCopy="Exception error connecting to Showboat application server." />,
        document.getElementById("root")
      );

      return;
    }

    //Ensure we got a valid connection
    if (!connectionResult) {
      SHOWBOAT.Logger.Error("Error connecting to Showboat application server.");

      UIHelper.SendErrorLog("Error connecting to Showboat application server.");

      ReactDOM.render(
        <TimeoutErrorPage failCopy="Error connecting to Showboat application server." />,
        document.getElementById("root")
      );

      return;
    }

    //Signal Server Helper to start monitoring debug events
    await ServerHelper.ManageDeviceDebugInfo();

  //Join the event
    let response: SHOWBOAT.JoinRequestResponse = null;
    try {
      //Check if we are using the simulation testing version of app
      if (SHOWBOAT.RemotePlayersZoneConfig.doAvatarSimulation) {
        if (SHOWBOAT.RemotePlayersZoneConfig.doStreamSimulatedVideo) {
          SHOWBOAT.LocalAvatarDataManager.cameraEnabled = true;
          SHOWBOAT.LocalAvatarDataManager.micEnabled =
            SHOWBOAT.RemotePlayersZoneConfig.doStreamSimulatedAudio;
        } else {
          SHOWBOAT.LocalAvatarDataManager.cameraEnabled = false;
          SHOWBOAT.LocalAvatarDataManager.micEnabled = false;
          SHOWBOAT.LocalAvatarDataManager.currentCameraName = "BOT";
        }

        SHOWBOAT.LocalAvatarDataManager.firstName =
          ServerHelper.getRandomFirst();
        SHOWBOAT.LocalAvatarDataManager.lastName = ServerHelper.getRandomLast();
        SHOWBOAT.LocalAvatarDataManager.company =
          (Math.random() < 0.7
            ? ServerHelper.getRandomLast()
            : ServerHelper.getRandomLast() +
              " & " +
              ServerHelper.getRandomLast()) +
          " " +
          ServerHelper.getRandomCompanySuffix();
        SHOWBOAT.LocalAvatarDataManager.face = Math.floor(Math.random() * 6);
        SHOWBOAT.LocalAvatarDataManager.color = Math.floor(Math.random() * 6);
      }

      //Set system information
      SHOWBOAT.LocalAvatarDataManager.browser = `${SHOWBOAT.SystemInformation.BrowserName} ${SHOWBOAT.SystemInformation.BrowserVersion}`;
      SHOWBOAT.LocalAvatarDataManager.operatingSystem = `${SHOWBOAT.SystemInformation.OSName} ${SHOWBOAT.SystemInformation.OSVersion}`;
      SHOWBOAT.LocalAvatarDataManager.graphicsDriver =
        SHOWBOAT.ShowboatLoader.getGraphicsDrive();
      SHOWBOAT.LocalAvatarDataManager.isMobileUser = ServerHelper.useMobileApp;
      SHOWBOAT.LocalAvatarDataManager.isAvailableForTeleport =
        !SHOWBOAT.LocalAvatarDataManager.isMobileUser;

      let serverToken = await ServerHelper.getServerToken();

      if (!serverToken) {
        ReactDOM.render(
          <TimeoutErrorPage failCopy="Exception error logging into Showboat application server." />,
          document.getElementById("root")
        );

        return;
      }

      response = await SHOWBOAT.SocketIOController.Login(
        serverToken as string,
        SHOWBOAT.LocalAvatarDataManager.avatarData
      );
      SHOWBOAT.Logger.Log("Login response", response);

      //Store the user ID
      SHOWBOAT.LocalAvatarDataManager.userID = response.userID;

      //TEST TEST TEST
      //console.warn("Timer testing");
      //UIHelper.timerTesting();
    } catch (err) {
      SHOWBOAT.Logger.Error(
        "Exception error logging into Showboat application server",
        err
      );

      UIHelper.SendErrorLog(
        "Exception error logging into Showboat application server."
      );

      ReactDOM.render(
        <TimeoutErrorPage failCopy="Exception error logging into Showboat application server." />,
        document.getElementById("root")
      );

      return;
    }

    //Ensure join succeeded
    if (!response.success) {
      SHOWBOAT.Logger.Error(
        "Error. Unable to login to Showboat application server"
      );

      let errorCopy: string;
      //Check if join fail is result of event being full
      if (response.failReason === "Event full") {
        errorCopy = "Event is currently at maximum capacity.";
      } else {
        errorCopy = "Error. Unable to login to Showboat application server.";
      }

      UIHelper.SendErrorLog(errorCopy);

      ReactDOM.render(
        <TimeoutErrorPage failCopy={errorCopy} />,
        document.getElementById("root")
      );
      return;
    }

    //Store our room ID
    SHOWBOAT.LocalAvatarDataManager.roomID = response.roomID;

    //Get the initial server variables
    await SHOWBOAT.ServerVariableManager.Init(
      SHOWBOAT.LocalAvatarDataManager.roomID,
      response.eventVariablesData,
      response.roomVariablesData
    );

    let systemInformationDebugInfo =
      SHOWBOAT.SystemInformation.getDebugString();
    SHOWBOAT.Logger.Server("System Information:", systemInformationDebugInfo);

    let babylonDebugData = SHOWBOAT.ShowboatLoader.getEngineDebugInfo();
    if (babylonDebugData) {
      babylonDebugData.forEach((element) => {
        SHOWBOAT.Logger.Server(element);
      });
    }

    //Get the userID
    /*
    let userID: string = ""
    try {
      userID = await SHOWBOAT.SocketIOController.GetUserID(); 
    } catch (err) {
      SHOWBOAT.Logger.Error("Error obtaining user ID for Showboat application server.");
      SHOWBOAT.Logger.Error(err);

      ReactDOM.render(
        <TimeoutErrorPage failCopy="Error obtaining user ID for Showboat application server." />,
        document.getElementById('root')
      );
      return;
    }
    

    //Store the user ID
    SHOWBOAT.LocalAvatarDataManager.userID = userID;
    */

    //If on mobile, initialize the mobile presenter stream helper
    if (ServerHelper.useMobileApp) {
      MobilePresenterStreamHelper.Init();
    }

    //Initialize the Presenter Helper
    PresenterHelper.Init();

    //Initialize the Test Helper
    TestHelper.Init();

    //Initialize the User Permissions Helper
    UserPermissionsHelper.Init();

    //Allow page to do post join work
    toggleIsJoinCompleted(true);

    //Setup liveswitch
    if (!ServerHelper.useMobileHybridApp) {
      //No need to start a liveswitch client if we are a mobile hybrid user

      SHOWBOAT.LiveSwitchClientController.createClient(
        ServerHelper.VideoServerAppID,
        SHOWBOAT.LocalAvatarDataManager.userID,
        SHOWBOAT.LiveSwitchClientController.generateUUID(),
        "ShowboatWebClient_" + SHOWBOAT.LocalAvatarDataManager.eventID,
        ServerHelper.VideoServer
      );

      let registerResult: boolean = false;
      try {
        registerResult = await SHOWBOAT.LiveSwitchClientController.register();
      } catch (err) {
        SHOWBOAT.Logger.Error(
          "Exception error registering with Showboat video server."
        );

        UIHelper.SendErrorLog(
          "Exception error registering with Showboat video server."
        );

        ReactDOM.render(
          <TimeoutErrorPage failCopy="Exception error registering with Showboat video server." />,
          document.getElementById("root")
        );
        return;
      }

      //Check registration was a success
      if (!registerResult) {
        SHOWBOAT.Logger.Error("Error registering with Showboat video server.");

        UIHelper.SendErrorLog("Error registering with Showboat video server.");

        ReactDOM.render(
          <TimeoutErrorPage failCopy="Error registering with Showboat video server." />,
          document.getElementById("root")
        );
        return;
      }
    }

    //Prep out 2D helper
    await TwoDHelper.Init();

    //Prep jumbotron helper
    if (ServerHelper.useMobileApp) {
      SHOWBOAT.JumbotronHelper.Init(ServerHelper.useMobileApp);
    }

    //Start my video stream as an attendees
    let sendUpstream: boolean = true;
    if (
      SHOWBOAT.RemotePlayersZoneConfig.doAvatarSimulation &&
      !SHOWBOAT.RemotePlayersZoneConfig.doStreamSimulatedVideo
    ) {
      sendUpstream = false;
    }

    if (sendUpstream && !ServerHelper.useMobileApp) {
      SHOWBOAT.LiveswitchUpstreamController.Init(
        SHOWBOAT.LocalAvatarDataManager.userID,
        SHOWBOAT.ShowboatChannelType.Attendees,
        SHOWBOAT.LocalAvatarDataManager.avatarData.role,
        response.eventID,
        response.roomID
      );
    }

    //PRE-WARM a media stream
    if (!ServerHelper.useMobileHybridApp) {
      try {
        await SHOWBOAT.LiveswitchMediaDownstreamController.Start(
          response.eventID
        );

        /*     if (ServerHelper.useMobileApp) {
          try {
            SHOWBOAT.Logger.Log("Playing initial mobile video");
            SHOWBOAT.LiveswitchMediaDownstreamController.getHTMLVideoElement().setAttribute("playsinline", "");
            await SHOWBOAT.LiveswitchMediaDownstreamController.getHTMLVideoElement().play();
          } catch (error) {
            SHOWBOAT.Logger.Error("Error playing initial mobile video:", error);
          }
        } */

        //await SHOWBOAT.LiveswitchMediaDownstreamController.Pause();
      } catch (e) {
        SHOWBOAT.Logger.Error("Error setting up media downstream");
        SHOWBOAT.Logger.Error(e);
      }
    }

    //Get initial presenter variables
    await SHOWBOAT.ServerVariableManager.getEventVariable(
      StringVariableHelper.LocalVideoVolumeEventName,
      { volume: 1 }
    );

    await SHOWBOAT.ServerVariableManager.getEventVariable(
      StringVariableHelper.PresentationEventName,
      { mode: SHOWBOAT.JumbotronMode.off, slide: "", userID: "" }
    );
    await SHOWBOAT.ServerVariableManager.getEventVariable(
      StringVariableHelper.SilenceAudienceEventName,
      { muted: false }
    );
    await SHOWBOAT.ServerVariableManager.getEventVariable(
      StringVariableHelper.SpatialAudioEventName,
      { value: true }
    );

    //Get initial QA values
    let attendeesSeeAllQs =
      await SHOWBOAT.ServerVariableManager.getEventVariable(
        StringVariableHelper.AttendeesSeeAllQuestionsName,
        { value: true }
      );
    setAttendeesSeeAllQs(attendeesSeeAllQs.value);

    let allowAnonymousQs =
      await SHOWBOAT.ServerVariableManager.getEventVariable(
        StringVariableHelper.AllowAnonymousQuestionsName,
        { value: true }
      );
    setAllowAnonymousQs(allowAnonymousQs.value);

    //Prepare the audio distance for the room
    let audioDistanceEventVariable =
      await SHOWBOAT.ServerVariableManager.getEventVariable(
        StringVariableHelper.AudioDistanceEventName,
        { auto: true, distance: 20, userID: "none" }
      );

    if (
      audioDistanceEventVariable &&
      audioDistanceEventVariable.auto !== undefined &&
      audioDistanceEventVariable.distance
    ) {
      SHOWBOAT.RemotePlayersZoneConfig.useAutoBubble =
        audioDistanceEventVariable.auto;
      if (!SHOWBOAT.RemotePlayersZoneConfig.useAutoBubble) {
        SHOWBOAT.RemotePlayersZoneConfig.setAudioBubbleDistance(
          audioDistanceEventVariable.distance
        );
      }
    }

    //Get all existing player data
    let avatarDataArrary: SHOWBOAT.AvatarData[] = [];
    try {
      avatarDataArrary =
        await SHOWBOAT.SocketIOController.RequestAllPlayerData();
    } catch (err) {
      SHOWBOAT.Logger.Error(
        "Exception error retrieving participant data from Showboat application server."
      );

      UIHelper.SendErrorLog(
        "Exception error retrieving participant data from Showboat application server."
      );

      ReactDOM.render(
        <TimeoutErrorPage failCopy="Exception error retrieving participant data from Showboat application server." />,
        document.getElementById("root")
      );
      return;
    }

    //Get QA items
    handleGetAllQuestions();

    SHOWBOAT.RemoteAvatarDataManager.setInitialAvatarData(avatarDataArrary);

    //Raise event so AttendeeList knows to load initial event list
    SHOWBOAT.UIEventManager.OnSuccessfulRequestAllPlayerData.Raise();

    //Start the liveswitch downstream if not on mobile hybrid app
    if (!ServerHelper.useMobileHybridApp) {
      try {
        await SHOWBOAT.LiveswitchDownstreamController.init(
          response.eventID,
          response.roomID,
          SHOWBOAT.LocalAvatarDataManager.avatarData
        );
      } catch (err) {
        SHOWBOAT.Logger.Error(
          "Exception error initializing Showboat video server."
        );

        UIHelper.SendErrorLog(
          "Exception error initializing Showboat video server."
        );

        ReactDOM.render(
          <TimeoutErrorPage failCopy="Exception error initializing Showboat video server." />,
          document.getElementById("root")
        );
        return;
      }
    }

    //Initialize the AdvancedLogging helper
    AdvancedLoggingHelper.Init();

    //Prep Audio Engine
    //SHOWBOAT.PlayerAudioController.init();

    let loadStartTime: number = Date.now();

    const calculateLoadTime = () => {
      let duration: number = Date.now() - loadStartTime;
      SHOWBOAT.Logger.Server("Scene load time:", duration);
      SHOWBOAT.ShowboatLoader.OnHideLoadScreen.Remove(calculateLoadTime);
    };

    SHOWBOAT.ShowboatLoader.OnHideLoadScreen.Add(calculateLoadTime);

    //Add listener for camera drone mode
    document.addEventListener("keypress", handleDroneKeyPress);

    //Only load canvas if not on mobile
    if (!ServerHelper.useMobileApp) {
      let sceneSupplement = SHOWBOAT.ApplicationSkin.sceneSupplement
        ? "./scenes/" + SHOWBOAT.ApplicationSkin.sceneSupplement
        : null;

      await SHOWBOAT.ShowboatLoader.Load(
        "showboatCanvas",
        "./scenes/" + SHOWBOAT.ApplicationSkin.mainScene,
        "./scenes/" + SHOWBOAT.ApplicationSkin.mainSceneConfig,
        sceneSupplement,
        {},
        true
      );
    }

    //Send entry notification if enabled for this booking and user is an attendee
    if (
      ServerHelper.loginResult.sendEntryNotifications &&
      SHOWBOAT.LocalAvatarDataManager.role ===
        StringVariableHelper.ShowboatRoles.attendees
    ) {
      const dataSendChatNotification = await UIHelper.sendChatNotification(
        "entry"
      );

      SHOWBOAT.Logger.Log(
        "Chat notification send response:",
        dataSendChatNotification
      );
    }

    //Only init JumbotronHelper here if bypass is not occurring
    if (!ServerHelper.bypass && !ServerHelper.useMobileApp) {
      SHOWBOAT.JumbotronHelper.Init(ServerHelper.useMobileApp);
    }

    if (ServerHelper.useMobileApp) {
      SHOWBOAT.UIEventManager.OnAudioUnlockEvent.Raise();
    }

    //TESTING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //SHOWBOAT.JumbotronHelper.Init();
    //JumbotronHelper.Init();
  };

  return {
    isIntakeCompleted,
    isJoinCompleted,
    loadApp,
    toggleIsIntakeCompleted,
  };
};

export default useLoadApp;
