import { Typography } from '@material-ui/core';
import React from 'react';

import styles from "./MobileLandscapeOverlay.module.css";
import { MobileLandscapeOverlayStyles } from "./MobileLandscapeOverlayStyles";

type Props = {}

export default function MobileLandscapeOverlay({}: Props) {

  const classes = MobileLandscapeOverlayStyles();
  
  return (
    <div className={styles.mobileLandscapeOverlay}>
      <Typography className={classes.landscapeText}>
        Landscape mode is not supported
      </Typography>
    </div>
  )
}