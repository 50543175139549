import { ReportRounded } from "@material-ui/icons";
import { StringVariableHelper } from "./StringVariableHelper";

export class PresenterHelper {
  public static OnPresentationModeChange: NSM.Delegate = new NSM.Delegate();

  public static myCurrentPresentationMode: SHOWBOAT.JumbotronMode =
    SHOWBOAT.JumbotronMode.none;
  public static myPreviousPresentationMode: SHOWBOAT.JumbotronMode =
    SHOWBOAT.JumbotronMode.none;
  public static currentJumbotronMode: SHOWBOAT.JumbotronMode =
    SHOWBOAT.JumbotronMode.none;
  public static pendingJumbotronMode: SHOWBOAT.JumbotronMode =
    SHOWBOAT.JumbotronMode.none;

  public static selectedPresentation;

  public static LivestreamController: SHOWBOAT.LivestreamController;

  private static shareID: number = 0; ///TODO  Deprecate this property

  public static currentPresenterID: string;

  //Video elements for presenter tools
  public static LocalVideo: HTMLVideoElement;
  public static ScreenshareVideo: HTMLVideoElement;
  public static LivestreamVideo: HTMLVideoElement;
  public static WebcamVideo: HTMLVideoElement;

  private static currentSlideNumber: number = 0;
  private static currentLivestreamURL: string = "";

  public static currentScreenshareController: SHOWBOAT.PresenterScreenshare =
    null;
  public static currentLocalVideoController: SHOWBOAT.PresenterLocalVideo =
    null;

  public static async Init() {
    //Allow access to PresenterHelper on window object
    (window as any).PresenterHelper = PresenterHelper;

    //Listen for socket IO presentation events
    SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Add(
      StringVariableHelper.PresentationEventName,
      PresenterHelper.OnPresenterEventVariable
    );

    //AV Controller Listeners
    SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Add(
      PresenterHelper.OnCameraDeviceChanged
    );

    await this.GetInitialPresenterVariable();
  }

  public static updateServer(
    toJumbotronMode: SHOWBOAT.JumbotronMode,
    requestingJumbotronMode = null,
    allowForceOff = false
  ): void {
    SHOWBOAT.Logger.Log(
      "PresenterHelper.switchToJumbotronMode",
      toJumbotronMode,
      requestingJumbotronMode
    );

    //Special care to not turn the jumbotron if someone else is in charge of it
    if (toJumbotronMode == SHOWBOAT.JumbotronMode.off) {
      //Ensure the requesting mode is the current mode for any off requests
      if (
        requestingJumbotronMode != PresenterHelper.myCurrentPresentationMode &&
        !allowForceOff
      ) {
        return;
      }

      //Ensure off requests are ignored if another mode is in the process of starting up
      if (PresenterHelper.pendingJumbotronMode != SHOWBOAT.JumbotronMode.none) {
        return;
      }

      //Only go off if we are the current presenter ID, or we are forcing an off
      if (
        PresenterHelper.currentPresenterID !=
        SHOWBOAT.LocalAvatarDataManager.userID
      ) {
        if (!allowForceOff) {
          return;
        }
      }
    }

    //Mark we are now in the new mode. Do it now to prevent risk of infinite loops caused by callbacks
    PresenterHelper.myPreviousPresentationMode =
      PresenterHelper.myCurrentPresentationMode;
    PresenterHelper.myCurrentPresentationMode = toJumbotronMode;
    PresenterHelper.pendingJumbotronMode = SHOWBOAT.JumbotronMode.none;

    //Switch to the correct mode
    switch (toJumbotronMode) {
      case SHOWBOAT.JumbotronMode.off:
        SHOWBOAT.SocketIOController.SetServerEventVariable(
          StringVariableHelper.PresentationEventName,
          {
            mode: toJumbotronMode,
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
          }
        );

        break;

      case SHOWBOAT.JumbotronMode.slideShare:
        SHOWBOAT.SocketIOController.SetServerEventVariable(
          StringVariableHelper.PresentationEventName,
          {
            mode: toJumbotronMode,
            slide:
              PresenterHelper.selectedPresentation.slideRoot +
              PresenterHelper.selectedPresentation.slides[
                PresenterHelper.currentSlideNumber
              ],
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
          }
        );

        break;

      case SHOWBOAT.JumbotronMode.screenShare:
        //Notify socket of the screenshare
        SHOWBOAT.SocketIOController.SetServerEventVariable(
          StringVariableHelper.PresentationEventName,
          {
            mode: toJumbotronMode,
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
            screenshareID: PresenterHelper.shareID,
          }
        );

        break;

      case SHOWBOAT.JumbotronMode.localVideoShare:
        //Notify socket of the screenshare
        SHOWBOAT.SocketIOController.SetServerEventVariable(
          StringVariableHelper.PresentationEventName,
          {
            mode: toJumbotronMode,
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
            screenshareID: PresenterHelper.shareID,
          }
        );

        break;

      case SHOWBOAT.JumbotronMode.webcamShare:
        SHOWBOAT.SocketIOController.SetServerEventVariable(
          StringVariableHelper.PresentationEventName,
          {
            mode: toJumbotronMode,
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
            screenshareID: PresenterHelper.shareID,
          }
        );

        break;

      case SHOWBOAT.JumbotronMode.livestream:
        SHOWBOAT.SocketIOController.SetServerEventVariable(
          StringVariableHelper.PresentationEventName,
          {
            mode: toJumbotronMode,
            url: PresenterHelper.currentLivestreamURL,
            userID: SHOWBOAT.LocalAvatarDataManager.avatarData.userID,
          }
        );

        break;
    }
  }

  public static async stopCurrentMode() {
    switch (PresenterHelper.myCurrentPresentationMode) {
      case SHOWBOAT.JumbotronMode.off:
        //nothing to do
        break;

      case SHOWBOAT.JumbotronMode.slideShare:
        //nothing to do
        break;

      case SHOWBOAT.JumbotronMode.screenShare:
        //Stop any streaming connection
        await SHOWBOAT.LiveswitchMediaUpstreamController.stopConnection();

        //Stop local screenshare

        break;

      case SHOWBOAT.JumbotronMode.localVideoShare:
        //Stop any streaming connection
        await SHOWBOAT.LiveswitchMediaUpstreamController.stopConnection();

        //Stop current video share
        if (PresenterHelper.currentLocalVideoController) {
          SHOWBOAT.PresenterLocalVideo.currentPresenterLocalVideo =
            PresenterHelper.currentLocalVideoController;
        }

        break;

      case SHOWBOAT.JumbotronMode.webcamShare:
        //Stop any streaming connection
        await SHOWBOAT.LiveswitchMediaUpstreamController.stopConnection();

        break;

      case SHOWBOAT.JumbotronMode.livestream:
        //Stop the livestream
        /* PresenterHelper.LivestreamController.stopLivestream(); */
        break;
    }
  }

  public static async OnSlideToggle(
    slideModeIsOn: boolean,
    currentSlide: number = 0
  ) {
    

    if (slideModeIsOn) {
      await PresenterHelper.stopCurrentMode();
      PresenterHelper.pendingJumbotronMode = SHOWBOAT.JumbotronMode.slideShare;
      PresenterHelper.currentSlideNumber = currentSlide;
      PresenterHelper.updateServer(SHOWBOAT.JumbotronMode.slideShare);
    } else {
      PresenterHelper.updateServer(
        SHOWBOAT.JumbotronMode.off,
        SHOWBOAT.JumbotronMode.slideShare
      );
    }

   
    PresenterHelper.OnPresentationModeChange.Raise();
  }

  public static OnSlideChange(slideNumber: number): void {
 

    //ensure this slide exists
    if (PresenterHelper.selectedPresentation.slides[slideNumber] === undefined)
      return;

    //Ensure we are in slide share mode for this request to be valid
    if (
      PresenterHelper.myCurrentPresentationMode ==
      SHOWBOAT.JumbotronMode.slideShare
    ) {
      PresenterHelper.currentSlideNumber = slideNumber;
      PresenterHelper.updateServer(SHOWBOAT.JumbotronMode.slideShare);
    }
  }

  private static handlePresenterToolFailure(): void {
    PresenterHelper.pendingJumbotronMode = SHOWBOAT.JumbotronMode.none;
    PresenterHelper.myCurrentPresentationMode = SHOWBOAT.JumbotronMode.none;

   
    PresenterHelper.OnPresentationModeChange.Raise();
  }

  public static async OnScreenshareToggle(
    wantScreenshareOn: boolean
  ): Promise<boolean> {
 

    if (wantScreenshareOn) {
      //Check if we are already in this mode
      if (
        PresenterHelper.myCurrentPresentationMode ==
        SHOWBOAT.JumbotronMode.screenShare
      )
        return true;

      try {
        PresenterHelper.pendingJumbotronMode =
          SHOWBOAT.JumbotronMode.screenShare;

        //Wait to stop current mode because screenshare requires an OS dialogue that can be cancelled
        await PresenterHelper.stopCurrentMode();

        //Get the newly created screenshare media stream
        let mediaStream =
          PresenterHelper.currentScreenshareController.getScreenshareStream();

        if (!mediaStream) {
          PresenterHelper.handlePresenterToolFailure();
          return false;
        }

        //start sending signal to liveswitch
        let startStreamResult =
          await SHOWBOAT.LiveswitchMediaUpstreamController.startMediaStreamConnection(
            mediaStream,
            "Media_Jumbotron",
            true,
            true
          );
        if (!startStreamResult) {
          PresenterHelper.handlePresenterToolFailure();
          return false;
        }

        PresenterHelper.updateServer(SHOWBOAT.JumbotronMode.screenShare);
      } catch (error) {
        PresenterHelper.handlePresenterToolFailure();
        SHOWBOAT.Logger.Error("Error starting screenshare", error);
        return false;
      }
    } else {
      if (
        PresenterHelper.myCurrentPresentationMode ==
          SHOWBOAT.JumbotronMode.off ||
        PresenterHelper.myCurrentPresentationMode !=
          SHOWBOAT.JumbotronMode.screenShare ||
        PresenterHelper.pendingJumbotronMode != SHOWBOAT.JumbotronMode.none
      )
        return true;

      await PresenterHelper.stopCurrentMode();

      PresenterHelper.updateServer(
        SHOWBOAT.JumbotronMode.off,
        SHOWBOAT.JumbotronMode.screenShare
      );
    }

   
    PresenterHelper.OnPresentationModeChange.Raise();

    return true;
  }

  public static async OnStartBrowserScreenshare(
    optimizeScreenshareForVideo: boolean
  ): Promise<boolean> {
    //Create a new instance of screenshare controller
    PresenterHelper.currentScreenshareController =
      new SHOWBOAT.PresenterScreenshare();
    SHOWBOAT.PresenterScreenshare.currentPresenterScreenshare =
      PresenterHelper.currentScreenshareController;

    let startScreenshareResult: boolean =
      await PresenterHelper.currentScreenshareController.startScreenshare(
        true,
        optimizeScreenshareForVideo ? 1280 : null,
        optimizeScreenshareForVideo ? 720 : null,
        optimizeScreenshareForVideo ? 30 : 5
      );

    if (!startScreenshareResult) {
      //Don't do anything. just return since the mode never actually changed
      //PresenterHelper.handlePresenterToolFailure();
      return false;
    }

    //Listen for screenshare ending
    PresenterHelper.currentScreenshareController.OnScreenshareStopped.Add(
      PresenterHelper.OnScreenshareUpstreamStopped
    );

    return true;
  }

  public static async OnVideoshareToggle(
    wantVideoShareOn: boolean
  ): Promise<boolean> {
    
    if (wantVideoShareOn) {
      try {
        PresenterHelper.pendingJumbotronMode =
          SHOWBOAT.JumbotronMode.localVideoShare;

        await PresenterHelper.stopCurrentMode();

        let mediaStream =
          PresenterHelper.currentLocalVideoController.getStream();
        if (!mediaStream) {
          PresenterHelper.handlePresenterToolFailure();
          return false;
        }

        let startStreamResult =
          await SHOWBOAT.LiveswitchMediaUpstreamController.startMediaStreamConnection(
            mediaStream,
            "Media_Jumbotron",
            true,
            true
          );
        if (!startStreamResult) {
          PresenterHelper.handlePresenterToolFailure();
          return false;
        }

        PresenterHelper.updateServer(SHOWBOAT.JumbotronMode.localVideoShare);
      } catch (error) {
        PresenterHelper.pendingJumbotronMode = SHOWBOAT.JumbotronMode.none;
        SHOWBOAT.Logger.Error("Error starting videoshare", error);
        return false;
      }
    } else {
      if (
        PresenterHelper.myCurrentPresentationMode ==
          SHOWBOAT.JumbotronMode.off ||
        PresenterHelper.myCurrentPresentationMode !=
          SHOWBOAT.JumbotronMode.localVideoShare ||
        PresenterHelper.pendingJumbotronMode != SHOWBOAT.JumbotronMode.none
      )
        return true;

      await PresenterHelper.stopCurrentMode();

      PresenterHelper.updateServer(
        SHOWBOAT.JumbotronMode.off,
        SHOWBOAT.JumbotronMode.localVideoShare
      );
    }

    
    PresenterHelper.OnPresentationModeChange.Raise();
    return true;
  }

  public static async OnPresentWebcamToggle(
    isPresenterWebCamOn: boolean
  ): Promise<boolean> {
    

    if (isPresenterWebCamOn) {
      if (
        PresenterHelper.myCurrentPresentationMode ==
        SHOWBOAT.JumbotronMode.webcamShare
      )
        return false;

      //No point do anything if the camera is off
      if (!SHOWBOAT.StreamingUserMedia.isCameraRunning()) return false;

      try {
        let mediaStream = SHOWBOAT.StreamingUserMedia.getCameraStream();
        if (!mediaStream) {
          //PresenterHelper.handlePresenterToolFailure();
          return false;
        }

        PresenterHelper.pendingJumbotronMode =
          SHOWBOAT.JumbotronMode.webcamShare;

        await PresenterHelper.stopCurrentMode();

        let startStreamResult =
          await SHOWBOAT.LiveswitchMediaUpstreamController.startMediaStreamConnection(
            mediaStream,
            "Media_Jumbotron",
            true,
            true
          );
        if (!startStreamResult) {
          PresenterHelper.handlePresenterToolFailure();
          return false;
        }

        PresenterHelper.updateServer(SHOWBOAT.JumbotronMode.webcamShare);
      } catch (err) {
        PresenterHelper.pendingJumbotronMode = SHOWBOAT.JumbotronMode.none;
        SHOWBOAT.Logger.Error("Error starting webcam share");
        SHOWBOAT.Logger.Error(err);
      }
    } else {
      if (
        PresenterHelper.myCurrentPresentationMode ==
          SHOWBOAT.JumbotronMode.off ||
        PresenterHelper.myCurrentPresentationMode !=
          SHOWBOAT.JumbotronMode.webcamShare ||
        PresenterHelper.pendingJumbotronMode != SHOWBOAT.JumbotronMode.none
      )
        return;

      await PresenterHelper.stopCurrentMode();

      PresenterHelper.updateServer(
        SHOWBOAT.JumbotronMode.off,
        SHOWBOAT.JumbotronMode.webcamShare
      );
    }

    
    PresenterHelper.OnPresentationModeChange.Raise();
    return true;
  }

  public static async OnHLSToggle(isLivestreamOn: boolean, url: string = "") {
    PresenterHelper.currentLivestreamURL = url;

    if (isLivestreamOn) {
      await PresenterHelper.stopCurrentMode();
      PresenterHelper.updateServer(SHOWBOAT.JumbotronMode.livestream);
    } else {
      PresenterHelper.updateServer(
        SHOWBOAT.JumbotronMode.off,
        SHOWBOAT.JumbotronMode.livestream
      );
    }

    PresenterHelper.OnPresentationModeChange.Raise();
  }

  public static async OnDefaultScreenActivate() {
    
    //Let HLS keep playing
    if (
      PresenterHelper.myCurrentPresentationMode !=
      SHOWBOAT.JumbotronMode.livestream
    ) {
      await PresenterHelper.stopCurrentMode();
    }

    PresenterHelper.updateServer(
      SHOWBOAT.JumbotronMode.off,
      PresenterHelper.myCurrentPresentationMode,
      true
    );

   
    PresenterHelper.OnPresentationModeChange.Raise();
  }

  public static OnCameraDeviceChanged() {
    //Turn off webcam share if we changed cameras
    if (
      PresenterHelper.myCurrentPresentationMode ==
      SHOWBOAT.JumbotronMode.webcamShare
    )
      PresenterHelper.OnPresentWebcamToggle(false);
  }

  public static OnScreenshareUpstreamStopped(): void {
    PresenterHelper.OnScreenshareToggle(false);
    //PresenterHelper.OnForceScreenshareOff.Raise();
  }

  public static OnSpatialAudioChange(spatialAudio: boolean): void {
    //When SpatialAudio is set to OFF, change zone size to large value 9999
    //Otherwise, set to default
    if (spatialAudio) {
      SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible =
        SHOWBOAT.RemotePlayersZoneConfig.Default_Distance_Visible;
    } else {
      SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible = 99999;
    }

    SHOWBOAT.SocketIOController.SetServerEventVariable(
      StringVariableHelper.SpatialAudioEventName,
      { value: spatialAudio }
    );
  }

  //Mode is currently not visible on presenter tools
  public static OnEventMuteToggle(isEventMuted: boolean): void {
    SHOWBOAT.SocketIOController.SendEventAll(
      StringVariableHelper.EventMuteEventName,
      { isEventMuted: isEventMuted }
    );
  }

  public static OnSilenceAudience(audienceIsSilenced: boolean): void {
    SHOWBOAT.SocketIOController.SetServerEventVariable(
      StringVariableHelper.SilenceAudienceEventName,
      { muted: audienceIsSilenced }
    );
  }

  public static async OnPresenterEventVariable(newValue: any) {
    //Set the current presenter ID so we never accidently turn off the screen if someone else has overtaken us as the presenter
    if (newValue.userID) {
      PresenterHelper.currentPresenterID = newValue.userID;
    } else {
      PresenterHelper.currentPresenterID = "";
    }

    //Check if we need to disable our own share
    if (
      newValue.mode &&
      newValue.userID &&
      newValue.userID != SHOWBOAT.LocalAvatarDataManager.userID
    ) {
      //Mark the current mode the jumbotron is in
      PresenterHelper.currentJumbotronMode = newValue.mode;

      if (
        PresenterHelper.myCurrentPresentationMode !=
        SHOWBOAT.JumbotronMode.livestream
      ) {
        await PresenterHelper.stopCurrentMode();
      }

      PresenterHelper.myPreviousPresentationMode =
        PresenterHelper.myCurrentPresentationMode;
      PresenterHelper.myCurrentPresentationMode = SHOWBOAT.JumbotronMode.none;

   
      PresenterHelper.OnPresentationModeChange.Raise();
    }
  }

  public static async GetInitialPresenterVariable(): Promise<boolean> {
    if (
      PresenterHelper.currentPresenterID &&
      PresenterHelper.currentPresenterID.length > 1
    ) {
      try {
        let initialVal = await SHOWBOAT.ServerVariableManager.getEventVariable(
          StringVariableHelper.PresentationEventName,
          { mode: SHOWBOAT.JumbotronMode.off, slide: "", userID: "" }
        );
        if (initialVal.userID) {
          PresenterHelper.currentPresenterID = initialVal.userID;
        } else {
          PresenterHelper.currentPresenterID = "";
        }
        return true;
      } catch (err) {
        SHOWBOAT.Logger.Error(
          "Error retrieving initial presenter variable",
          err
        );
        PresenterHelper.currentPresenterID = "";
        return true;
      }
    } else {
      return true;
    }
  }

  public static async CreateLocalVideoPreview(src:string, loop:boolean) {
    //Remove old video if it is there
    if (document.getElementById("localVideo")) {
      document
        .getElementById("root")
        .removeChild(document.getElementById("localVideo"));
    }

    SHOWBOAT.Logger.Log("SRC:", src);

    let newVideo = document.createElement("video") as HTMLVideoElement;
    newVideo.id = "localVideo";
    newVideo.crossOrigin = "anonymous";
    newVideo.src = src;
    newVideo.pause();
    newVideo.muted = false;
    newVideo.controls = false;
    PresenterHelper.LocalVideo = newVideo;
    document.getElementById("root").appendChild(newVideo);
    /* newVideo.style.visibility = "hidden"; */

    //Instantiate the LocalVideo controller
    PresenterHelper.currentLocalVideoController =
      new SHOWBOAT.PresenterLocalVideo();
    SHOWBOAT.PresenterLocalVideo.currentPresenterLocalVideo =
      PresenterHelper.currentLocalVideoController;

    //Start drawing to the canvas
    await PresenterHelper.currentLocalVideoController.startVideoShare(
      newVideo,
      loop,
      false,
      30
    );

    //Append canvas to preview
    let container = document.getElementById("localVideoPreviewHolder");

    if (container)
      container.appendChild(newVideo);
      //container.appendChild(PresenterHelper.currentLocalVideoController.canvas);
  }

  public static removeVideoPreviewFromPopup(elementID: string) {
    let previewHolder = document.getElementById(elementID);

    if (previewHolder) {
      for (let i = 0; i < previewHolder.childNodes.length; i++) {
        if (
          previewHolder.childNodes[i].nodeName === "VIDEO" ||
          previewHolder.childNodes[i].nodeName === "CANVAS"
        ) {
          previewHolder.removeChild(previewHolder.childNodes[i]);
        }
      }
    }
  }
}
