import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import Close from "@material-ui/icons/Close";
import PeopleIcon from "@material-ui/icons/People";

import styles from "./MovePopup.module.css";
import { MovePopupStyles } from "./MovePopupStyles";
import { AppContext, AppContextType } from "../../../../context/AppContext";
import {
  AttendeeContext,
  AttendeeContextType,
} from "../../../../context/AttendeeContext";
import MovePopupItem from "./MovePopupItem/MovePopupItem";
import { UIHelper } from "../../../../Utilities/UIHelper";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";

interface Props {}

export default function MovePopup(props: Props): ReactElement {
  const [moveUrl, setMoveUrl] = useState("");
  const [moveUrlError, setMoveUrlError] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedAttendeeIDs, setSelectedAttendeeIDs] = useState([]);

  const {
    attendeesInEvent,
    handleInitialEventAttendeesLoad,
  }: AttendeeContextType = useContext(AttendeeContext);

  const { showMoveMenu, setShowMoveMenu }: AppContextType =
    useContext(AppContext);

  useEffect(() => {
    if (showMoveMenu) {
      handleInitialEventAttendeesLoad();
    }
  }, [showMoveMenu]);

  const handleMoveUrlChange = (e) => {
    setMoveUrlError(false);

    setMoveUrl(e.target.value);
  };

  const handleCloseMovePopup = () => {
    //Clear the popup
    setSelectedAttendeeIDs([]);
    setSelectAll(false);

    setMoveUrlError(false);
    
    setShowMoveMenu(false);
  };

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    
    if (e.target.checked) {
      //Add all userID's to the array
      let userIDArray = [];

      for (let i = 0; i < attendeesInEvent.length; i++) {
        userIDArray.push(attendeesInEvent[i].userID);
      }

      setSelectedAttendeeIDs((selectedAttendeeIDs) => [
        ...selectedAttendeeIDs,
        ...userIDArray,
      ]);
    } else {
      //Remove all userIDs from state
      setSelectedAttendeeIDs([]);
    }
  };

  const handleAddAttendee = (userID: string) => {
    setSelectedAttendeeIDs(selectedAttendeeIDs => [...selectedAttendeeIDs, userID]);
  };

  const handleRemoveAttendee = (userID: string) => {
    setSelectedAttendeeIDs(selectedAttendeeIDs => selectedAttendeeIDs.filter(id => id !== userID))
  };

  const handleMoveClick = () => {

    //Ensure there is a URL entered
    if (moveUrl.trim().length === 0) {
      setMoveUrlError(true);
      return;
    }

    setMoveUrlError(false);

    //Add bypass to the URL
    let url = UIHelper.checkAddBypass(moveUrl);

    //Ensure URL has https
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }

    let message = {
      url,
      maxStaggerLength: 0.1 * selectedAttendeeIDs.length
    }

    //Raise socket event for all userIDs with the URL
    SHOWBOAT.SocketIOController.Send(StringVariableHelper.ForceMoveMessageName, selectedAttendeeIDs, message);

    //Reset popup and close
    handleCloseMovePopup();
  };

  const classes = MovePopupStyles();

  if (showMoveMenu) {
    return (
      <div className={styles.moveMenuWrapper}>
        <Paper className={classes.moveHeaderHolder}>
          <PeopleIcon className={classes.peopleIcon}></PeopleIcon>

          <Typography variant="h1" className={classes.moveHeader}>
            Directory
          </Typography>

          <IconButton
            onClick={handleCloseMovePopup}
            className={classes.closeIconButton}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </Paper>

        <TextField
          value={moveUrl}
          onChange={handleMoveUrlChange}
          label="URL"
          fullWidth
          variant="outlined"
          className={classes.urlInput}
          error={moveUrlError}
        />

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={selectAll}
              onChange={handleSelectAllChange}
              name="select all"
            />
          }
          label="SELECT ALL"
          className={classes.selectAllLabel}
        />

        <div className={styles.attendeesHolder}>
          {attendeesInEvent.map((attendee) => {
            return (
              <MovePopupItem
                selectedAttendeeIDs={selectedAttendeeIDs}
                attendee={attendee}
                handleAddAttendee={handleAddAttendee}
                handleRemoveAttendee={handleRemoveAttendee}
                key={attendee.userID}
              />
            );
          })}
        </div>

        <Button
          className={classes.moveButton}
          onClick={handleMoveClick}
          variant="contained"
        >
          MOVE
        </Button>
      </div>
    );
  } else {
    return null;
  }
}
