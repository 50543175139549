import React, { useState, useEffect, useRef } from "react";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";
import { UIHelper } from "../Utilities/UIHelper";

import { testQAItems } from "../Utilities/QATestStore";

export interface QAContextType {
  qaRole: string;
  setQARole: (qaRole: string) => void;
  qaItems: Array<any>;
  setQAItems: (qaItems: Array<any>) => void;
  allowAnonymousQs: boolean;
  setAllowAnonymousQs: (allowAnonymousQs: any) => void;
  attendeesSeeAllQs: boolean;
  setAttendeesSeeAllQs: (attendeesSeeAllQs: boolean) => void;
  upvotedQuestionIDs: Array<string>;
  setUpvotedQuestionIDs: (upvotedQuestionIDs: any) => void;
  handleGetAllQuestions: () => Promise<void>;
  questionIDsPosted: Array<any>;
  setQuestionIDsPosted: (questionIDsPosted: Array<any>) => void;
  submitAnonymously: boolean;
  setSubmitAnonymously: (submitAnonymously: boolean) => void,
  displayedQuestionID: string,
  setDisplayedQuestionID: (displayedQuestionID: string) => void
}

export const QAContext = React.createContext(null);

export const QAContextProvider = (props) => {
  const [qaRole, setQARole] = useState(
    SHOWBOAT.LocalAvatarDataManager.role ===
      StringVariableHelper.ShowboatRoles.presenter
      ? UIHelper.QARole.PRESENTER
      : UIHelper.QARole.ATTENDEE
  );

  const [qaItems, setQAItems] = useState([]);
  const [questionIDsPosted, setQuestionIDsPosted] = useState([]);
  const [allowAnonymousQs, setAllowAnonymousQs] = useState(false);
  const [attendeesSeeAllQs, setAttendeesSeeAllQs] = useState(false);
  const [submitAnonymously, setSubmitAnonymously] = useState(false);

  const [upvotedQuestionIDs, setUpvotedQuestionIDs] = useState([]);

  const [displayedQuestionID, setDisplayedQuestionID] = useState("");

  const qaItemsRef = useRef([]);
  const questionIDsPostedRef = useRef([]);

  useEffect(() => {
    questionIDsPostedRef.current = questionIDsPosted;
  }, [questionIDsPosted]);

  useEffect(() => {
    //Listen for questions being updated
    SHOWBOAT.SocketIOController.OnQuestionAddedOrUpdated.Add(
      handleQuestionAddedOrUpdated
    );

    //Listen for changes to allow anonymous Qs
    SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Add(
      StringVariableHelper.AllowAnonymousQuestionsName,
      handleAllowAnonymousQsChange
    );

    //Listen for changes to attendees see all Qs
    SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Add(
      StringVariableHelper.AttendeesSeeAllQuestionsName,
      handleAttendeesSeeAllQsChange
    );

    //Listen for nametag changes
    SHOWBOAT.UIEventManager.OnNameTagChanged.Add(handleNameTagChange);
    /* SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
      SHOWBOAT.ChangeReason.NameTag,
      handlePlayerNameTagChange
    ); */

    //Listen for reconnection to re-get questions
    SHOWBOAT.SocketIOController.OnLocalPlayerReconnected.Add(handleGetAllQuestions);

    //Listen for changes to currently-displayed questionID
    SHOWBOAT.SocketIOController.OnHighlightedQuestionIDUpdated.Add(handleDisplayedQuestionChange)

    return function cleanup() {
      SHOWBOAT.SocketIOController.OnQuestionAddedOrUpdated.Remove(
        handleQuestionAddedOrUpdated
      );

      SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Remove(
        StringVariableHelper.AllowAnonymousQuestionsName,
        handleAllowAnonymousQsChange
      );

      SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Remove(
        StringVariableHelper.AttendeesSeeAllQuestionsName,
        handleAttendeesSeeAllQsChange
      );

      SHOWBOAT.UIEventManager.OnNameTagChanged.Remove(handleNameTagChange);

      SHOWBOAT.SocketIOController.OnLocalPlayerReconnected.Remove(
        handleGetAllQuestions
      );

    };
  }, []);
  
  const handleDisplayedQuestionChange = (questionID: string) => {
    SHOWBOAT.Logger.Log("Displayed questionID change:", questionID);
    setDisplayedQuestionID(questionID);
  }

  const handleAllowAnonymousQsChange = (data) => {
    setAllowAnonymousQs(data.value);
  };

  const handleAttendeesSeeAllQsChange = (data) => {
    setAttendeesSeeAllQs(data.value);
  };

  const handleNameTagChange = (firstName, lastName) => {
    let newName = `${firstName} ${lastName}`;

    //Loop through QA Items that we have posted and call the socket event
    for (let i = 0; i < questionIDsPostedRef.current.length; i++) {
      //Raise server event to update
      SHOWBOAT.SocketIOController.SetQuestionAskerName(
        questionIDsPostedRef.current[i],
        newName
      );
    }

    for (let i = 0; i < qaItemsRef.current.length; i++) {
      if (
        qaItemsRef.current[i].answererUserID ===
        SHOWBOAT.LocalAvatarDataManager.userID
      ) {
        //Raise server event if this avatar data is our own
        SHOWBOAT.SocketIOController.SetQuestionAnswererName(
          qaItemsRef.current[i].questionID,
          newName
        );
      }
    }
  };

  const handleQuestionAddedOrUpdated = (newQuestion) => {
    SHOWBOAT.Logger.Log("Question added/updated", newQuestion);
    
    //Check if QA list contains this question
    if (
      qaItemsRef.current.some(
        (item) => item.questionID === newQuestion.questionID
      )
    ) {
      let currentQAList = qaItemsRef.current;
      let newQAList = currentQAList.map((item) =>
        item.questionID === newQuestion.questionID ? newQuestion : item
      );

      qaItemsRef.current = newQAList;
      setQAItems(newQAList);
    } else {
      //Just add the item to the QA list
      qaItemsRef.current = [...qaItemsRef.current, newQuestion];
      setQAItems((qaItems) => [...qaItems, newQuestion]);
    }
  };

  const handleGetAllQuestions = async () => {
    try {
      let questions = await SHOWBOAT.SocketIOController.GetAllQuestions();

      if (questions) {
        qaItemsRef.current = questions;
        setQAItems(questions);
      }
    } catch (error) {
      SHOWBOAT.Logger.Error("Error getting questions", error);
    }
    
    try {
      //Also get currently-displayed question
      const currentQuestion =
        await SHOWBOAT.SocketIOController.GetHighlightedQuestionID();

      SHOWBOAT.Logger.Log("Current question response:", currentQuestion);

      if (currentQuestion) {
        setDisplayedQuestionID(currentQuestion);
      }
    } catch (error) {
      SHOWBOAT.Logger.Error("Error getting currently-displayed question", error)
    }
    
  };

  let val: QAContextType = {
    qaRole,
    setQARole,
    qaItems,
    setQAItems,
    allowAnonymousQs,
    setAllowAnonymousQs,
    attendeesSeeAllQs,
    setAttendeesSeeAllQs,
    upvotedQuestionIDs,
    setUpvotedQuestionIDs,
    handleGetAllQuestions,
    questionIDsPosted,
    setQuestionIDsPosted,
    submitAnonymously,
    setSubmitAnonymously,
    displayedQuestionID,
    setDisplayedQuestionID
  };

  return <QAContext.Provider value={val}>{props.children}</QAContext.Provider>;
};
