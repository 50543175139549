import { Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState, useContext } from 'react';

import { ServerHelper } from '../../Utilities/ServerHelper';
import { IntakeContext, IntakeContextType } from '../../context/IntakeContext';

import { EventCountdownStyles } from './EventCountdownStyles';
import styles from './EventCountdown.module.css';
import MobileEventCountdown from '../../MobileIntake/MobileEventCountdown/MobileEventCountdown';

export default function EventCountdown(): ReactElement {
  
  const {
    toggleUnblockEnter,
  } : IntakeContextType = useContext(IntakeContext);

  const [ daysState, setDaysState ] = useState(0);
  const [ hoursState, setHoursState ] = useState(0);
  const [ minutesState, setMinutesState ] = useState(0);
  const [ secondsState, setSecondsState ] = useState(0);

  useEffect(() => {

    let totalTimeINeedToWaitForStart : number = new Date(SHOWBOAT.ApplicationSkin.startDateTime).getTime() - new Date(ServerHelper.loginTimestamp).getTime();
    let myLoginAttemptTime : number = new Date().getTime();

    //Initial check for earlyAccess
    let nowInitial : number = new Date().getTime();

    if (ServerHelper.allowEarlyAccess && (nowInitial > new Date(ServerHelper.accessTime).getTime())) {
      toggleUnblockEnter(true);
    }

    if (ServerHelper.doorsOpen !== undefined && ServerHelper.enableDoors !== undefined) {
      if ((nowInitial > new Date(ServerHelper.doorsOpen).getTime()) && (ServerHelper.enableDoors === true)) {
        toggleUnblockEnter(true);
      }
    }

    var intervalIdentifier = setInterval(function() {

      var now : number = new Date().getTime();
      var howLongIHaveBeenWaitingSoFar : number = now - myLoginAttemptTime;

      var howMuchLongerIStillNeedToWait : number = totalTimeINeedToWaitForStart - howLongIHaveBeenWaitingSoFar;
    
      var days : number = Math.floor(howMuchLongerIStillNeedToWait / (1000 * 60 * 60 * 24));
      setDaysState(days);
      var hours : number = Math.floor((howMuchLongerIStillNeedToWait % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      setHoursState(hours);
      var minutes : number = Math.floor((howMuchLongerIStillNeedToWait % (1000 * 60 * 60)) / (1000 * 60));
      setMinutesState(minutes)
      var seconds : number = Math.floor((howMuchLongerIStillNeedToWait % (1000 * 60)) / 1000);
      setSecondsState(seconds);

      //If registration has allowEarlyAccess boolean, check if current time has reached accessTime. If so, enable enter
      if ((now > new Date(ServerHelper.accessTime).getTime()) && ServerHelper.allowEarlyAccess) {
        toggleUnblockEnter(true);
      }

      //If time is before doorsOpen time and doors open are enabled, enable enter
      if (ServerHelper.doorsOpen !== undefined && ServerHelper.enableDoors !== undefined) {
        if ((now > new Date(ServerHelper.doorsOpen).getTime()) && (ServerHelper.enableDoors === true)) {
          toggleUnblockEnter(true);
        }
      }
     
      //If countdown is finished, clear the interval and unblock enter button
      if (howMuchLongerIStillNeedToWait < 0) {
        clearInterval(intervalIdentifier);
        toggleUnblockEnter(true);
      }
    }, 250);

    return function cleanup() {
      clearInterval(intervalIdentifier);
    }
  }, [toggleUnblockEnter]);

  const classes = EventCountdownStyles();
  
  return (
    <React.Fragment>
      {ServerHelper.useMobileApp
      ?
      <MobileEventCountdown days={daysState} hours={hoursState} minutes={minutesState} seconds={secondsState} />
      :
      <Paper elevation={0} className={classes.eventCountdownHolder}>
        <div className={styles.countdownHolder}>
          <div className={styles.countdownLineHolder}>
            <Paper className={classes.numberHolder} elevation={0}>
              <Typography variant="body1" className={classes.number}>
                {daysState <= 0 ? 0 : daysState}
              </Typography>
            </Paper>
            <Typography variant="body1" className={classes.timeLabel}>
              Days
            </Typography>
          </div>
          <div className={styles.countdownLineHolder}>
            <div className={styles.numberHolder}>
              <Paper className={classes.numberHolder} elevation={0}>
                <Typography variant="body1" className={classes.number}>
                  {hoursState <= 0 ? 0 : hoursState}
                </Typography>
              </Paper>
              <Typography variant="body1" className={classes.timeLabel}>
                Hours
              </Typography>
            </div>
          </div>
          <div className={styles.countdownLineHolder}>
            <Paper className={classes.numberHolder} elevation={0}>
              <Typography variant="body1" className={classes.number}>
                {minutesState <= 0 ? 0 : minutesState}
              </Typography>
            </Paper>
            <Typography variant="body1" className={classes.timeLabel}>
              Minutes
            </Typography>
          </div>
          <div className={styles.countdownLineHolder}>
            <Paper className={classes.numberHolder} elevation={0}>
              <Typography variant="body1" className={classes.number}>
                {secondsState <= 0 ? 0 : secondsState}
              </Typography>
            </Paper>
            <Typography variant="body1" className={classes.timeLabel}>
              Seconds
            </Typography>
          </div>
        </div>
      </Paper>
      }
      
    </React.Fragment>
  );
}
