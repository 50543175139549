import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { ServerHelper } from "../Utilities/ServerHelper";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";

export interface AppContextType {
  isPresenter: boolean;
  toggleIsPresenter: (isPresenter: boolean) => void;
  isTeleportationBlocked: boolean;
  toggleIsTeleportationBlocked: (isTeleportationBlocked: boolean) => void;
  isDebugModeActive: boolean;
  toggleIsDebugModeActive: (isDebugModeActive: boolean) => void;
  selectedAttendeeIDContext: string;
  setSelectedAttendeeIDContext: (attendeeID: string) => void;
  avatarLoadComplete: boolean;
  toggleAvatarLoadComplete: (loadComplete: boolean) => void;
  currentSidebarMode: string;
  setCurrentSidebarMode: (mode: string) => void;
  photoPreview: boolean;
  setPhotoPreview: (photoPreview: boolean) => void;
  showMoveMenu: boolean;
  setShowMoveMenu: (showMoveMenu: boolean) => void;
  bypassJoinCompleted: boolean;
  setBypassJoinCompleted: (bypassJoinCompleted: boolean) => void;
  preferredDragHeight: number;
  setPreferredDragHeight: (preferredDragHeight: number) => void;
}

export const AppContext = React.createContext(null);

export const AppContextProvider = (props) => {
  const [isPresenter, toggleIsPresenter] = useState(
    SHOWBOAT.LocalAvatarDataManager.avatarData.role ===
      StringVariableHelper.ShowboatRoles.presenter
      ? true
      : false
  );
  const [isTeleportationBlocked, toggleIsTeleportationBlocked] =
    useState(false);
  const [isDebugModeActive, toggleIsDebugModeActive] = useState(false);
  const [selectedAttendeeIDContext, setSelectedAttendeeIDContext] =
    useState(null);
  const [currentSidebarMode, setCurrentSidebarMode] = useState("");
  const [avatarLoadComplete, toggleAvatarLoadComplete] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(false);

  const [showMoveMenu, setShowMoveMenu] = useState(false);

  const [bypassJoinCompleted, setBypassJoinCompleted] = useState(false);

  const [preferredDragHeight, setPreferredDragHeight] = useState(null);

  useEffect(() => {
    //Add pause listener for Jumbotron element on desktop devices
    if (!isMobile) {
      SHOWBOAT.JumbotronHelper.OnJumbotronPaused.Add(handleJumbotronPaused);
    }

    //Listen for document visibility changing to resume jumbotron content
    document.addEventListener("visibilitychange", handleVisibilityChange);

    SHOWBOAT.UIEventManager.OnWebGLContextRestored.Add(
      handleWebGLContextRestored
    );

    return function cleanup() {
      SHOWBOAT.JumbotronHelper.OnJumbotronPaused.Remove(handleJumbotronPaused);

      document.removeEventListener("visibilitychange", handleVisibilityChange);

      SHOWBOAT.UIEventManager.OnWebGLContextRestored.Remove(
        handleWebGLContextRestored
      );
    };
  }, []);

  const handleWebGLContextRestored = () => {
    document.getElementById("canvasWrapper").style.width = "100.01%";

    SHOWBOAT.ShowboatLoader.getEngine().resize();

    document.getElementById("canvasWrapper").style.width = "100%";
  };

  const handleVisibilityChange = () => {
    //If document visibility changes, call to resume jumbotron content
    SHOWBOAT.JumbotronHelper.resumeCurrentJumbotronContent();
  };

  const handleJumbotronPaused = () => {
    SHOWBOAT.JumbotronHelper.playCurrentJumbotronElement();
  };

  const drawerProviderValue: AppContextType = {
    isPresenter,
    toggleIsPresenter,
    isTeleportationBlocked,
    toggleIsTeleportationBlocked,
    isDebugModeActive,
    toggleIsDebugModeActive,
    selectedAttendeeIDContext,
    setSelectedAttendeeIDContext,
    avatarLoadComplete,
    toggleAvatarLoadComplete,
    currentSidebarMode,
    setCurrentSidebarMode,
    photoPreview,
    setPhotoPreview,
    showMoveMenu,
    setShowMoveMenu,
    bypassJoinCompleted,
    setBypassJoinCompleted,
    preferredDragHeight,
    setPreferredDragHeight,
  };

  return (
    <AppContext.Provider value={drawerProviderValue}>
      {props.children}
    </AppContext.Provider>
  );
};
