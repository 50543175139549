import axios from "axios";
import {
  isMobile,
  isMobileOnly,
  isTablet,
  isIOS,
  isSafari,
} from "react-device-detect";

import { ServerHelper } from "./ServerHelper";
import { StringVariableHelper } from "./StringVariableHelper";

export class UIHelper {
  /* String enums*/
  public static ShowboatModeTypes = {
    attendees: "attendees",
    presenter: "presenter",
    backstage: "backstage",
  };

  public static QASortEnum = {
    NEWEST: "newest",
    UPVOTES: "upvotes",
  };

  public static QATabEnum = {
    PENDING: "pending",
    APPROVED: "approved",
    PASS: "pass",
    DONE: "done",
  };

  public static QARole = {
    PRESENTER: "presenter",
    MODERATOR: "moderator",
    ATTENDEE: "attendee",
  };

  public static AllowLevel = {
    DESKTOP: "desktop",
    TABLETS: "tablets",
    MOBILE_TABLETS: "mobile-tablets",
    MOBILE_NO_TABLETS: "mobile-no-tablets",
    MOBILE_HYBRID_TABLETS: "mobile-hybrid-tablets",
    MOBILE_HYBRID_NO_TABLETS: "mobile-hybrid-no-tablets",
  };

  /**/

  public static OnForceScreenshareOff: NSM.Delegate = new NSM.Delegate();
  public static OnForceVideoshareOff: NSM.Delegate = new NSM.Delegate();
  public static OnForcePresenterWebcamOff: NSM.Delegate = new NSM.Delegate();
  public static OnForceSlideOff: NSM.Delegate = new NSM.Delegate();
  public static OnForceLivestreamOff: NSM.Delegate = new NSM.Delegate();
  public static OnMobileJumbotronFullscreen: NSM.Delegate = new NSM.Delegate();

  public static OnShowJumbotronPlayButton: NSM.Delegate = new NSM.Delegate();

  public static hdTimeout;

  public static htmlImage: HTMLImageElement;

  public static imgBase64: string;

  public static hasAudioContextBeenLogged: boolean = false;

  public static Init(): void {
    (window as any).UIHelper = UIHelper;

    SHOWBOAT.StreamingUserMedia.OnCameraStarted.Add(UIHelper.OnCameraStarted);
    SHOWBOAT.StreamingUserMedia.OnCameraStopped.Add(UIHelper.OnCameraStopped);
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Add(
      UIHelper.OnMicrophoneStarted
    );
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStopped.Add(
      UIHelper.OnMicrophoneStopped
    );

    SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Add(
      UIHelper.OnLocalVideoShareVolumeChange
    );

    SHOWBOAT.UIEventManager.OnImageCaptureComplete.Add(
      UIHelper.OnImageCaptureComplete
    );

    //Advanced Debugging
    SHOWBOAT.SocketIOController.OnPrivateMessage.Add(
      StringVariableHelper.AdvancedDebuggingMessageName,
      UIHelper.OnAdvancedDebuggingMessage
    );
    SHOWBOAT.UIEventManager.OnAdvancedDebugToggle.Add(
      UIHelper.OnAdvancedDebugToggleHandler
    );

    //Force move
    SHOWBOAT.SocketIOController.OnPrivateMessage.Add(
      StringVariableHelper.ForceMoveMessageName,
      UIHelper.OnForceMove
    );
  }

  public static OnCameraStarted = () => {
    SHOWBOAT.LocalAvatarDataManager.setCameraEnabled(true, true);
  };

  public static OnCameraStopped = () => {
    SHOWBOAT.LocalAvatarDataManager.setCameraEnabled(false, true);
  };

  public static OnMicrophoneStarted = () => {
    SHOWBOAT.LocalAvatarDataManager.setMicEnabled(true, true);
  };

  public static OnMicrophoneStopped = () => {
    SHOWBOAT.LocalAvatarDataManager.setMicEnabled(false, true);
  };

  public static OnLocalVideoShareVolumeChange = (volume: number) => {
    SHOWBOAT.SocketIOController.SetServerEventVariable(
      SHOWBOAT.PlayerAudioController.LocalVideoVolumeEventName,
      { volume: volume }
    );
  };

  public static OnImageCaptureComplete = (base64Image) => {
    UIHelper.imgBase64 = base64Image;

    //Create img tag with base64 as src
    let htmlImg = document.createElement("img");
    htmlImg.src = base64Image;

    UIHelper.htmlImage = htmlImg;
  };

  public static OnAdvancedDebuggingMessage = (data: any) => {
    if (data.message.doAdvancedDebug) {
      if (UIHelper.hasAudioContextBeenLogged === false) {
        //AudioContext has not been logged, so log it and mark the boolean true
        //Send AudioContext object and SystemInformation to server
        SHOWBOAT.Logger.Server(
          "Audio Context:",
          SHOWBOAT.AudioContextManager.AudioContext.currentTime,
          SHOWBOAT.AudioContextManager.AudioContext.state,
          SHOWBOAT.AudioContextManager.AudioContext.sampleRate
        );

        SHOWBOAT.Logger.Server(
          "User Information:",
          SHOWBOAT.SystemInformation.getDebugString()
        );

        UIHelper.hasAudioContextBeenLogged = true;
      }

      SHOWBOAT.Logger.WebLogLevel = SHOWBOAT.LogLevel.Debug;
      SHOWBOAT.Logger.LiveswitchLogLevel = SHOWBOAT.LogLevel.Debug;
    } else {
      UIHelper.hasAudioContextBeenLogged = false;

      SHOWBOAT.Logger.WebLogLevel = SHOWBOAT.LogLevel.Error;
      SHOWBOAT.Logger.LiveswitchLogLevel = SHOWBOAT.LogLevel.Warn;
    }
  };

  public static OnAdvancedDebugToggleHandler = (
    userIDs: string[],
    doAdvancedDebug: boolean
  ) => {
    let msg = { doAdvancedDebug: doAdvancedDebug };
    SHOWBOAT.SocketIOController.Send(
      StringVariableHelper.AdvancedDebuggingMessageName,
      userIDs,
      msg
    );
  };

  public static removeQueryParam = (key, sourceURL) => {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  };

  public static checkIfQueryStringParams = (url) => {
    //Check if URL contains a query param
    return url.indexOf("?") !== -1;
  };

  public static checkAddBypass = (url): string => {
    //Check if the href is a showboat URL
    if (
      url.includes("localhost") ||
      url.includes("showboatui.inputinput") ||
      url.includes("go.showboat.live")
    ) {
      //Ensure link doesn't already have bypass
      if (url.includes("&bypass") || url.includes("?bypass")) return url;

      return UIHelper.checkIfQueryStringParams(url)
        ? url + "&bypass=true"
        : url + "?bypass=true";
    } else {
      //Just return the href - no need to add bypass
      return url;
    }
  };

  public static DisableWebcamHD = async () => {
    //Check if operation is in progress. If so, retry in .5 seconds
    if (SHOWBOAT.StreamingUserMedia.isOperationInProgress()) {
      UIHelper.hdTimeout = setTimeout(UIHelper.DisableWebcamHD, 500);
    } else {
      //Just do the disable
      await SHOWBOAT.StreamingUserMedia.SetHDMode(false);
    }
  };

  public static OnForceMove = (data) => {
    let message = data.message;
    let url = message.url;
    let maxStaggerTime = message.maxStaggerLength;
    let staggerTime = 3 + maxStaggerTime * Math.random();

    SHOWBOAT.UIEventManager.OnForceMove.Raise(staggerTime);

    //Start timeout with staggerTime to do the force move
    setTimeout(function () {
      //Show the loading screen, then navigate after 1 second
      SHOWBOAT.ShowboatLoader.OnShowLoadScreen.Raise();
      setTimeout(function () {
        window.location = url;
      }, 1500);
    }, staggerTime * 1000);
  };

  public static CheckForCachedColor = () => {
    //Check for cached color in local storage
    let colorHex = localStorage.getItem(
      StringVariableHelper.LocalStorageProperties.PreferredColorHex
    );
    let avatarColors = SHOWBOAT.ApplicationSkin.primaryAvatarColors;

    if (colorHex) {
      //Check if avatar color array has the hex
      if (avatarColors.includes(colorHex)) {
        //Array has the hex, so set the correct index
        let index = avatarColors.indexOf(colorHex);
        SHOWBOAT.LocalAvatarDataManager.color = index;
        SHOWBOAT.UIEventManager.OnAvatarColorChanged.Raise(index);
      } else {
        SHOWBOAT.LocalAvatarDataManager.color = 0;
        SHOWBOAT.UIEventManager.OnAvatarColorChanged.Raise(0);
      }
    } else {
      SHOWBOAT.LocalAvatarDataManager.color = 0;
      SHOWBOAT.UIEventManager.OnAvatarColorChanged.Raise(0);
    }

    return colorHex;
  };

  public static CheckForCachedFace = () => {
    //Check for face index in local storage
    let faceIndex = localStorage.getItem(
      StringVariableHelper.LocalStorageProperties.PreferredFaceIndex
    );

    if (faceIndex) {
      //Just set the face index for now
      SHOWBOAT.LocalAvatarDataManager.face = parseInt(faceIndex);
      SHOWBOAT.UIEventManager.OnAvatarFaceChanged.Raise(parseInt(faceIndex));
    }

    if (faceIndex) {
      return parseInt(faceIndex);
    } else {
      return undefined;
    }
  };

  public static SendErrorLog = async (errorText: string) => {
    try {
      await axios.post("/error-log", {
        bookingID: ServerHelper.loginResult.eventID,
        loginCode: ServerHelper.loginCode,
        error: errorText,
        intakeData: {
          firstName: SHOWBOAT.LocalAvatarDataManager.firstName,
          lastName: SHOWBOAT.LocalAvatarDataManager.lastName,
          company: SHOWBOAT.LocalAvatarDataManager.company,
        },
        systemInformation: SHOWBOAT.SystemInformation.getDebugString(),
      });
    } catch (error) {
      SHOWBOAT.Logger.Log("Error occurred sending error log", error);
    }
  };

  public static AddCameraPreviewTo2D = (
    video: HTMLVideoElement,
    type: string,
    userID: string
  ) => {
    video.id = userID;
  };

  public static RemoveCameraPreviewTo2D = (userID: string) => {
    //Remove the preview with the ID of the passed userID
  };

  public static handleHangUpClick = () => {
    //If ServerHelper.hangUpURL is undefined or an empty string, just reload to home page. Otherwise, redirect to specified hangUpURL for event
    if (ServerHelper.hangUpURL === undefined || ServerHelper.hangUpURL === "") {
      //Ensure bypass gets cut from the URL

      window.location.replace(
        UIHelper.removeQueryParam("bypass", window.location.href)
      );
    } else {
      //Double-check if URL starts with http first
      if (!ServerHelper.hangUpURL.trim().startsWith("http")) {
        window.location.href = "https://" + ServerHelper.hangUpURL;
        return false;
      } else {
        window.location.href = ServerHelper.hangUpURL;
        return false;
      }
    }
  };

  public static appendSpecialMobileStylesheet = () => {
    document.head.appendChild(document.createElement("style")).innerHTML = `
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 60px #111112 inset !important;
        background-color: #111112 !important;
        background-clip: content-box !important;
        -webkit-text-fill-color: white !important;
      }
    `;
  };

  /* public static checkIfAllow = (enableMobile, hybridLogin?) => {
    //Function to determine if login is enabled
    let AllowLevel = UIHelper.AllowLevel;

    let allowLevel = enableMobile ? AllowLevel.MOBILE_NO_TABLETS : AllowLevel.MOBILE_HYBRID_NO_TABLETS;

    if (allowLevel === AllowLevel.DESKTOP) {
      //Reject any tablet or phone
      return isMobile ? false : true;
    } else if (allowLevel === AllowLevel.TABLETS) {
      //Reject any non-tablet
      return isMobileOnly ? false : true;
    } else {
      //For any mobile-inclusive allow level, reject if iOS and non-safari browser
      if (isMobileOnly) {
        let allowMobile;

        if (isIOS && !isSafari) {
          allowMobile = false;
        } else {
          //Device is either non-iOS, or iOS and Safari
          //So, check further constraints
          if (
            allowLevel === AllowLevel.MOBILE_HYBRID_NO_TABLETS ||
            allowLevel === AllowLevel.MOBILE_HYBRID_TABLETS
          ) {
            allowMobile = hybridLogin ? true : false;
            return allowMobile;
          }

          allowMobile = true;
        }

        return allowMobile;
      } else if (isTablet) {
        //Check allow level for tablets
        return allowLevel === AllowLevel.MOBILE_HYBRID_TABLETS ||
          allowLevel === AllowLevel.MOBILE_TABLETS
          ? true
          : false;
      } else {
        //Desktop device, return true
        return true;
      }
    }
  }; */

  public static async sendChatNotification(type) {
    try {
      const dataSendNotification = await axios.post(
        ServerHelper.appAPIUrl + "/notification-send",
        JSON.stringify({
          eventName: ServerHelper.loginResult.bookingName,
          enteredUser:
            SHOWBOAT.LocalAvatarDataManager.lastName !== ""
              ? `${SHOWBOAT.LocalAvatarDataManager.firstName} ${SHOWBOAT.LocalAvatarDataManager.lastName}`
              : SHOWBOAT.LocalAvatarDataManager.firstName,
          bookingID: ServerHelper.loginResult.eventID,
          type,
        })
      );

      SHOWBOAT.Logger.Log("Data send notification:", dataSendNotification);

      return true;
    } catch (error) {
      SHOWBOAT.Logger.Error("Error sending notification:", error);
      return false;
    }
  }

  /*
    public static timerTesting = async () => {
        await SHOWBOAT.ServerTimeSyncEvent.SyncTimeWithServer();

        SHOWBOAT.ServerTimeSyncEvent.Register("FooEvent",7000, UIHelper.testEvent);

    }

    public static testEvent = () => {
        console.log(Date.now() + "EVENT");
    }
    */
}
