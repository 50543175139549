import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { isSafari } from "react-device-detect";

import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import { PresenterHelper } from "../../../../Utilities/PresenterHelper";
import { UIHelper } from "../../../../Utilities/UIHelper";
import LocalVideoControls from "./LocalVideoControls/LocalVideoControls";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";

import styles from "./LocalVideo.module.css";
import { LocalVideoStyles } from "./LocalVideoStyles";
import {
  PopupContext,
  PopupContextType,
} from "../../../../context/PopupContext";

export default function LocalVideo(): ReactElement {
  const {
    currentPresenterTool,
    playLocalVideo,
    togglePlayLocalVideo,
    setCurrentPresenterTool,
    jumbotronFullscreenView,
    loopLocalVideo,
    setLoopLocalVideo
  }: PresenterContextType = useContext(PresenterContext);

  const {
    setToolXPosition,
    setToolYPosition,
    setToolWidth,
    setToolHeight,
  }: PopupContextType = useContext(PopupContext);

  const [streamVideo, toggleStreamVideo] = useState(
    PresenterHelper.currentPresenterID ===
      SHOWBOAT.LocalAvatarDataManager.userID &&
      PresenterHelper.myCurrentPresentationMode ===
        SHOWBOAT.JumbotronMode.localVideoShare
      ? true
      : false
  );
  const [, setInputFiles] = useState([]);
  const [disableStreamToggle, toggleDisableStreamToggle] = useState(true);
  const [hasCanvas, toggleHasCanvas] = useState(false);
  const [play, togglePlay] = useState(false);
  const [videoMuted, toggleVideoMuted] = useState(false);
  const [localVideoVolume, setLocalVideoVolume] = useState(100);
  const [hideLocalVideoPreview, setHideLocalVideoPreview] = useState(
    jumbotronFullscreenView && streamVideo
  );

  const streamVideoRef = useRef(false);
  const loopLocalVideoRef = useRef(loopLocalVideo);
  const playPauseRef = useRef(false);
  const volumeRef = useRef(100);
  const dragTimeoutRef = useRef(null);
  const disableToggleRef = useRef(null);

  //Video upload handler
  async function handleVideoUpload(event) {
    let acceptedFileTypes = ["video/mp4", "video/quicktime", "video/m4v"];

    if (
      this.files[0] === undefined ||
      !acceptedFileTypes.includes(this.files[0].type)
    ) {
      return;
    }

    if (this.files.length === 0) {
      return;
    } else {
      //Force a dismount of the video controls
      toggleHasCanvas(false);

      //Ensure the preview holder is cleared
      let previewHolder = document.getElementById("localVideoPreviewHolder");
      if (previewHolder) {
        PresenterHelper.removeVideoPreviewFromPopup("localVideoPreviewHolder");
      }

      let file = (document.getElementById("fileInput") as HTMLInputElement)
        .files[0];

      setInputFiles(this.files[0]);

      //Set SRC on the video
      let fileURL = URL.createObjectURL(file);

      //Append the video preview to the preview div
      await PresenterHelper.CreateLocalVideoPreview(fileURL, loopLocalVideoRef.current);

      //Enable the "share" toggle
      toggleDisableStreamToggle(false);

      //Start drawing to the canvas
      PresenterHelper.currentLocalVideoController.startCanvasDrawing();

      toggleHasCanvas(true);

      //Ensure play button is shown
      togglePlay(false);

      //PresenterHelper.currentLocalVideoController.canvas.id = "localVideoCanvas";

      //Set up volume level change listener/muted listener
      PresenterHelper.LocalVideo.onvolumechange = function () {
        if (PresenterHelper.LocalVideo.muted) {
          console.warn("SETTING TO 0");
          SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Raise(0);
          return;
        }
      };

      //Set up video pause listener to show pause state
      PresenterHelper.LocalVideo.onpause = function () {
        togglePlay(false);
      }; 
    }
  }

  /**/

  useEffect(() => {
    //Check if there is a video already in PresenterHelper. If so, add to preview
    //Also ensure the video is unmuted
    //Only append the preview if we are not currently streaming and in 2D view
    if (PresenterHelper.LocalVideo) {
      appendLocalVideoPreview(
        !jumbotronFullscreenView || (jumbotronFullscreenView && !streamVideo)
          ? true
          : false
      );
    }

    return function cleanup() {
      if (PresenterHelper.LocalVideo) {
        PresenterHelper.LocalVideo.onpause = null;
      }

    }
  }, [hideLocalVideoPreview, streamVideo]);

  useEffect(() => {
    //Determine if we need to hide the preview on mount
    if (jumbotronFullscreenView && streamVideo) {
      setHideLocalVideoPreview(true);

      //Set visibility to hidden
      let video = document.getElementById("localVideo") as HTMLVideoElement;
      
      if (video) {
        video.style.visibility = "hidden";
      }
    } else {
      setHideLocalVideoPreview(false);

      //Set visibility to visible
      let video = document.getElementById("localVideo");

      if (video) {
        video.style.visibility = "visible";
      }
    }
  }, [jumbotronFullscreenView, streamVideo]);

  useEffect(() => {
    //Set play/pause button to whatever is currently set on video
    let localVideo = document.getElementById("localVideo") as HTMLVideoElement;
    if (localVideo) {
      togglePlay(localVideo.paused ? false : true);
    }

    UIHelper.OnForceVideoshareOff.Add(handleVideoshareForceOff);

    return function cleanup() {
      if (PresenterHelper.LocalVideo) {
        PresenterHelper.LocalVideo.onvolumechange = null;
      }

      //If livestream is disabled, stop drawing to canvas, mute, and pause the video
      if (!streamVideoRef.current) {
        if (PresenterHelper.currentLocalVideoController) {
          PresenterHelper.currentLocalVideoController.stopCanvasDrawing();
        }

        if (PresenterHelper.LocalVideo) {
          PresenterHelper.LocalVideo.muted = true;
          PresenterHelper.LocalVideo.pause();
        }
      }

      if (disableToggleRef.current) {
        clearTimeout(disableToggleRef.current);
      }

      UIHelper.OnForceVideoshareOff.Remove(handleVideoshareForceOff);

      if (document.getElementById("fileInput") !== null) {
        document
          .getElementById("fileInput")
          .removeEventListener("change", handleVideoUpload, false);
      }

      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    //Add listener for file upload
    if (
      currentPresenterTool ===
      StringVariableHelper.PresenterToolNames.localVideo
    ) {
      let fileInput = document.getElementById("fileInput");

      fileInput.addEventListener("change", handleVideoUpload, false);
    }
  }, [currentPresenterTool]);

  useEffect(() => {
    //Ensure local state/ref stay up to date with context
    toggleStreamVideo(playLocalVideo);
    streamVideoRef.current = playLocalVideo;
  }, [playLocalVideo]);

  const appendLocalVideoPreview = (doAppend: boolean) => {
    let container = document.getElementById("localVideoPreviewHolder");

    if (container && PresenterHelper.currentLocalVideoController) {
      if (doAppend) {
        container.appendChild(
          //PresenterHelper.currentLocalVideoController.canvas
          PresenterHelper.currentLocalVideoController.getVideoElement()
        );
      }

      //Enable streamVideo toggle
      if (!disableToggleRef.current) {
        toggleDisableStreamToggle(false);
      }

      //Show video controls
      toggleHasCanvas(true);

      //Ensure the video is visible
      PresenterHelper.LocalVideo.style.display = "inline-block";

      //Set up video pause listener to show pause state
      PresenterHelper.LocalVideo.onpause = function () {
        togglePlay(false);
      };

      //Set correct muted/volume states of the video
      if (PresenterHelper.LocalVideo.muted) {
        toggleVideoMuted(true);
        setLocalVideoVolume(0);
      } else {
        toggleVideoMuted(false);
        setLocalVideoVolume(PresenterHelper.LocalVideo.volume * 100);
      }

      //Set muted to state of video
      toggleVideoMuted(PresenterHelper.LocalVideo.muted);
    }
  };

  const handleVideoshareForceOff = () => {
    //Move "stream video" toggle to off position
    toggleStreamVideo(false);
    streamVideoRef.current = false;

    //Pause the video element
    let localVideo = document.getElementById("localVideo") as HTMLVideoElement;
    if (localVideo) {
      localVideo.pause();
    }

    //Show play button, as video gets paused
    togglePlay(false);
  };

  //Video stream toggle
  const handleVideoStreamToggle = () => {
    let toggleState = !streamVideo;

    //Disable share toggle for 1 second
    toggleDisableStreamToggle(true);
    disableToggleRef.current = setTimeout(function () {
      toggleDisableStreamToggle(false);
    }, 1000);

    let videoNode = document.getElementById("localVideo") as HTMLVideoElement;

    if (videoNode) {
      //If starting to stream, set volume to whatever is currently set on video
      if (toggleState) {
        SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Raise(
          videoNode.volume
        );
        
        videoNode.play();

        //Show play button
        togglePlay(true);
      } else {
        //Pause the video
        videoNode.pause();

        togglePlay(false);
      }

      PresenterHelper.OnVideoshareToggle(toggleState);

      toggleStreamVideo(!streamVideo);
      streamVideoRef.current = !streamVideo;
      togglePlayLocalVideo(!streamVideo);
    }
  };

  //Play/pause for custom video controls
  const handlePlayPauseClick = () => {
    let video = document.getElementById("localVideo") as HTMLVideoElement;

    if (video) {
      //If video is paused, play it
      if (video.paused) {
        video.play();
        togglePlay(true);
        playPauseRef.current = true;
      } else {
        video.pause();
        togglePlay(false);
        playPauseRef.current = false;
      }
    }
  };

  const handleCloseLocalVideo = () => {
    //Special close handler for local video - ensure video remains in DOM
    if (PresenterHelper.LocalVideo) {
      document.getElementById("root").appendChild(PresenterHelper.LocalVideo);
      PresenterHelper.LocalVideo.style.display = "none";
    }

    //Set preferred dimensions in presenterContext for popup
    let popup = document.getElementById("presenterToolPopup") as HTMLDivElement;
    if (popup) {
      let viewportOffset = popup.getBoundingClientRect();
      setToolXPosition(viewportOffset.left);
      setToolYPosition(viewportOffset.top);
      setToolWidth(popup.clientWidth);
      setToolHeight(popup.clientHeight);
    }

    setCurrentPresenterTool("");
  };

  //Mute/unmute handler for controls
  const handleVideoMutedToggle = () => {
    let toggleState = !videoMuted;

    //If we are muting, set volume slider value to 0
    if (toggleState) {
      setLocalVideoVolume(0);
    }

    toggleVideoMuted(toggleState);
  };

  //Local video volume
  const handleLocalVideoVolumeChange = (volume: number) => {
    let video = document.getElementById("localVideo") as HTMLVideoElement;
    if (video) {
      video.volume = volume / 100;
      video.muted = false;
    }

    //If we are muted, unmute
    if (videoMuted) {
      toggleVideoMuted(false);
    }

    setLocalVideoVolume(volume);

    volumeRef.current = volume;

    clearTimeout(dragTimeoutRef.current);

    dragTimeoutRef.current = setTimeout(handleDragTimeout, 500);
  };

  const handleDragTimeout = () => {
    //Send SocketIO variable event if we are currently streaming
    if (streamVideoRef.current) {
      SHOWBOAT.SocketIOController.SetServerEventVariable(
        SHOWBOAT.PlayerAudioController.LocalVideoVolumeEventName,
        { volume: volumeRef.current / 100 }
      );
    }
  };

  const handleLoopLocalVideoChange = () => {
    let toggleState = !loopLocalVideo;

    //Set loop property on the local video element
    PresenterHelper.currentLocalVideoController.loop = toggleState;
    
    setLoopLocalVideo(toggleState);
    loopLocalVideoRef.current = toggleState;
  }

  const classes = LocalVideoStyles();

  return (
    <React.Fragment>
      <IconButton
        onClick={handleCloseLocalVideo}
        className={`${classes.closeButton} cancelDrag`}
      >
        <CloseIcon />
      </IconButton>

      <div
        id="localVideoPreviewHolder"
        className={`${styles.videoHolder} cancelDrag`}
        onClick={handlePlayPauseClick}
      >
        {hasCanvas && (
          <LocalVideoControls
            play={play}
            handlePlayPauseClick={handlePlayPauseClick}
            videoMuted={videoMuted}
            handleVideoMutedToggle={handleVideoMutedToggle}
            localVideoVolume={localVideoVolume}
            handleLocalVideoVolumeChange={handleLocalVideoVolumeChange}
            streamVideo={streamVideoRef.current}
          />
        )}
      </div>

      <Button
        disabled={streamVideo}
        variant="text"
        component="label"
        className={`${classes.chooseFileButton} cancelDrag`}
      >
        CHOOSE FILE
        <input
          type="file"
          id="fileInput"
          accept="video/mp4,video/quicktime,video/m4v"
          style={{ display: "none" }}
        />
      </Button>

      {hideLocalVideoPreview && hasCanvas && (
        <Typography variant="body1" className={classes.twoDModeInfo}>
          Playing on presentation display
        </Typography>
      )}

      <FormControlLabel
        control={
          <Switch
            checked={loopLocalVideo}
            onChange={handleLoopLocalVideoChange}
            color="primary"
          />
        }
        disabled={disableStreamToggle || isSafari}
        label="LOOP"
        classes={{
          label: classes.streamVideoLabel,
          root: `${classes.shareVideoToggle} ${classes.loopToggle} cancelDrag`,
        }}
        labelPlacement="start"
      />

      <FormControlLabel
        control={
          <Switch
            checked={streamVideo}
            onChange={handleVideoStreamToggle}
            color="primary"
          />
        }
        disabled={disableStreamToggle || isSafari}
        label="SHARE"
        classes={{
          label: classes.streamVideoLabel,
          root: `${classes.shareVideoToggle} cancelDrag`,
        }}
        labelPlacement="start"
      />
    </React.Fragment>
  );
}
