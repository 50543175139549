import React, { useContext, useEffect, useState } from 'react';
import MailIcon from "@material-ui/icons/Mail";
import { Button, TextField, Typography } from "@material-ui/core";

import { MobileContextType, MobileContext } from '../../../context/MobileContext';
import { OneOnOneChatContext, OneOnOneChatContextType } from '../../../context/OneOnOneChatContext';

import styles from "./MobileSendPrivateMessage.module.css";
import { MobileSendPrivateMessageStyles } from "./MobileSendPrivateMessageStyles";

type Props = {
  isRespond: boolean;
}

var Filter = require("bad-words");

export default function MobileSendPrivateMessage(props: Props) {

  const {
    nameToMessage,
    userIDToMessage,
    respondMessageID 
  } : OneOnOneChatContextType = useContext(OneOnOneChatContext);
  
  const {
    mobilePrivateMessages,
    setMobilePrivateMessages,
    setMobileShowSendPrivateMessage,
    decrementMessageCount,
    mobileShowSendPrivateMessage
  } : MobileContextType = useContext(MobileContext);

  let filter = new Filter();

  const [privateChatMessage, setPrivateChatMessage] = useState("");

  useEffect(() => {
    if (!mobileShowSendPrivateMessage) {
      let textField = document.getElementById(
        "mobileNewPrivateMessageTextField"
      );

      if (textField) {
        textField.style.visibility = "hidden";
      }
    }
  }, [mobileShowSendPrivateMessage])
  
  const handlePrivateChatMessageChange = (e) => {
    setPrivateChatMessage(e.target.value);
  }

  const handlePrivateChatMessageSubmit = (e) => {
    if (privateChatMessage === "") return;

    //Ensure attendee is still in the event
    if (
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(userIDToMessage) !==
      undefined
    ) {
      SHOWBOAT.SocketIOController.SendOne(
        "chat",
        userIDToMessage,
        filter.clean(privateChatMessage)
      );
    }

    //Un-focus the text field
    let textField = document.getElementById("mobileNewPrivateMessageTextField");
    if (textField) {
      textField.blur();
    }

    //Remove this message from the list if we are responding
    if (props.isRespond) {
      setMobilePrivateMessages(
        mobilePrivateMessages.filter(
          (msg) => (msg as any).id !== respondMessageID
        )
      );
      //Decrement message count in context
      decrementMessageCount();
    }

    setPrivateChatMessage("");
    
    setMobileShowSendPrivateMessage(false);
  }

  const handleDismissClick = () => {
    setPrivateChatMessage("");
    setMobileShowSendPrivateMessage(false);
  }
  
  const classes = MobileSendPrivateMessageStyles();
  
  return (
    <div 
      className={styles.mobileRespondPrivateMessageHolder}
      style={{ visibility: mobileShowSendPrivateMessage ? "visible" : "hidden"}}
    >
      <div className={styles.privateMessageRespondHeader}>
        <MailIcon style={{ color: "white", marginRight: "14px" }} />

        <Typography className={classes.newMessageHeader}>
          {props.isRespond
            ? "REPLY TO PRIVATE MESSAGE"
            : "SEND PRIVATE MESSAGE"}
        </Typography>
      </div>

      {props.isRespond && <div className={styles.replyMessageHolder}></div>}

      <TextField
        multiline
        rows={3}
        value={privateChatMessage}
        variant="outlined"
        onChange={handlePrivateChatMessageChange}
        className={props.isRespond ? `${classes.privateMessageTextField} ${classes.privateMessageTextFieldRespond}` : classes.privateMessageTextField}
        placeholder="Send a message"
        inputProps={{
          maxLength: 240,
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handlePrivateChatMessageSubmit(e);
          }
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.noBorder,
            root: classes.chatInput,
            inputAdornedEnd: classes.chatInputAdornedEnd,
          },
          id: "mobileNewPrivateMessageTextField",
          type: "text",
        }}
      />

      <Typography variant="body1" className={classes.privateMessageSubtext}>
        To: {nameToMessage}
      </Typography>

      <Typography variant="body1" className={classes.characterCount}>
        {`${privateChatMessage.length} / 240`}
      </Typography>

      <div className={styles.buttonHolder}>
        <Button
          className={classes.privateMessageButton}
          onClick={handlePrivateChatMessageSubmit}
        >
          SEND
        </Button>
        <Button
          className={`${classes.privateMessageButton} ${classes.privateMessageButtonDismiss}`}
          onClick={handleDismissClick}
        >
          DISMISS
        </Button>
      </div>
    </div>
  );
}