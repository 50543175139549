import React, { ReactElement, useState, useEffect, useRef } from "react";
import {
  Paper,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import $ from "jquery";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import { Picker } from "emoji-mart";
import Linkify from "react-linkify";

import { PrivateMessageStyles } from "./PrivateMessageStyles";
import { UIHelper } from "../../../../../Utilities/UIHelper";
import { ServerHelper } from "../../../../../Utilities/ServerHelper";

/* var Filter = require("bad-words"); */
interface ReflowObj {
  bottomAmount: number;
  elementID: string;
}

interface ReflowHeightObj {
  elementID: string;
  height: number;
}

interface Props {
  message: string;
  userID: string;
  id: number;
  privateMessages?: Array<SHOWBOAT.SocketMessage>;
  add?: (id: string, height: number) => void;
  modify?: (id: string, height: number) => void;
  remove?: (id: string, msgNumber: number) => void;
  mobile?: boolean;
  handleMessageRespond?: (
    respondToName: string,
    respondToUserID: string,
    respondToMessageID: number
  ) => void;
  handleMessageDismiss?: (msgID: number) => any;
}

export default function PrivateMessage(props: Props): ReactElement {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showResponseBar, toggleShowResponseBar] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [showEmojis, toggleShowEmojis] = useState(false);
  const [elementClass, setElementClass] = useState("");
  const [animationQueue, setAnimationQueue] = useState([]);

  const msgID = useRef("");

  const firstRender = useRef(true);
  const classTimer = useRef(null);
  const visibilityTimer = useRef(null);
  const jqueryTimer = useRef(null);

  msgID.current = props.id.toString() + "CHATMESSAGE";

  useEffect(() => {
    //Make sure sendingAttendee is not undefined before accessing first/last names
    let sendingAttendee = SHOWBOAT.RemoteAvatarDataManager.getAvatarData(
      props.userID
    );

    if (sendingAttendee !== undefined) {
      setFirstName(sendingAttendee.firstName);
      setLastName(sendingAttendee.lastName);
    } else {
      setFirstName("");
      setLastName("");
    }

    if (!ServerHelper.useMobileApp) {
      //Use a timeout to get the current height
      jqueryTimer.current = setTimeout(function () {
        let newElementHeight = $(`#${msgID.current}`).outerHeight(true);

        //Add this element to the reflow queue
        props.add(msgID.current, newElementHeight);
      }, 0);

      //Set visibility to visible after 300ms
      visibilityTimer.current = setTimeout(function () {
        document.getElementById(
          `${props.id.toString()}CHATMESSAGE`
        ).style.visibility = "visible";
      }, 300);
    }

    return function cleanup() {
      if (classTimer.current) {
        clearTimeout(classTimer.current);
      }

      if (jqueryTimer.current) {
        clearTimeout(jqueryTimer.current);
      }

      if (visibilityTimer.current) {
        clearTimeout(visibilityTimer.current);
      }
    };
  }, [props.id, props.userID]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (!ServerHelper.useMobileApp) {
      jqueryTimer.current = setTimeout(function () {
        let newElementHeight = $(`#${msgID.current}`).outerHeight(true);

        //Modify this element's height in the reflow queue
        props.modify(msgID.current, newElementHeight + 10);
      }, 0);
    }
  }, [showResponseBar, props.id, props.userID]);

  //RESPOND TO MESSAGE
  const handleRespondClick = () => {
    if (props.mobile) {
      props.handleMessageRespond(
        `${firstName} ${lastName}`,
        props.userID,
        props.id
      );
    } else {
      toggleShowResponseBar(true);
    }
  };

  //Focus text field if showResponseBar is toggled to true
  useEffect(() => {
    if (showResponseBar) {
      let textField = document.getElementById(`${props.id.toString()}INPUT`);

      if (textField !== null) {
        textField.focus();
      }
    }
  }, [showResponseBar, props.id]);

  const handleResponseSubmit = () => {
    //Double check attendee is still in event before sending server message
    if (
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID) !== undefined
    ) {
      SHOWBOAT.SocketIOController.SendOne(
        "chat",
        props.userID,
        responseText /* filter.clean(responseText) */
      );
    }

    toggleShowResponseBar(false);
    setResponseText("");

    //Send dismissal to the reflow queue
    if (!ServerHelper.useMobileApp) {
      props.remove(msgID.current, props.id);
    } else {
      props.handleMessageDismiss(props.id);
    }
  };

  //RESPONSE LOGIC
  const handleResponseTextFieldChange = (e) => {
    setResponseText(e.target.value);
  };

  //EMOJI LOGIC
  const handleEmojiClick = () => {
    toggleShowEmojis(!showEmojis);
  };

  const handleEmojiSelect = (e) => {
    //parse emoji code from event object
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    //append emoji code to message input
    setResponseText(`${responseText} ${emoji}`);

    //hide emoji picker
    toggleShowEmojis(false);
  };

  //DISMISS MESSAGE
  const handleDismissClick = () => {
    toggleShowResponseBar(false);

    //Send dismissal to the reflow queue
    if (!ServerHelper.useMobileApp) {
      props.remove(msgID.current, props.id);
    } else {
      props.handleMessageDismiss(props.id);
    }
  };

  const classes = PrivateMessageStyles();

  let responseBarHolderClasses;

  responseBarHolderClasses = `${classes.privateMessageHolderWithResponseBar}`;

  let linkColor;

  if (SHOWBOAT.ApplicationSkin.theme === "light") {
    linkColor = "black";
  } else {
    linkColor = "white";
  }

  if (showResponseBar) {
    return (
      <Paper
        id={msgID.current}
        className={`${responseBarHolderClasses} ${
          props.privateMessages.length > 1 ? "privateMessage" : null
        }`}
      >
        {showEmojis && (
          <Paper
            elevation={10}
            square
            classes={{ root: classes.emojiSelectHolder }}
          >
            <Picker
              theme={SHOWBOAT.ApplicationSkin.theme}
              onSelect={(e) => handleEmojiSelect(e)}
            />
          </Paper>
        )}

        <Paper className={classes.senderNameHolder}>
          <Typography variant="body1" className={classes.messageSenderName}>
            {firstName} {lastName}
          </Typography>

          <Typography variant="body1" className={classes.messageTextResponse}>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  target={
                    decoratedHref.includes("go.showboat.live")
                      ? "_self"
                      : "_blank"
                  }
                  href={UIHelper.checkAddBypass(decoratedHref)}
                  key={key}
                  style={{ color: linkColor }}
                >
                  {decoratedText}
                </a>
              )}
            >
              {props.message}
            </Linkify>
          </Typography>
        </Paper>

        <Paper className={classes.responseBarHolder}>
          <TextField
            value={responseText}
            label="Send a response"
            onChange={handleResponseTextFieldChange}
            fullWidth
            helperText={`To: ${firstName} ${lastName}`}
            variant="outlined"
            id={`${props.id.toString()}INPUT`}
            className={`${classes.respondMessageInputHolder} messageResponseInput`}
            FormHelperTextProps={{
              className: classes.responseHelperText,
            }}
            InputProps={{
              className: classes.respondMessageInput,
              endAdornment: (
                <InputAdornment position="end">
                  <EmojiEmotionsIcon
                    onClick={handleEmojiClick}
                    classes={{ root: classes.emojiIcon }}
                    fontSize="large"
                  />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 320,
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();

                handleResponseSubmit();
              }
            }}
          />
        </Paper>

        <Button
          onClick={handleResponseSubmit}
          variant="text"
          className={`${classes.sendButton} responseBarCloseButton`}
        >
          SEND
        </Button>
        <Button
          onClick={handleDismissClick}
          variant="text"
          className={`${classes.dismissButton} ${classes.dismissButtonRespond} responseBarCloseButton`}
        >
          DISMISS
        </Button>
      </Paper>
    );
  } else {
    return (
      <Paper
        id={msgID.current}
        className={
          ServerHelper.useMobileApp
            ? `${classes.privateMessageHolder} ${classes.privateMessageHolderMobile} privateMessage`
            : `${classes.privateMessageHolder} privateMessage`
        }
      >
        <Typography
          variant="body1"
          className={
            ServerHelper.useMobileApp
              ? `${classes.messageSenderName} ${classes.messageSenderNameMobile}`
              : classes.messageSenderName
          }
        >
          {firstName} {lastName}
        </Typography>

        <Typography
          variant="body1"
          className={
            ServerHelper.useMobileApp
              ? `${classes.messageText} ${classes.messageTextMobile}`
              : classes.messageText
          }
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target={
                  decoratedHref.includes("go.showboat.live")
                    ? "_self"
                    : "_blank"
                }
                href={UIHelper.checkAddBypass(decoratedHref)}
                key={key}
                style={{ color: linkColor }}
              >
                {decoratedText}
              </a>
            )}
          >
            {props.message}
          </Linkify>
        </Typography>

        {props.message !==
          "Sorry, chat is disabled for this attendee and your message was not seen." && (
          <Button
            onClick={handleRespondClick}
            className={`${classes.respondButton}`}
            variant="text"
          >
            RESPOND
          </Button>
        )}

        <Button
          onClick={handleDismissClick}
          className={`${classes.dismissButton} messageCloseButton`}
          variant="text"
        >
          DISMISS
        </Button>
      </Paper>
    );
  }
}
