import { Button, Checkbox, FormControlLabel, Switch } from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import { PresenterHelper } from "../../../../Utilities/PresenterHelper";
import LivestreamScreenshareVolume from "../LivestreamScreenshareVolume/LivestreamScreenshareVolume";

import styles from "./Screenshare.module.css";
import { ScreenshareStyles } from "./ScreenshareStyles";

interface Props {}

export default function Screenshare(props: Props): ReactElement {

  const {
    isSharingScreen,
    optimizeScreenshareForVideo,
    toggleIsSharingScreen,
    toggleOptimizeScreenshareForVideo,
    jumbotronFullscreenView,
  }: PresenterContextType = useContext(PresenterContext);

  const [disableShareToggle, toggleDisableShareToggle] = useState(true);
  const [shareScreenshare, toggleShareScreenshare] = useState(
    PresenterHelper.currentPresenterID ===
      SHOWBOAT.LocalAvatarDataManager.userID &&
      PresenterHelper.myCurrentPresentationMode === SHOWBOAT.JumbotronMode.screenShare
      ? true
      : false
  );
  const [hasWindowBeenChosen, toggleHasWindowBeenChosen] = useState(
    PresenterHelper.ScreenshareVideo ? true : false
  );
  const [screenshareVolume, setScreenshareVolume] = useState(100);
  const [hideScreensharePreview, setHideScreensharePreview] = useState(jumbotronFullscreenView);

  const shareScreenshareRef = useRef(false);
  const isMounted = useRef(true);
  const volumeRef = useRef(100);
  const dragTimeoutRef = useRef(null);
  const toggleDisableTimeoutRef = useRef(null);

  useEffect(() => {
    //On mount, check for existence of video preview in PresenterHelper
    //Also add listener for stopping screenshare
    if (PresenterHelper.ScreenshareVideo) {
      let container = document.getElementById("screensharePreviewHolder");
      if (container) container.appendChild(PresenterHelper.ScreenshareVideo);

      //Enable share toggle
      toggleDisableShareToggle(false);

      PresenterHelper.ScreenshareVideo.muted = true;

      //Set the volume values
      setScreenshareVolume(PresenterHelper.ScreenshareVideo.volume * 100);
      volumeRef.current = PresenterHelper.ScreenshareVideo.volume * 100;

      PresenterHelper.ScreenshareVideo.play();
      PresenterHelper.currentScreenshareController.OnScreenshareStopped.Add(
        handleScreenshareStop
      );
    }

    return function cleanup() {
      isMounted.current = false;
      if (PresenterHelper.currentScreenshareController) {
        PresenterHelper.currentScreenshareController.OnScreenshareStopped.Remove(
          handleScreenshareStop
        );
      }

      if (toggleDisableTimeoutRef.current) {
        clearTimeout(toggleDisableTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    //Ensure local state/refs get set when context variable changes
    toggleShareScreenshare(isSharingScreen);
    shareScreenshareRef.current = isSharingScreen;
  }, [isSharingScreen]);

  const appendScreensharePreview = () => {
    //Append preview video
    let videoHolder = document.getElementById("screensharePreviewHolder");
    let newVideo = document.createElement("video");
    newVideo.id = "screensharePreviewVideo";
    newVideo.srcObject =
      SHOWBOAT.PresenterScreenshare.currentPresenterScreenshare.getScreenshareStream();
    PresenterHelper.ScreenshareVideo = newVideo;
    newVideo.muted = true;
    newVideo.play();

    if (videoHolder) videoHolder.appendChild(newVideo);
  };

  const handleChooseWindowClick = async () => {
    //Make sure preview container is cleared
    let container = document.getElementById("screensharePreviewHolder");
    if (container) {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    }

    let screenshareSuccess: boolean =
      await PresenterHelper.OnStartBrowserScreenshare(
        optimizeScreenshareForVideo
      );

    //Add listener for screenshare stopping
    PresenterHelper.currentScreenshareController.OnScreenshareStopped.Add(
      handleScreenshareStop
    );

    if (screenshareSuccess) {
      //Swap button state to "stop screenshare"
      toggleHasWindowBeenChosen(true);

      //Enable toggle
      toggleDisableShareToggle(false);

      appendScreensharePreview();
    }
  };

  //Share screenshare toggle
  const handleToggleShareScreenshare = () => {

    let toggleState = !shareScreenshare;

    //Disable toggle for 1 second
    toggleDisableShareToggle(true);
    toggleDisableTimeoutRef.current = setTimeout(function() {
      toggleDisableShareToggle(false);
    }, 1000)

    //If we are starting to share screenshare, set the initial volume
    if (toggleState) {
      SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Raise(
        screenshareVolume / 100
      );
    }

    PresenterHelper.OnScreenshareToggle(toggleState);

    toggleShareScreenshare(toggleState);
    toggleIsSharingScreen(toggleState);
  };

  //Optimize for video
  const handleOptimizeForVideoChange = (e) => {
    toggleOptimizeScreenshareForVideo(!optimizeScreenshareForVideo);
  };

  //User stopping screenshare
  const handleStopScreenshareClick = () => {

    if (PresenterHelper.currentScreenshareController) {
      PresenterHelper.currentScreenshareController.stopScreenshare();
    }

    //If we are streaming to room, stop it
    if (shareScreenshare) {
      PresenterHelper.OnScreenshareToggle(false);
      toggleShareScreenshare(false);
    }

    //Null out preview video when stopping browser screenshare
    PresenterHelper.ScreenshareVideo = null;

    //Disable share toggle
    toggleDisableShareToggle(true);

    //Show choose window button
    toggleHasWindowBeenChosen(false);
  };

  //Screenshare stopping from browser
  const handleScreenshareStop = () => {

    //Clear out preview div
    let video = document.getElementById("screensharePreviewVideo");
    if (video) {
      video.remove();
    }

    //Null out preview video when stopping browser screenshare
    PresenterHelper.ScreenshareVideo = null;

    //Send button state back to "choose window", and disable "share" toggle
    if (isMounted.current) {
      toggleHasWindowBeenChosen(false);
      toggleDisableShareToggle(true);
    }
  };

  const handleVolumeChange = (volume: number) => {
    //Set volume of the preview video
    let video = document.getElementById(
      "screensharePreviewVideo"
    ) as HTMLVideoElement;
    if (video) {
      video.volume = volume / 100;
    }
    
    setScreenshareVolume(volume);

    volumeRef.current = volume;

    clearTimeout(dragTimeoutRef.current);

    dragTimeoutRef.current = setTimeout(handleDragTimeout, 500);
  };

  const handleDragTimeout = () => {
    //Send SocketIO variable event if we are currently streaming
    if (shareScreenshareRef.current) {
      SHOWBOAT.SocketIOController.SetServerEventVariable(
        SHOWBOAT.PlayerAudioController.LocalVideoVolumeEventName,
        { volume: volumeRef.current / 100 }
      );
    }
  };

  const classes = ScreenshareStyles();

  return (
    <div className={styles.screenshareHolder}>
      <FormControlLabel
        control={
          <Checkbox
            checked={optimizeScreenshareForVideo}
            onChange={handleOptimizeForVideoChange}
            name="checkedB"
            color="primary"
            className={`${classes.checkboxBox} cancelDrag`}
          />
        }
        labelPlacement="start"
        label="Optimize for Video"
        classes={{
          root: classes.checkbox,
          label: classes.checkboxLabel,
        }}
        disabled={hasWindowBeenChosen || shareScreenshare}
      />

      <div id="screensharePreviewHolder" className={styles.previewHolder}>
        {hasWindowBeenChosen && (
          <LivestreamScreenshareVolume
            localVideoVolume={screenshareVolume}
            handleVolumeChange={handleVolumeChange}
          />
        )}
      </div>

      <FormControlLabel
        control={
          <Switch
            checked={shareScreenshare}
            onChange={handleToggleShareScreenshare}
            name="Mode Toggle"
            color="primary"
          />
        }
        label="SHARE"
        labelPlacement="start"
        classes={{
          root: `${classes.screenshareToggle} cancelDrag`,
          label: classes.toggleLabel,
        }}
        disabled={disableShareToggle}
      />
      {hasWindowBeenChosen ? (
        <Button
          className={`${classes.chooseWindowButton} cancelDrag`}
          variant="text"
          onClick={handleStopScreenshareClick}
        >
          STOP SCREENSHARE
        </Button>
      ) : (
        <Button
          className={`${classes.chooseWindowButton} cancelDrag`}
          variant="text"
          onClick={handleChooseWindowClick}
        >
          CHOOSE WINDOW
        </Button>
      )}
    </div>
  );
}
