import { CircularProgress, Dialog, Typography } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";

import styles from "./MobileDisconnectOverlay.module.css";
import { MobileDisconnectOverlayStyles } from "./MobileDisconnectOverlayStyles";

type Props = {};

export default function MobileDisconnectOverlay({}: Props) {
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);

  const showDisconnectPopupRef = useRef(false);
  const serverTimeCheckInterval = useRef(null);
  const firstRender = useRef(true);

  useEffect(() => {
    SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Add(
      handleServerConnectionErrorToggle
    );

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return function cleanup() {
      SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Remove(
        handleServerConnectionErrorToggle
      );

      if (serverTimeCheckInterval.current) {
        clearInterval(serverTimeCheckInterval.current);
      }
    };
  }, []);

  const handleVisibilityChange = async () => {
    if (firstRender.current === true) {
      firstRender.current = false;
      return;
    }
    
    //If document becomes visible, check if we are disconnected or connected
    if (document.visibilityState === "visible") {
      const isConnected = SHOWBOAT.SocketIOController.isConnected;

      if (isConnected) {
        //Run the time check
        const serverTimeResponse = await tryGetServerTime();

        //If server time check fails, show popup and keep running
        if (!serverTimeResponse) {
          setShowDisconnectPopup(true);
          showDisconnectPopupRef.current = true;

          serverTimeCheckInterval.current = setInterval(async function () {
            const serverTimeResponseInterval = await tryGetServerTime();

            //If we get any successful calls, remove the popup
            if (serverTimeResponseInterval) {
              setShowDisconnectPopup(false);
              showDisconnectPopupRef.current = false;

              //Clear interval
              if (serverTimeCheckInterval.current) {
                clearInterval(serverTimeCheckInterval.current);
              }
            }
          }, 3000);
        }
      }
    }
  };

  const tryGetServerTime = async (): Promise<boolean> => {
    try {
      await SHOWBOAT.SocketIOController.GetServerTime();
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleServerConnectionErrorToggle = (isDisplayed: boolean) => {
    //Stop the server time check interval if it is running
    if (serverTimeCheckInterval.current) {
      clearInterval(serverTimeCheckInterval.current);
    }

    setShowDisconnectPopup(isDisplayed);
    showDisconnectPopupRef.current = isDisplayed;
  };

  const classes = MobileDisconnectOverlayStyles();

  return (
    <Dialog
      open={showDisconnectPopup}
      classes={{ paper: classes.disconnectPopup }}
      disableBackdropClick
    >
      <div className={styles.disconnectPopupWrapper}>
        <Typography className={classes.disconnectText}>
          Your browser has disconnected from the server. Please wait for
          auto-reconnection.
        </Typography>

        <CircularProgress className={classes.loadingSpinner} />
      </div>
    </Dialog>
  );
}
