import { StringVariableHelper } from "./StringVariableHelper";

export interface TwoDObject {
    userID : string;
    downstreamController :  SHOWBOAT.LiveswitchDownstreamController;
    avatarData : SHOWBOAT.AvatarData;
}


export class TwoDHelper {
  private static twoD_Presenters: Map<string, TwoDObject> = new Map<
    string,
    TwoDObject
  >();
  private static twoD_Attendees: Map<string, TwoDObject> = new Map<
    string,
    TwoDObject
  >();

  public static OnTwoDViewUpdate: NSM.Delegate = new NSM.Delegate();
  public static OnCameraViewResize: NSM.Delegate = new NSM.Delegate();
  public static OnCameraItemResize: NSM.Delegate = new NSM.Delegate();
  public static OnHideCameraBadge: NSM.Delegate = new NSM.Delegate();
  public static OnPresentersOnlyInTwoDModeUpdate : NSM.Delegate = new NSM.Delegate();


  private static TwoDIsVisible: boolean = false;

  private static _PresentersOnlyInTwoDMode : boolean = false;

  public static async Init() {
    SHOWBOAT.LiveswitchDownstreamController.OnAddTo2DDisplay.Add(
      TwoDHelper.OnAddTo2DDisplay
    );
    SHOWBOAT.LiveswitchDownstreamController.OnRemoveFrom2DDisplay.Add(
      TwoDHelper.OnRemoveFrom2DDisplay
    );

    // SHOWBOAT.RemoteAvatarDataManager.OnPartitionChange.Add(TwoDHelper.OnPartitionChange);
    SHOWBOAT.UIEventManager.OnFullscreenPresentationToggle.Add(
      TwoDHelper.OnFullscreenPresentationToggle
    );

    SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Add(StringVariableHelper.PresentersOnlyInTwoDMode, TwoDHelper._OnPresentersOnlyInTwoDModeUpdate);
    TwoDHelper.getPresentersOnlyInTwoDMode();

  }

  //Listen for server updates
  private static _OnPresentersOnlyInTwoDModeUpdate(data : any) : void {
    TwoDHelper.PresentersOnlyInTwoDMode = (data.value as boolean);
    TwoDHelper.OnPresentersOnlyInTwoDModeUpdate.Raise(TwoDHelper.PresentersOnlyInTwoDMode);
  }

  //Get value internally
  private static get PresentersOnlyInTwoDMode() : boolean{
    return TwoDHelper._PresentersOnlyInTwoDMode;
  }

  //Set value internally
  private static set PresentersOnlyInTwoDMode(b : boolean){
    if(TwoDHelper._PresentersOnlyInTwoDMode == b) return;
    TwoDHelper._PresentersOnlyInTwoDMode = b;
    TwoDHelper.notifyObservers();
  }

  public static async getPresentersOnlyInTwoDMode () : Promise<boolean>{    
      let data : any = await SHOWBOAT.ServerVariableManager.getEventVariable(StringVariableHelper.PresentersOnlyInTwoDMode, {value: false});
      TwoDHelper.PresentersOnlyInTwoDMode = data.value;
      return TwoDHelper.PresentersOnlyInTwoDMode;    
  }

  public static async setPresentersOnlyInTwoDMode(b : boolean){    
      await SHOWBOAT.ServerVariableManager.setEventVariable(StringVariableHelper.PresentersOnlyInTwoDMode, {value: b});
      TwoDHelper.PresentersOnlyInTwoDMode = b;    
  }

  private static OnFullscreenPresentationToggle(b: boolean) {
    TwoDHelper.TwoDIsVisible = b;
  }

  private static OnAddTo2DDisplay(
    downstreamController: SHOWBOAT.LiveswitchDownstreamController
  ) {
    let userID = downstreamController.getUserID();
    let avatarData = SHOWBOAT.RemoteAvatarDataManager.getAvatarData(
      downstreamController.getUserID()
    );
    if (!avatarData) return;

    let twoDObject: TwoDObject = {
      userID,
      avatarData,
      downstreamController,
    };

    if (avatarData.partition == "presenter") {
      //Ensure not in attendees list
      TwoDHelper.twoD_Attendees.delete(userID);

      TwoDHelper.twoD_Presenters.set(userID, twoDObject);
    } else {
      TwoDHelper.twoD_Presenters.delete(userID);

      TwoDHelper.twoD_Attendees.set(userID, twoDObject);
    }

    //SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(userID, TwoDHelper.OnRemotePlayerDataUpdate );

    TwoDHelper.notifyObservers();
  }

  private static OnRemoveFrom2DDisplay(
    downstreamController: SHOWBOAT.LiveswitchDownstreamController
  ) {
    let userID = downstreamController.getUserID();
    let result =
      TwoDHelper.twoD_Attendees.delete(userID) ||
      TwoDHelper.twoD_Presenters.delete(userID);
    if (result) {
      TwoDHelper.notifyObservers();
    }
  }

  /*
    private static OnPartitionChange(avatarData : SHOWBOAT.AvatarData, position : number[], rotation : number[]){

        if(avatarData.partition == "presenter"){
            //Check if we need to move this person from attendees to presenters
            let twoDObject : TwoDObject = TwoDHelper.twoD_Attendees.get(avatarData.userID);
            if(twoDObject){
                TwoDHelper.twoD_Attendees.delete(avatarData.userID);
                TwoDHelper.twoD_Presenters.set(avatarData.userID, twoDObject);
            }
        } else {
            //Check if we need to move this person from presenters to attendees
            let twoDObject : TwoDObject = TwoDHelper.twoD_Presenters.get(avatarData.userID);
            if(twoDObject){
                TwoDHelper.twoD_Presenters.delete(avatarData.userID);
                TwoDHelper.twoD_Attendees.set(avatarData.userID, twoDObject);
            }
        }

    }

    private static OnRemotePlayerDataUpdate(changeReason : SHOWBOAT.ChangeReason, avatarData : SHOWBOAT.AvatarData){
        
        //only interested in camera events
        if(changeReason != SHOWBOAT.ChangeReason.Camera) return;

        if(TwoDHelper.twoD_Attendees.has(avatarData.userID) || TwoDHelper.twoD_Presenters.has(avatarData.userID) ){
            TwoDHelper.notifyObservers();
        }
    }
    */

  private static notifyObservers() {
    if (TwoDHelper.TwoDIsVisible) {
      TwoDHelper.OnTwoDViewUpdate.Raise(TwoDHelper.getTwoDObjects());
    }
  }

  public static getPresenters(): TwoDObject[] {
    let presenters: TwoDObject[] = Array.from(
      TwoDHelper.twoD_Presenters.values()
    );
    presenters.sort(TwoDHelper.sortByNameComparator);
    return presenters;
  }

  public static getAttendees(): TwoDObject[] {
    if(TwoDHelper._PresentersOnlyInTwoDMode) return [];
    let attendees: TwoDObject[] = Array.from(
      TwoDHelper.twoD_Attendees.values()
    );
    attendees.sort(TwoDHelper.sortByNameComparator);
    return attendees;
  }

  public static getTwoDObjects(): TwoDObject[] {
    return TwoDHelper.getPresenters().concat(TwoDHelper.getAttendees());
  }

  public static sortByVolumeThenName(a: TwoDObject, b: TwoDObject): number {
    let volumeA: number = a.downstreamController.getVolumeRequested();
    let volumeB: number = b.downstreamController.getVolumeRequested();
    if (volumeA != volumeB) {
      return volumeB > volumeA ? 1 : -1;
    }
    return TwoDHelper.sortByNameComparator(a, b);
  }

  private static sortByNameComparator(a: TwoDObject, b: TwoDObject): number {
    let aName: string =
      a.avatarData.lastName && a.avatarData.lastName.length > 0
        ? a.avatarData.lastName
        : a.avatarData.firstName;
    let bName: string =
      b.avatarData.lastName && b.avatarData.lastName.length > 0
        ? b.avatarData.lastName
        : b.avatarData.firstName;

    let lastNameCompare = aName.localeCompare(bName);
    if (lastNameCompare != 0) return lastNameCompare;
    return a.avatarData.firstName.localeCompare(b.avatarData.firstName);
  }

  public static getTwoDSquarePixelSize(x: number, y: number, n: number) {
    // Compute number of rows and columns, and cell size
    var ratio = x / y;
    var ncols_float = Math.sqrt(n * ratio);
    var nrows_float = n / ncols_float;

    // Find best option filling the whole height
    var nrows1 = Math.ceil(nrows_float);
    var ncols1 = Math.ceil(n / nrows1);
    while (nrows1 * ratio < ncols1) {
      nrows1++;
      ncols1 = Math.ceil(n / nrows1);
    }
    var cell_size1 = y / nrows1;

    // Find best option filling the whole width
    var ncols2 = Math.ceil(ncols_float);
    var nrows2 = Math.ceil(n / ncols2);
    while (ncols2 < nrows2 * ratio) {
      ncols2++;
      nrows2 = Math.ceil(n / ncols2);
    }
    var cell_size2 = x / ncols2;

    // Find the best values
    var nrows, ncols, cell_size;
    if (cell_size1 < cell_size2) {
      nrows = nrows2;
      ncols = ncols2;
      cell_size = cell_size2;
    } else {
      nrows = nrows1;
      ncols = ncols1;
      cell_size = cell_size1;
    }
    return cell_size;
  }

  
}

(window as any).TwoDHelper = TwoDHelper;