import {
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import EmojiIcon from "@material-ui/icons/EmojiEmotions";
import CheckIcon from "@material-ui/icons/Check";
import { Picker } from "emoji-mart";

import { PresenterContext, PresenterContextType } from "../../../../context/PresenterContext";
import { QASidebarStyles } from "./QASidebarStyles";
import styles from "./QASidebar.module.css";
import QASortSelect from "./QASortSelect/QASortSelect";
import { UIHelper } from "../../../../Utilities/UIHelper";
import { QAContext, QAContextType } from "../../../../context/QAContext";
import QAHeader from "./QAHeader/QAHeader";
import QASettings from "./QASettings/QASettings";
import QAItemList from "./QAItemList/QAItemList";

/* let Filter = require("bad-words"); */

interface Props {}

export default function QASidebar({}: Props): ReactElement {
  const {
    qaRole,
    allowAnonymousQs,
    attendeesSeeAllQs,
    questionIDsPosted,
    setQuestionIDsPosted,
    submitAnonymously,
    setSubmitAnonymously
  }: QAContextType = useContext(QAContext);

  const {
    jumbotronFullscreenView
  } : PresenterContextType = useContext(PresenterContext);

  const [sortStyle, setSortStyle] = useState(UIHelper.QASortEnum.NEWEST);
  const [tab, setTab] = useState(
    attendeesSeeAllQs ? UIHelper.QATabEnum.APPROVED : UIHelper.QATabEnum.PENDING
  );
  const [qaText, setQaText] = useState("");
  const [qaSubmitted, setQaSubmitted] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const qaSubmittedTimeoutRef = useRef(null);

  /* let filter = new Filter(); */

  useEffect(() => {
    if (allowAnonymousQs === false) {
      setSubmitAnonymously(false);
    }
  }, [allowAnonymousQs]);

  useEffect(() => {
    //If we set QA role to attendees or presenter, make sure tab is set to approved
    if (
      qaRole === UIHelper.QARole.ATTENDEE ||
      qaRole === UIHelper.QARole.PRESENTER
    ) {
      setTab(UIHelper.QATabEnum.APPROVED);
    }
  }, [qaRole]);

  useEffect(() => {
    if (attendeesSeeAllQs) {
      setTab(UIHelper.QATabEnum.APPROVED);
    } else {
      setTab(UIHelper.QATabEnum.PENDING);
    }
  }, [attendeesSeeAllQs]);

  const handleSortChange = (sort: string) => {
    setSortStyle(sort);
  };

  const handleSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  const handleQATextChange = (e) => {
    setQaText(e.target.value);
  };

  const handleQAMessageSubmit = async (e) => {
    e.preventDefault();

    //Don't allow submission if text field is blank
    if (qaText === "") return;

    //Refocus canvas if 2D mode is not active
    if (!jumbotronFullscreenView) {
      let aWindow: any = window;
      aWindow.babylonCanvas.focus();
    }
    
    try {
      //Submit message to server
      let questionID = await SHOWBOAT.SocketIOController.AddQuestion(
        /* filter.clean(qaText) */qaText,
        submitAnonymously
          ? "Anonymous"
          : `${SHOWBOAT.LocalAvatarDataManager.firstName} ${SHOWBOAT.LocalAvatarDataManager.lastName}`
      );

      let questionIDsClone = questionIDsPosted;
      questionIDsClone.push(questionID);
      setQuestionIDsPosted(questionIDsClone);

      setQaSubmitted(true);
      qaSubmittedTimeoutRef.current = setTimeout(function () {
        setQaSubmitted(false);
      }, 2000);

      //Reset text field
      setQaText("");
    } catch (error) {
      SHOWBOAT.Logger.Error("Error submitting question:", error);
      SHOWBOAT.UIEventManager.OnUIError.Raise("Error submitting question")
    }
  };

  const handleEmojiClick = () => {
    setShowEmojis(!showEmojis);
  };

  const handleSubmitAnonymouslyChange = () => {
    setSubmitAnonymously(!submitAnonymously);
  };

  const handleTabChange = (e, tab) => {
    setTab(tab);
  };

  const handleEmojiSelect = (e) => {
    //parse emoji code from event object
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    //append emoji code to message input
    setQaText(`${qaText} ${emoji}`);

    //hide emoji picker
    setShowEmojis(false);
  };

  const classes = QASidebarStyles();

  return (
    <React.Fragment>
      <QAHeader
        handleSettingsClick={handleSettingsClick}
        tab={tab}
        handleTabChange={handleTabChange}
        settings={showSettings}
      />

      <div
        className={
          qaRole === UIHelper.QARole.MODERATOR
            ? showSettings
              ? `${styles.qaContentHolder} ${styles.qaContentHolderModerator} ${styles.qaContentHolderModeratorSettings}`
              : `${styles.qaContentHolder} ${styles.qaContentHolderModerator}`
            : SHOWBOAT.LocalAvatarDataManager.role === "presenter"
            ? styles.qaContentHolder
            : `${styles.qaContentHolder} ${styles.qaContentHolderAttendee}`
        }
        id="qaContentHolder"
      >
        {showSettings ? (
          <QASettings />
        ) : (
          <React.Fragment>
            <QASortSelect
              sort={sortStyle}
              handleSortChange={handleSortChange}
            />

            <QAItemList tab={tab} sort={sortStyle} role={qaRole} />

            {showEmojis && (
              <div className={styles.emojiSelectHolder}>
                <Picker
                  theme={SHOWBOAT.ApplicationSkin.theme}
                  onSelect={(e) => handleEmojiSelect(e)}
                  style={{ width: "100%" }}
                />
              </div>
            )}

            {qaRole === UIHelper.QARole.ATTENDEE && (
              <Paper className={classes.qaMessageInputHolder}>
                <TextField
                  multiline
                  rows={3}
                  value={qaText}
                  variant="outlined"
                  onChange={handleQATextChange}
                  className={classes.qaTextField}
                  placeholder="Post A Question"
                  inputProps={{
                    maxLength: 240,
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleQAMessageSubmit(e);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.emojiIcon}
                      >
                        <EmojiIcon onClick={handleEmojiClick} />
                      </InputAdornment>
                    ),
                    classes: {
                      notchedOutline: classes.noBorder,
                      root: classes.qaInput,
                      inputAdornedEnd: classes.qaInputAdornedEnd,
                      input: classes.qaInputInput,
                    },
                    type: "text",
                  }}
                />

                <div className={styles.textFieldHelpersHolder}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={submitAnonymously}
                        onChange={handleSubmitAnonymouslyChange}
                        name="submit anonymously"
                        color="primary"
                      />
                    }
                    disabled={!allowAnonymousQs}
                    classes={{
                      root: classes.anonymousCheckbox,
                      label: classes.anonymousLabel,
                    }}
                    label="Submit Anonymously"
                    color="primary"
                    style={{
                      visibility: allowAnonymousQs ? "visible" : "hidden",
                    }}
                  />

                  <Typography
                    variant="body1"
                    className={classes.characterCount}
                  >
                    {`${qaText.length} / 240`}
                  </Typography>
                </div>

                <Button
                  className={
                    qaSubmitted
                      ? `${classes.submitButton} ${classes.submitButtonSubmitted}`
                      : classes.submitButton
                  }
                  onClick={handleQAMessageSubmit}
                  variant="contained"
                >
                  {qaSubmitted ? <CheckIcon /> : "SUBMIT"}
                </Button>

                <Typography variant="body1" className={classes.qaHelperText}>
                  {attendeesSeeAllQs
                    ? "This message will be seen by everyone"
                    : "This message will be sent to the moderator"}
                </Typography>
              </Paper>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
