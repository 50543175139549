import { StringVariableHelper } from "./StringVariableHelper";
import { isMobileOnly } from "react-device-detect";

const axios = require("axios");

export class ServerHelper {
  public static useMobileApp: boolean = isMobileOnly;
  public static useMobileHybridApp: boolean = false;

  public static enableMobile: boolean = false;
  
  public static useAudioManager: boolean = false;
  public static useAudioManagerLoopback: boolean = true;

  public static attendeesID: string;

  //Dev option for UI Development
  public static useUIDevelopment: boolean = false;

  public static OnServerConnectionTimeout: NSM.Delegate = new NSM.Delegate();
  public static loginCode: string;

  public static loginFailReason: string;

  public static loginResult: SHOWBOAT.LoginResult;

  public static deadLoginCode: boolean = false;
  public static errorMsg: string;

  public static enableQA: boolean = false;

  public static attendeesIDToken: string;

  public static allowEarlyAccess: boolean = false;
  public static accessTime: string;

  public static hangUpURL: string;

  public static bookingName: string;

  public static SocketIOPort: number;

  public static appAPIUrl =
    process.env.REACT_APP_API_STAGE === "dev" ||
    window.location.hostname.includes("showboatui")
      ? "https://appservice.showboat.live/appdev/"
      : "https://appservice.showboat.live/appprod/";

  public static appAPIUrlAlias =
    process.env.REACT_APP_API_STAGE === "dev" ||
    window.location.hostname.includes("showboatui")
      ? "https://f96l7jj519.execute-api.us-east-1.amazonaws.com/dev"
      : "https://f96l7jj519.execute-api.us-east-1.amazonaws.com/prod";

  private static aliasUrlUsed: boolean = false;

  public static awsLoggingUrl = `wss://3znq6eov5c.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_API_STAGE}`;
  public static awsLoggingUrlAlias = `wss://log.showboat.live/${process.env.REACT_APP_API_STAGE}`;

  private static SocketIOServer: string;
  private static useSecureWebsockets = true;

  public static VideoServer: string;
  public static VideoServerAppID: string;

  private static connectionTimeout: NodeJS.Timeout;
  private static connectionTimeoutMilliseconds: number = 20000;

  private static connectionPromiseResolver: (boolean) => void;

  public static enableSupport: boolean = false;
  public static supportMessage: string = "";

  public static enableCalendar: boolean = false;

  public static enableDoors: boolean = false;
  public static doorsOpen: string;
  public static doorsClosed: string;

  public static missingIntakeDataOnLogin: boolean = false;

  public static loginTimestamp: string;
  public static loginTime: Date;

  public static userPermissions = {};

  private static GetQueryParam = (paramName: string) => {
    paramName = paramName.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + paramName + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results !== null
      ? decodeURIComponent(results[1].replace(/\+/g, " "))
      : null;
  };

  public static bypass: boolean =
    ServerHelper.GetQueryParam("bypass") === "true" ? true : false;

  public static async Login(): Promise<SHOWBOAT.LoginResult> {
    //Listen for disconnects
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(
      ServerHelper.OnRemotePlayerDisconnected
    );

    //Listen for forceMutes from presenters in debug mode
    SHOWBOAT.SocketIOController.OnPrivateMessage.Add(
      "ForceAudioMute",
      ServerHelper.onForceMuteMic
    );
    SHOWBOAT.SocketIOController.OnPrivateMessage.Add(
      "UnforceAudioMute",
      ServerHelper.onUnforceMuteMic
    );
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
      SHOWBOAT.ChangeReason.ForceMute,
      ServerHelper.onForceMutePlayerDataUpdate
    );

    //UI Event Manager Helpers
    SHOWBOAT.UIEventManager.OnModeChange.Add(ServerHelper.OnModeChange);
    SHOWBOAT.UIEventManager.On3DAvatarLoadComplete.Add(
      ServerHelper.On3DAvatarLoadComplete
    );

    //Listen for AudioDistance changes
    SHOWBOAT.SocketIOController.OnEventVariableUpdate.Add(
      StringVariableHelper.AudioDistanceEventName,
      ServerHelper.handleAudioDistanceChange
    );

    /* SHOWBOAT.UIEventManager.OnFullscreenPresentationToggle.Add(ServerHelper.OnFullscreenPresentationToggle); */

    //Setup axios to call the API
    axios.defaults.baseURL = ServerHelper.appAPIUrl;

    //Make login call
    ServerHelper.loginCode = ServerHelper.getLoginCode();

    if (ServerHelper.loginCode.length === 0)
      return ServerHelper.getErrorLoginResult("Missing Login Code");

    if (
      StringVariableHelper.LoginFailReasons.SystemCheckLoginCodes.indexOf(
        ServerHelper.loginCode.substring(0, 11)
      ) >= 0
    )
      return ServerHelper.getSystemCheckLoginResult();

    //Set up parameters for login call
    const params = {
      loginCode: ServerHelper.loginCode,
      timestamp: new Date().getTime(),
    };

    try {
      //Execute login call
      const loginResponse = await ServerHelper.attemptLogin(
        ServerHelper.appAPIUrl,
        params
      );

      if (loginResponse) {
        SHOWBOAT.Logger.Server("Successful connection made to default API");

        ServerHelper.handleLoginSuccess(loginResponse);

        return loginResponse.data;
      } else {
        //Retry with alias URL (f96l7jj519 variant)
        SHOWBOAT.Logger.Server(
          "Error connecting to registration server, attempting login with alias URL."
        );

        const aliasLoginResponse = await ServerHelper.attemptLogin(
          ServerHelper.appAPIUrlAlias,
          params
        );

        if (aliasLoginResponse) {
          ServerHelper.aliasUrlUsed = true;

          SHOWBOAT.Logger.Server("Successful connection made to alias API");

          ServerHelper.handleLoginSuccess(aliasLoginResponse);

          return aliasLoginResponse.data;
        } else {
          //Allow mobile users to view the error page
          if (ServerHelper.useMobileApp) {
            ServerHelper.useMobileHybridApp = true;
          }

          return ServerHelper.getErrorLoginResult("Login failed");
        }
      }
    } catch (err) {
      try {
        SHOWBOAT.Logger.Server(
          "Error connecting to registration server, attempting login with alias URL."
        );

        //Try alias URL to login (f96l7jj519 variant)
        const aliasLoginResponse = await ServerHelper.attemptLogin(
          ServerHelper.appAPIUrlAlias,
          params
        );

        if (aliasLoginResponse) {
          ServerHelper.aliasUrlUsed = true;

          ServerHelper.handleLoginSuccess(aliasLoginResponse);

          SHOWBOAT.Logger.Server("Successful connection made to alias API");

          return aliasLoginResponse.data;
        } else {
          if (ServerHelper.useMobileApp) {
            ServerHelper.useMobileHybridApp = true;
          }

          return ServerHelper.getErrorLoginResult("Login failed");
        }
      } catch (err) {
        if (ServerHelper.useMobileApp) {
          ServerHelper.useMobileHybridApp = true;
        }

        return ServerHelper.getErrorLoginResult("Login failed");
      }
    }
  }

  private static attemptLogin = async (
    baseURL,
    params
  ): Promise<boolean | any> => {
    try {
      //set base URL on axios
      axios.defaults.baseURL = baseURL;

      const loginApiResponse = await axios.get("login", { params });

      if (loginApiResponse && loginApiResponse.data) {
        return loginApiResponse;
      } else {
        return false;
      }
    } catch (err) {
      SHOWBOAT.Logger.Error(err);
      return false;
    }
  };

  private static handleLoginSuccess = (loginApiResponse) => {
    SHOWBOAT.Logger.Debug("LOGIN SUCCESS", loginApiResponse);

    if (loginApiResponse.data.success) {
      //Add listener for admin kick
      SHOWBOAT.SocketIOController.OnLocalPlayerKicked.Add(
        ServerHelper.handleAdminKick
      );

      let loginResult = loginApiResponse.data as SHOWBOAT.LoginResult;
      ServerHelper.loginResult = loginResult;
      ServerHelper.useMobileHybridApp = loginResult.isMobileHybridLogin
        ? loginResult.isMobileHybridLogin
        : false;
      ServerHelper.storeLoginResultData(loginResult);
      ServerHelper.loginFailReason = loginResult.failReason;
      return loginResult;
    } else if (
      !loginApiResponse.data.success &&
      loginApiResponse.data.failReason ===
        StringVariableHelper.LoginFailReasons.DeletedLoginCode
    ) {
      let loginResult = loginApiResponse.data as SHOWBOAT.LoginResult;
      ServerHelper.storeLoginResultData(loginResult);
      ServerHelper.loginResult = loginResult;
      ServerHelper.useMobileHybridApp = loginResult.isMobileHybridLogin
        ? loginResult.isMobileHybridLogin
        : false;
      ServerHelper.loginFailReason = loginResult.failReason;
      ServerHelper.errorMsg = loginResult.errorMsg;
      return loginResult;
    } else if (
      !loginApiResponse.data.success &&
      (loginApiResponse.data.failReason ===
        StringVariableHelper.LoginFailReasons.NotStarted ||
        loginApiResponse.data.failReason ===
          StringVariableHelper.LoginFailReasons.Ended ||
        loginApiResponse.data.failReason ===
          StringVariableHelper.LoginFailReasons.CapacityFull)
    ) {
      let loginResult = loginApiResponse.data as SHOWBOAT.LoginResult;
      ServerHelper.storeLoginResultData(loginResult);
      ServerHelper.loginResult = loginResult;
      ServerHelper.deadLoginCode = false;
      ServerHelper.loginFailReason = loginResult.failReason;
      ServerHelper.useMobileHybridApp = loginResult.isMobileHybridLogin
        ? loginResult.isMobileHybridLogin
        : false;
      return loginResult;
    } else {
      return ServerHelper.getErrorLoginResult("Login unsuccessful");
    }
  };

  public static getLoginCode(): string {
    //Get the code the person is attempting to login in with
    let loginCode = ServerHelper.GetQueryParam("login");

    //Check if we got a login code. If not, try to parse it from the URL
    if (!loginCode || loginCode.length === 0 || loginCode === null) {
      //loginCode = window.location.pathname.split("/").pop();
      loginCode = window.location.pathname;

      let searchResult: number = loginCode.search(/^\/[a-zA-Z0-9]*$/);

      if (
        searchResult < 0 ||
        !loginCode ||
        loginCode.length === 0 ||
        loginCode === null
      ) {
        loginCode = "devTest";
      } else {
        loginCode = loginCode.substring(1);
      }
    }

    return loginCode;
  }

  private static getSystemCheckLoginResult = () => {
    let loginResult: SHOWBOAT.LoginResult = {
      success: false,
      allowEarlyAccess: false,
      failReason: StringVariableHelper.LoginFailReasons.SystemCheck,
      role: "",
      registrationData: null,
      bookingName: "",
      startTime: "",
      endTime: "",
      accessTime: "",
      doorsOpen: "",
      doorsClosed: "",
      enableDoors: false,
      uiSkin: {},
      avatarSkin: {},
      worldSkin: {},
      avatar: {},
      world: {},
      userData: {},
      eventID: "",
      gameServer: "",
      gamePort: 0,
      videoServer: "",
      videoServerAppID: "",
      enableSupport: false,
      supportMessage: "",
      hangUpURL: "",
      enableQA: false,
      enableMobile: false,
    };

    //Set "SystemCheck" fail reason
    ServerHelper.loginFailReason =
      StringVariableHelper.LoginFailReasons.SystemCheck;

    return loginResult;
  };

  private static getErrorLoginResult(failReason: string): SHOWBOAT.LoginResult {
    let loginResult: SHOWBOAT.LoginResult = {
      success: false,
      allowEarlyAccess: false,
      failReason: failReason,
      role: "",
      registrationData: null,
      bookingName: "",
      startTime: "",
      endTime: "",
      accessTime: "",
      doorsOpen: "",
      doorsClosed: "",
      enableDoors: false,
      uiSkin: {},
      avatarSkin: {},
      worldSkin: {},
      avatar: {},
      world: {},
      userData: {},
      eventID: "",
      gameServer: "",
      gamePort: 0,
      videoServer: "",
      videoServerAppID: "",
      enableSupport: false,
      supportMessage: "",
      /*  applicationSkin : null */ hangUpURL: "",
      enableQA: false,
      enableMobile: true,
    };
    return loginResult;
  }

  private static storeLoginResultData(loginResult: SHOWBOAT.LoginResult) {
    if (
      loginResult.success ||
      //also set skinnable properties for error states where we need them
      loginResult.failReason ===
        StringVariableHelper.LoginFailReasons.NotStarted ||
      loginResult.failReason === StringVariableHelper.LoginFailReasons.Ended ||
      loginResult.failReason ===
        StringVariableHelper.LoginFailReasons.CapacityFull ||
      loginResult.failReason ===
        StringVariableHelper.LoginFailReasons.DeletedLoginCode
    ) {
      //Socket information
      ServerHelper.SocketIOServer = loginResult.gameServer;

      ServerHelper.SocketIOPort = loginResult.gamePort;

      //Liveswitch server to connect to
      ServerHelper.VideoServer = loginResult.videoServer;

      if (loginResult.videoServerAppID) {
        ServerHelper.VideoServerAppID = loginResult.videoServerAppID;
      } else {
        ServerHelper.VideoServerAppID = SHOWBOAT.ApplicationSkin.applicationID;
      }

      /* USER DATA */
      SHOWBOAT.LocalAvatarDataManager.partition =
        StringVariableHelper.ShowboatPartitions.attendees;
      SHOWBOAT.LocalAvatarDataManager.role = loginResult.role;

      //Assign default face
      SHOWBOAT.LocalAvatarDataManager.face = 0;
      SHOWBOAT.UIEventManager.OnAvatarFaceChanged.Raise(0);

      //Assign random color 1-8
      /*   let colorIndex = ServerHelper.getRandomColor();
            console.log("ASSIGNING COLOR", colorIndex);
            SHOWBOAT.LocalAvatarDataManager.color = colorIndex;
            SHOWBOAT.UIEventManager.OnAvatarColorChanged.Raise(colorIndex); */

      /* EVENT DATA */
      if (loginResult.bookingName) {
        SHOWBOAT.ApplicationSkin.eventName = loginResult.bookingName;
      }

      /* EARLY ACCESS DATA*/
      if (loginResult.allowEarlyAccess) {
        ServerHelper.allowEarlyAccess = loginResult.allowEarlyAccess;
      }
      if (loginResult.accessTime) {
        ServerHelper.accessTime = loginResult.accessTime;
      }
      /* DOORS OPEN/CLOSED DATA */
      if (loginResult.enableDoors) {
        ServerHelper.enableDoors = loginResult.enableDoors;
      }
      if (loginResult.doorsOpen) {
        ServerHelper.doorsOpen = loginResult.doorsOpen;
      }
      if (loginResult.doorsClosed) {
        ServerHelper.doorsClosed = loginResult.doorsClosed;
      }

      if (loginResult.enableQA) {
        ServerHelper.enableQA = loginResult.enableQA;
      }

      /* UI Skin */
      if (loginResult.uiSkin && loginResult.uiSkin.theme) {
        SHOWBOAT.ApplicationSkin.theme = loginResult.uiSkin.theme;
      }

      if (loginResult.uiSkin && loginResult.uiSkin.landingPageAssetURL) {
        SHOWBOAT.ApplicationSkin.landingPageGraphicURL =
          loginResult.uiSkin.landingPageAssetURL;
      }

      if (loginResult.uiSkin && loginResult.uiSkin.landingPageGraphicType) {
        SHOWBOAT.ApplicationSkin.landingPageGraphicType =
          loginResult.uiSkin.landingPageGraphicType;
      }

      if (loginResult.uiSkin && loginResult.uiSkin.thumbnailLogoURL) {
        SHOWBOAT.ApplicationSkin.landingPageThumbnailURL =
          loginResult.uiSkin.thumbnailLogoURL;
      }

      if (loginResult.uiSkin && loginResult.uiSkin.intakeFields) {
        SHOWBOAT.ApplicationSkin.intakeFields = loginResult.uiSkin.intakeFields;
      }

      if (loginResult.uiSkin && loginResult.uiSkin.primaryThemeColor) {
        SHOWBOAT.ApplicationSkin.primaryThemeColor =
          loginResult.uiSkin.primaryThemeColor;
      }

      /* Avatar Skin */
      if (loginResult.avatarSkin) {
        //Colors
        if (
          loginResult.avatarSkin.avatarColors &&
          loginResult.avatarSkin.avatarColors.type == "ColorPairList"
        ) {
          let primaryColors: string[] = [];
          let secondaryColors: string[] = [];
          for (
            let i = 0;
            i < loginResult.avatarSkin.avatarColors.value.length;
            ++i
          ) {
            let colorRow: any = loginResult.avatarSkin.avatarColors.value[i];
            primaryColors.push(colorRow.primary);
            secondaryColors.push(colorRow.secondary);
          }
          if (
            primaryColors.length > 0 &&
            primaryColors.length == secondaryColors.length
          ) {
            SHOWBOAT.ApplicationSkin.primaryAvatarColors = primaryColors;
            SHOWBOAT.ApplicationSkin.secondaryAvatarColors = secondaryColors;
          }
        }

        //Thumbnails
        if (
          loginResult.avatarSkin.thumbnails &&
          loginResult.avatarSkin.thumbnails.value
        ) {
          SHOWBOAT.ApplicationSkin.faceThumbnailURLs =
            loginResult.avatarSkin.thumbnails.value;
        }

        //TextureMap
        if (
          loginResult.avatarSkin.textureMap &&
          loginResult.avatarSkin.textureMap.value
        ) {
          SHOWBOAT.ApplicationSkin.avatarTextureMap =
            loginResult.avatarSkin.textureMap.value;
        }
      }

      /* WORLD SKIN */
      if (loginResult.worldSkin) {
        SHOWBOAT.ApplicationSkin.worldSkin = loginResult.worldSkin;
      }

      /* WORLD */
      if (loginResult.world) {
        if (loginResult.world.core) {
          if (loginResult.world.core.showboatCore) {
            SHOWBOAT.ApplicationSkin.showboatCore =
              loginResult.world.core.showboatCore;
          }

          if (loginResult.world.core.showboatCoreConfig) {
            SHOWBOAT.ApplicationSkin.showboatCoreConfig =
              loginResult.world.core.showboatCoreConfig;
          }

          if (loginResult.world.core.intakeScene) {
            SHOWBOAT.ApplicationSkin.intakeScene =
              loginResult.world.core.intakeScene;
          }

          if (loginResult.world.core.intakeSceneConfig) {
            SHOWBOAT.ApplicationSkin.intakeSceneConfig =
              loginResult.world.core.intakeSceneConfig;
          }

          if (loginResult.world.core.mainScene) {
            SHOWBOAT.ApplicationSkin.mainScene =
              loginResult.world.core.mainScene;
          }

          if (loginResult.world.core.mainSceneConfig) {
            SHOWBOAT.ApplicationSkin.mainSceneConfig =
              loginResult.world.core.mainSceneConfig;
          }

          if (loginResult.world.core.sceneSupplement) {
            SHOWBOAT.ApplicationSkin.sceneSupplement =
              loginResult.world.core.sceneSupplement;
          }
        } else {
          SHOWBOAT.Logger.Fatal(
            "ServerHelper.storeLoginResultData",
            "Missing Core declarations. Showboat playground is no longer supported."
          );

          //ServerHelper.useLegacy = true;

          /*MODEL FILES*/
          /*
                    if (loginResult.world.modelFile) {
                        SHOWBOAT.ApplicationSkin.spaceModelURL = loginResult.world.modelFile;
                    }

                    if (loginResult.world.sourceFile) {
                        SHOWBOAT.ApplicationSkin.babylonToolkitBundlePath = loginResult.world.sourceFile;
                    }
                    */
        }

        if (loginResult.world.roomCapacity) {
          SHOWBOAT.ApplicationSkin.roomCapacity =
            loginResult.world.roomCapacity;
        }
      }

      /*
            if(loginResult.world && loginResult.world.sourceFile && loginResult.world.sourceFile[0]){
                SHOWBOAT.ApplicationSkin.babylonToolkitBundlePath = loginResult.world.sourceFile[0];
            }
            */

      if (loginResult.avatar && loginResult.avatar.modelFile) {
        SHOWBOAT.ApplicationSkin.avatarModelURL = loginResult.avatar.modelFile;
      }

      if (loginResult.startTime) {
        let startDateTime = new Date(loginResult.startTime);

        const day =
          startDateTime.getDate() +
          (startDateTime.getDate() % 10 == 1 && startDateTime.getDate() != 11
            ? "st"
            : startDateTime.getDate() % 10 == 2 && startDateTime.getDate() != 12
            ? "nd"
            : startDateTime.getDate() % 10 == 3 && startDateTime.getDate() != 13
            ? "rd"
            : "th");
        const month = startDateTime.toLocaleString("default", {
          month: "long",
        });
        const year = startDateTime.toLocaleString("default", {
          year: "numeric",
        });
        const time = startDateTime.toLocaleString("default", {
          hour: "numeric",
          minute: "numeric",
        });

        const dateString = `${month} ${day}, ${year} - ${time}`;
        SHOWBOAT.ApplicationSkin.eventTime = dateString;

        SHOWBOAT.ApplicationSkin.startDateTime = loginResult.startTime;
      }

      if (loginResult.timestamp) {
        ServerHelper.loginTimestamp = loginResult.timestamp;
        ServerHelper.loginTime = new Date(loginResult.timestamp);
      }

      if (loginResult.registrationData) {
        /*** INTAKE DATA  ****/
        SHOWBOAT.LocalAvatarDataManager.registrationData =
          loginResult.registrationData;

        /*** Local Avatar Data ***/

        if (loginResult.registrationData.firstName !== undefined) {
          SHOWBOAT.LocalAvatarDataManager.firstName =
            loginResult.registrationData.firstName;
        }
        if (loginResult.registrationData.lastName !== undefined) {
          SHOWBOAT.LocalAvatarDataManager.lastName =
            loginResult.registrationData.lastName;
        }
        if (loginResult.registrationData.company !== undefined) {
          SHOWBOAT.LocalAvatarDataManager.company =
            loginResult.registrationData.company;
        }

        if (Object.keys(loginResult.registrationData).length === 0) {
          //Mark that there is no registration data on the login code
          ServerHelper.missingIntakeDataOnLogin = true;
        }
      }

      if (loginResult.hangUpURL) {
        ServerHelper.hangUpURL = loginResult.hangUpURL;
      }

      if (loginResult.eventID) {
        SHOWBOAT.LocalAvatarDataManager.eventID = loginResult.eventID;
      }

      if (loginResult.role === StringVariableHelper.ShowboatRoles.presenter) {
      }

      if (loginResult.enableSupport) {
        ServerHelper.enableSupport = loginResult.enableSupport;
      }

      if (loginResult.supportMessage) {
        ServerHelper.supportMessage = loginResult.supportMessage;
      }

      if ((loginResult as any).permissions) {
        ServerHelper.userPermissions = (loginResult as any).permissions;
      }

      if (loginResult.enableMobile != undefined) {
        ServerHelper.enableMobile = loginResult.enableMobile;
      }
      
    }
  }

  public static Connect = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      ServerHelper.connectionPromiseResolver = resolve;

      //Sign up to listen for connection event
      SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Add(
        ServerHelper.OnLocalPlayerConnected
      );
      SHOWBOAT.SocketIOController.OnLocalPlayerDisconnected.Add(
        ServerHelper.OnLocalPlayerDisconnected
      );
      SHOWBOAT.SocketIOController.OnLocalPlayerReconnected.Add(
        ServerHelper.OnLocalPlayerReconnected
      );

      //Make the connection with the socket
      SHOWBOAT.SocketIOController.OnConnectionTimeout.Add(
        ServerHelper.OnConnectionTimeout
      );

      //Set a timeout to watch this operation is not taking too long
      ServerHelper.connectionTimeout = setTimeout(
        ServerHelper.OnConnectionTimeout,
        ServerHelper.connectionTimeoutMilliseconds
      );

      //Connect the socket
      SHOWBOAT.SocketIOController.Connect(
        ServerHelper.SocketIOServer,
        ServerHelper.SocketIOPort,
        ServerHelper.useSecureWebsockets
      );

      //Set frame rate
      if (
        (SHOWBOAT.SocketIOController as any).SetWorkerTransformUpdateInterval
      ) {
        (SHOWBOAT.SocketIOController as any).SetWorkerTransformUpdateInterval(
          SHOWBOAT.RemotePlayersZoneConfig.transformUpdateFrequency
        );
        setInterval(ServerHelper.updateSocketRequestPlayerPositionRate, 5000);
      }
    });
  };

  private static updateSocketRequestPlayerPositionRate(): void {
    (SHOWBOAT.SocketIOController as any).SetWorkerTransformUpdateInterval(
      SHOWBOAT.RemotePlayersZoneConfig.transformUpdateFrequency
    );
  }

  private static OnLocalPlayerConnected = () => {
    SHOWBOAT.Logger.Server(
      "ServerHelper.OnLocalPlayerConnected",
      "Local player connected!"
    );

    //Clear any existing timeout
    clearTimeout(ServerHelper.connectionTimeout);

    //Remove ourself from listening for socket IO timeout event
    SHOWBOAT.SocketIOController.OnConnectionTimeout.Remove(
      ServerHelper.OnConnectionTimeout
    );

    //Remove connection listener
    SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Remove(
      ServerHelper.OnLocalPlayerConnected
    );

    //make sure no conenction errors are showing
    SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Raise(false);

    //Notify the promise that we are complete
    ServerHelper.connectionPromiseResolver(true);
  };

  private static OnLocalPlayerDisconnected = () => {
    SHOWBOAT.Logger.Error(
      "ServerHelper.OnLocalPlayerDisconnected",
      "Local player disconnected!"
    );
    SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Raise(true);
  };

  private static OnLocalPlayerReconnected = (
    avatarDatas: SHOWBOAT.AvatarData[]
  ) => {
    SHOWBOAT.Logger.Server(
      "ServerHelper.OnLocalPlayerReconnected",
      "Local player reconnected!"
    );
    SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Raise(false);

    ServerHelper.handleReconnect();
  };

  private static async handleReconnect() {
    try {
      let currentAvatarData: SHOWBOAT.AvatarData[] =
        await SHOWBOAT.SocketIOController.RequestAllPlayerData();

      //Check for players that have joined
      let currentAvatarDataMap: Map<string, SHOWBOAT.AvatarData> = new Map<
        string,
        SHOWBOAT.AvatarData
      >();
      for (let i = 0; i < currentAvatarData.length; ++i) {
        let avatarData = currentAvatarData[i];
        currentAvatarDataMap.set(avatarData.userID, avatarData);

        //Check if we know about this avatar
        let storedAvatarData = SHOWBOAT.RemoteAvatarDataManager.getAvatarData(
          avatarData.userID
        );
        if (storedAvatarData) {
          //We already know about this person

          //Update the map in case avatarData changed
          SHOWBOAT.RemoteAvatarDataManager.updateAvatarData(avatarData);

          //Check for partition change
          if (storedAvatarData.partition != avatarData.partition) {
            SHOWBOAT.RemoteAvatarDataManager.OnPartitionChange.Raise(
              avatarData
            );
          }

          //Check for a room change
          if (storedAvatarData.roomID != avatarData.roomID) {
            SHOWBOAT.SocketIOController.OnRemotePlayerRoomChange.Raise(
              storedAvatarData.roomID,
              avatarData
            );
          }

          //Check for change to camera and mic
          if (
            storedAvatarData.cameraEnabled != avatarData.cameraEnabled &&
            storedAvatarData.micEnabled != avatarData.micEnabled
          ) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.CameraAndMic,
              avatarData
            );
          } else if (
            storedAvatarData.cameraEnabled != avatarData.cameraEnabled
          ) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.Camera,
              avatarData
            );
          } else if (storedAvatarData.micEnabled != avatarData.micEnabled) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.Mic,
              avatarData
            );
          }

          //Check laser
          if (storedAvatarData.laserEnabled != avatarData.laserEnabled) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.LaserEnabled,
              avatarData
            );
          }

          //Force mute
          if (storedAvatarData.isForceMuted != avatarData.isForceMuted) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.ForceMute,
              avatarData
            );
          }

          //Load complete
          if (storedAvatarData.loadComplete != avatarData.loadComplete) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.LoadComplete,
              avatarData
            );
          }

          //Check face
          if (storedAvatarData.face != avatarData.face) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.FaceNumber,
              avatarData
            );
          }

          //Check color
          if (storedAvatarData.color != avatarData.color) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.ColorNumber,
              avatarData
            );
          }

          //Check teleport available
          if (
            storedAvatarData.isAvailableForTeleport !=
            avatarData.isAvailableForTeleport
          ) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.TeleportAvailability,
              avatarData
            );
          }

          //Check name tag
          if (
            storedAvatarData.firstName != avatarData.firstName ||
            storedAvatarData.lastName != avatarData.lastName ||
            storedAvatarData.company != avatarData.company
          ) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.NameTag,
              avatarData
            );
          }

          //Device Debug
          if (
            storedAvatarData.currentMicName != avatarData.currentMicName ||
            storedAvatarData.currentCameraName !=
              avatarData.currentCameraName ||
            storedAvatarData.currentSpeakerName !=
              avatarData.currentSpeakerName ||
            storedAvatarData.micDeviceList != avatarData.micDeviceList ||
            storedAvatarData.cameraDeviceList != avatarData.cameraDeviceList ||
            storedAvatarData.speakerDeviceList != avatarData.speakerDeviceList
          ) {
            SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Raise(
              SHOWBOAT.ChangeReason.DeviceDebug,
              avatarData
            );
          }
        } else {
          //We don't know about this person
          SHOWBOAT.SocketIOController.OnRemotePlayerConnected.Raise(avatarData);
        }
      }

      //Check for any avatars that have left
      let ourAvatarList = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEvent(
        SHOWBOAT.LocalAvatarDataManager.userID
      );

      for (let i = 0; i < ourAvatarList.length; ++i) {
        //Check if this player still existed upon reconnect
        if (!currentAvatarDataMap.has(ourAvatarList[i].userID)) {
          SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Remove(
            ServerHelper.OnRemotePlayerDisconnected
          ); //Temporaily remove self as a responder to this to avoid refetching this list
          
          SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnectedHandler(ourAvatarList[i].userID);
          /*SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Raise(
            ourAvatarList[i]
          );*/
          SHOWBOAT.UIEventManager.OnLeftNearbyList.Raise(ourAvatarList[i].userID);
          SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(
            ServerHelper.OnRemotePlayerDisconnected
          ); //Add self back as a responder
        }
      }

      //Check on any variable changes
      SHOWBOAT.ServerVariableManager.resyncAllVariables(); //no need to await here
    } catch (err) {
      SHOWBOAT.Logger.Error(
        "Error retrieving avatar data after socket reconnection."
      );
      SHOWBOAT.Logger.Error(err);
    }
  }

  private static OnConnectionTimeout = () => {
    //Clear any existing timeout
    clearTimeout(ServerHelper.connectionTimeout);

    //Remove ourself from listening for socket IO timeout event
    SHOWBOAT.SocketIOController.OnConnectionTimeout.Remove(
      ServerHelper.OnConnectionTimeout
    );

    //Raise the timeout event
    ServerHelper.OnServerConnectionTimeout.Raise();

    ServerHelper.connectionPromiseResolver(false);
  };

  public static OnModeChange(mode: SHOWBOAT.ShowboatChannelType): void {
    ServerHelper.doPartitionChange(mode);
  }

  public static async doPartitionChange(
    mode: SHOWBOAT.ShowboatChannelType
  ): Promise<boolean> {
    let modeString = "";
    if (mode === SHOWBOAT.ShowboatChannelType.Attendees) {
      modeString = StringVariableHelper.ShowboatPartitions.attendees;
    } else if (mode === SHOWBOAT.ShowboatChannelType.Presenter) {
      modeString = StringVariableHelper.ShowboatPartitions.presenter;
    } else if (mode === SHOWBOAT.ShowboatChannelType.Backstage) {
      modeString = StringVariableHelper.ShowboatPartitions.backstage;
    } else {
      return false;
    }

    //Check if we need a partition change
    if (modeString == SHOWBOAT.LocalAvatarDataManager.partition) {
      return true;
    }

    try {
      //Ask socket IO to change partitions
      await SHOWBOAT.SocketIOController.ChangePartitions(modeString);

      //update my feed
      SHOWBOAT.LiveswitchUpstreamController.changeMode(
        mode,
        SHOWBOAT.LocalAvatarDataManager.eventID,
        SHOWBOAT.LocalAvatarDataManager.roomID
      );

      //update my partition
      SHOWBOAT.LocalAvatarDataManager.partition = modeString;

      //raise event
      SHOWBOAT.UIEventManager.OnPartionChange.Raise(modeString);

      return true;
    } catch (err) {
      SHOWBOAT.Logger.Error("Error changing partitions");
      SHOWBOAT.Logger.Error(err);
      SHOWBOAT.UIEventManager.OnPartionChangeFailure.Raise();
      return false;
    }
  }

  public static OnRemotePlayerDisconnected(
    avatarData: SHOWBOAT.AvatarData
  ): void {
    //SHOWBOAT.UIEventManager.OnLeftNearbyList.Raise(avatarData.userID);
    //setTimeout(() => {
    //    ServerHelper.handleReconnect();     //TEMPORARY HACK TO TRY AND RECHECK FOR THE AVATAR LIST IF A ROGUE DISCONNECT HAPPENED WHILE SOMEONE IS HAVING INTERNET ISSUES
    //}, 1000);
  }

  public static async ManageDeviceDebugInfo(): Promise<boolean> {
    SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Add(
      ServerHelper.OnCameraDeviceChangedDeviceDebug
    );
    SHOWBOAT.StreamingUserMedia.OnMicrophoneDeviceChanged.Add(
      ServerHelper.OnMicrophoneDeviceChangedDeviceDebug
    );
    SHOWBOAT.StreamingUserMedia.OnSpeakerDeviceChanged.Add(
      ServerHelper.OnSpeakerDeviceChangedDeviceDebug
    );
    SHOWBOAT.StreamingUserMedia.OnCameraStarted.Add(
      ServerHelper.OnCameraStartedDeviceDebug
    );
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Add(
      ServerHelper.OnMicrophoneStartedDeviceDebug
    );

    let currentCameraName: string = ServerHelper.getCurrentCameraName();
    if (currentCameraName) {
      SHOWBOAT.LocalAvatarDataManager.currentCameraName = currentCameraName;
    }

    let currentMicrophoneName: string = ServerHelper.getCurrentMicrophoneName();
    if (currentMicrophoneName) {
      SHOWBOAT.LocalAvatarDataManager.currentMicName = currentMicrophoneName;
    }

    let currentSpeakerName: string = ServerHelper.getCurrentSpeakerName();
    if (currentSpeakerName) {
      SHOWBOAT.LocalAvatarDataManager.currentSpeakerName = currentSpeakerName;
    }

    await ServerHelper.UpdateDeviceDebugLists(true);
    return true;
  }

  public static OnCameraDeviceChangedDeviceDebug(deviceID: string): void {
    let cameraName: string = SHOWBOAT.SystemInformation.getCameraName(deviceID);

    if (cameraName) {
      SHOWBOAT.LocalAvatarDataManager.currentCameraName = cameraName;
      //SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
      ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.DeviceDebug);
    }
    ServerHelper.UpdateDeviceDebugLists();
  }

  public static OnMicrophoneDeviceChangedDeviceDebug(deviceID: string): void {
    let microphoneName: string =
      SHOWBOAT.SystemInformation.getMicrophoneName(deviceID);

    if (microphoneName) {
      SHOWBOAT.LocalAvatarDataManager.currentMicName = microphoneName;
      //SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
      ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.DeviceDebug);
    }
    ServerHelper.UpdateDeviceDebugLists();
  }

  public static OnSpeakerDeviceChangedDeviceDebug(deviceID: string): void {
    let speakerName: string =
      SHOWBOAT.SystemInformation.getSpeakerName(deviceID);
    if (speakerName) {
      SHOWBOAT.LocalAvatarDataManager.currentSpeakerName = speakerName;
      //SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
      ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.DeviceDebug);
      ServerHelper.UpdateDeviceDebugLists();
    }
  }

  public static OnCameraStartedDeviceDebug(
    htmlElement: HTMLVideoElement
  ): void {
    ServerHelper.UpdateDeviceDebugLists();
  }

  public static OnMicrophoneStartedDeviceDebug(): void {
    ServerHelper.UpdateDeviceDebugLists();
  }

  public static onForceMuteMic(): void {
    //SHOWBOAT.AVController.forceMuteAudio();
    SHOWBOAT.LocalAvatarDataManager.isForceMuted = true;
    //SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_ForceMute);
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.ForceMute);
  }

  public static onUnforceMuteMic(): void {
    //SHOWBOAT.AVController.forceMuteAudio();
    SHOWBOAT.LocalAvatarDataManager.isForceMuted = false;
    //SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_ForceMute);
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.ForceMute);
  }

  public static onForceMutePlayerDataUpdate(
    avatarData: SHOWBOAT.AvatarData
  ): void {
    SHOWBOAT.LiveswitchDownstreamController.SetForceMute(
      avatarData.userID,
      avatarData.isForceMuted
    );
  }

  public static async UpdateDeviceDebugLists(
    localUpdateOnly: boolean = false
  ): Promise<boolean> {
    //Call to load SystemInformation again
    await SHOWBOAT.SystemInformation.Load();

    let notifyServer: boolean = false;

    //Current Camera
    let currentCameraName: string = ServerHelper.getCurrentCameraName();
    if (
      currentCameraName &&
      currentCameraName != SHOWBOAT.LocalAvatarDataManager.currentCameraName
    ) {
      notifyServer = true;
      SHOWBOAT.LocalAvatarDataManager.currentCameraName = currentCameraName;
    }

    //Current Mic
    let currentMicName: string = ServerHelper.getCurrentMicrophoneName();
    if (
      currentMicName &&
      currentMicName != SHOWBOAT.LocalAvatarDataManager.currentMicName
    ) {
      notifyServer = true;
      SHOWBOAT.LocalAvatarDataManager.currentMicName = currentMicName;
    }

    //CAMERA
    if (
      SHOWBOAT.SystemInformation.VideoInputDevices &&
      SHOWBOAT.SystemInformation.VideoInputDevices.length > 0
    ) {
      let cameraDeviceList = "";
      for (
        let i = 0;
        i < SHOWBOAT.SystemInformation.VideoInputDevices.length;
        ++i
      ) {
        if (i > 0) {
          cameraDeviceList += "|";
        }
        cameraDeviceList +=
          SHOWBOAT.SystemInformation.VideoInputDevices[i].label;
      }
      if (
        cameraDeviceList != SHOWBOAT.LocalAvatarDataManager.cameraDeviceList
      ) {
        SHOWBOAT.LocalAvatarDataManager.cameraDeviceList = cameraDeviceList;
        notifyServer = true;
      }
    }

    //MIC
    if (
      SHOWBOAT.SystemInformation.AudioInputDevices &&
      SHOWBOAT.SystemInformation.AudioInputDevices.length > 0
    ) {
      let micDeviceList = "";
      for (
        let i = 0;
        i < SHOWBOAT.SystemInformation.AudioInputDevices.length;
        ++i
      ) {
        if (i > 0) {
          micDeviceList += "|";
        }
        micDeviceList += SHOWBOAT.SystemInformation.AudioInputDevices[i].label;
      }
      if (micDeviceList != SHOWBOAT.LocalAvatarDataManager.micDeviceList) {
        SHOWBOAT.LocalAvatarDataManager.micDeviceList = micDeviceList;
        notifyServer = true;
      }
    }

    //SPEAKER
    if (SHOWBOAT.SystemInformation.HasSpeakers) {
      let speakerDeviceList = "";
      for (
        let i = 0;
        i < SHOWBOAT.SystemInformation.AudioOutputDevices.length;
        ++i
      ) {
        if (i > 0) {
          speakerDeviceList += "|";
        }
        speakerDeviceList +=
          SHOWBOAT.SystemInformation.AudioOutputDevices[i].label;
      }
      if (
        speakerDeviceList != SHOWBOAT.LocalAvatarDataManager.speakerDeviceList
      ) {
        SHOWBOAT.LocalAvatarDataManager.speakerDeviceList = speakerDeviceList;
        notifyServer = true;
      }
    }

    //Notify the server of new device lists
    if (
      !localUpdateOnly &&
      notifyServer &&
      SHOWBOAT.SocketIOController.isConnected
    ) {
      //SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_DeviceDebug);
      ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.DeviceDebug);
    }

    return true;
  }

  private static getCurrentCameraName(): string {
    let currentCameraDeviceID: string;
    if (SHOWBOAT.StreamingUserMedia.isMicrophoneRunning()) {
      currentCameraDeviceID =
        SHOWBOAT.StreamingUserMedia.getCurrentCameraDevice();
    } else {
      currentCameraDeviceID =
        SHOWBOAT.StreamingUserMedia.getPrefferedCameraDevice();
    }

    if (!currentCameraDeviceID) return undefined;
    return SHOWBOAT.SystemInformation.getCameraName(currentCameraDeviceID);
  }

  private static getCurrentMicrophoneName(): string {
    let currentMicrophoneDeviceID: string;
    if (SHOWBOAT.StreamingUserMedia.isMicrophoneRunning()) {
      currentMicrophoneDeviceID =
        SHOWBOAT.StreamingUserMedia.getCurrentMicrophoneDevice();
    } else {
      currentMicrophoneDeviceID =
        SHOWBOAT.StreamingUserMedia.getPrefferedMicrophoneDevice();
    }

    if (!currentMicrophoneDeviceID) return undefined;
    return SHOWBOAT.SystemInformation.getMicrophoneName(
      currentMicrophoneDeviceID
    );
  }

  private static getCurrentSpeakerName(): string {
    let currentSpeakerDeviceID: string =
      SHOWBOAT.StreamingUserMedia.getCurrentSpeakerDevice();
    if (!currentSpeakerDeviceID) return undefined;
    return SHOWBOAT.SystemInformation.getSpeakerName(currentSpeakerDeviceID);
  }

  private static On3DAvatarLoadComplete() {
    SHOWBOAT.LocalAvatarDataManager.loadComplete = true;
    //SHOWBOAT.SocketIOController.UpdatePlayerData(SHOWBOAT.LocalAvatarDataManager.avatarData, SHOWBOAT.RemoteAvatarDataManager.CHANGEREASON_LoadComplete);
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.LoadComplete);
  }

  //Stash intake data for login code
  public static stashIntakeDataForLoginCode(
    firstName,
    lastName,
    company,
    emailAddress
  ) {
    let loginCode: string = ServerHelper.loginCode;
    let intakeObj: any = {
      firstName,
      lastName,
      company,
      emailAddress,
    };

    let intakeDataStringVariable =
      StringVariableHelper.LocalStorageProperties.IntakeData;

    //First check if we have intakeData stored in local storage
    if (localStorage.getItem(intakeDataStringVariable) !== null) {
      let intakeData = JSON.parse(
        localStorage.getItem(intakeDataStringVariable)
      );

      //Add or overwrite this intakeData associated with this loginCode
      intakeData[loginCode] = intakeObj;

      //Overwrite the master
      intakeData["master"] = intakeObj;

      localStorage.setItem(
        intakeDataStringVariable,
        JSON.stringify(intakeData)
      );
    } else {
      //intakeData does not exist in localStorage, so set it up
      let intakeData: any = {};

      //Set intakeObj for this login code
      intakeData[loginCode] = intakeObj;

      //Set master
      intakeData["master"] = intakeObj;

      localStorage.setItem(
        intakeDataStringVariable,
        JSON.stringify(intakeData)
      );
    }
  }

  /*     public static OnFullscreenPresentationToggle(toggleValue : boolean, leftSidebarOpen : boolean, rightSidebarOpen : boolean) : void {
        if(toggleValue){
            let color : string = SHOWBOAT.ApplicationSkin.secondaryAvatarColors[SHOWBOAT.LocalAvatarDataManager.avatarData.color];
            let imageURL : string = "assets/images/faces/" + SHOWBOAT.ApplicationSkin.faceThumbnailURLs[SHOWBOAT.LocalAvatarDataManager.face];
            SHOWBOAT.StreamingUserMedia.drawAvatarFaceOverAvatar(imageURL, color);
        } else {
            SHOWBOAT.StreamingUserMedia.stopAvatarFaceOverCamera();
        }
    } */

  private static firstNames = [
    "Adam",
    "Alex",
    "Aaron",
    "Ben",
    "Carl",
    "Dan",
    "David",
    "Edward",
    "Fred",
    "Frank",
    "George",
    "Hal",
    "Hank",
    "Ike",
    "John",
    "Jack",
    "Joe",
    "Larry",
    "Monte",
    "Matthew",
    "Mark",
    "Nathan",
    "Otto",
    "Paul",
    "Peter",
    "Roger",
    "Roger",
    "Steve",
    "Thomas",
    "Tim",
    "Ty",
    "Victor",
    "Walter",
  ];
  private static lastNames = [
    "Anderson",
    "Ashwoon",
    "Aikin",
    "Bateman",
    "Bongard",
    "Bowers",
    "Boyd",
    "Cannon",
    "Cast",
    "Deitz",
    "Dewalt",
    "Ebner",
    "Frick",
    "Hancock",
    "Haworth",
    "Hesch",
    "Hoffman",
    "Kassing",
    "Knutson",
    "Lawless",
    "Lawicki",
    "Mccord",
    "McCormack",
    "Miller",
    "Myers",
    "Nugent",
    "Ortiz",
    "Orwig",
    "Ory",
    "Paiser",
    "Pak",
    "Pettigrew",
    "Quinn",
    "Quizoz",
    "Ramachandran",
    "Resnick",
    "Sagar",
    "Schickowski",
    "Schiebel",
    "Sellon",
    "Severson",
    "Shaffer",
    "Solberg",
    "Soloman",
    "Sonderling",
    "Soukup",
    "Soulis",
    "Stahl",
    "Sweeney",
    "Tandy",
    "Trebil",
    "Trusela",
    "Trussel",
    "Turco",
    "Uddin",
    "Uflan",
    "Ulrich",
    "Upson",
    "Vader",
    "Vail",
    "Valente",
    "Van Zandt",
    "Vanderpoel",
    "Ventotla",
    "Vogal",
    "Wagle",
    "Wagner",
    "Wakefield",
    "Weinstein",
    "Weiss",
    "Woo",
    "Yang",
    "Yates",
    "Yocum",
    "Zeaser",
    "Zeller",
    "Ziegler",
    "Bauer",
    "Baxster",
    "Casal",
    "Cataldi",
    "Caswell",
    "Celedon",
    "Chambers",
    "Chapman",
    "Christensen",
    "Darnell",
    "Davidson",
    "Davis",
    "DeLorenzo",
    "Dinkins",
    "Doran",
    "Dugelman",
    "Dugan",
    "Duffman",
    "Eastman",
    "Ferro",
    "Ferry",
    "Fletcher",
    "Fietzer",
    "Hylan",
    "Hydinger",
    "Illingsworth",
    "Ingram",
    "Irwin",
    "Jagtap",
    "Jenson",
    "Johnson",
    "Johnsen",
    "Jones",
    "Jurgenson",
    "Kalleg",
    "Kaskel",
    "Keller",
    "Leisinger",
    "LePage",
    "Lewis",
    "Linde",
    "Lulloff",
    "Maki",
    "Martin",
    "McGinnis",
    "Mills",
    "Moody",
    "Moore",
    "Napier",
    "Nelson",
    "Norquist",
    "Nuttle",
    "Olson",
    "Ostrander",
    "Reamer",
    "Reardon",
    "Reyes",
    "Rice",
    "Ripka",
    "Roberts",
    "Rogers",
    "Root",
    "Sandstrom",
    "Sawyer",
    "Schlicht",
    "Schmitt",
    "Schwager",
    "Schutz",
    "Schuster",
    "Tapia",
    "Thompson",
    "Tiernan",
    "Tisler",
  ];
  private static companySuffix = ["LLC", "Inc.", "Corp."];

  public static getRandomFirst() {
    let index = Math.floor(
      Math.random() * Math.floor(ServerHelper.firstNames.length - 1)
    );
    return ServerHelper.firstNames[index];
  }

  public static getRandomLast() {
    let index = Math.floor(
      Math.random() * Math.floor(ServerHelper.lastNames.length - 1)
    );
    return ServerHelper.lastNames[index];
  }

  public static getRandomCompanySuffix() {
    let index = Math.floor(
      Math.random() * Math.floor(ServerHelper.companySuffix.length - 1)
    );
    return ServerHelper.companySuffix[index];
  }

  public static UpdatePlayerData(changeReason: SHOWBOAT.ChangeReason) {
    let updateObject: any = {};
    switch (changeReason) {
      case SHOWBOAT.ChangeReason.Camera:
        updateObject.cE = SHOWBOAT.LocalAvatarDataManager.cameraEnabled;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.Camera
        );
        break;
      case SHOWBOAT.ChangeReason.Mic:
        updateObject.mE = SHOWBOAT.LocalAvatarDataManager.micEnabled;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.Mic
        );
        break;
      case SHOWBOAT.ChangeReason.CameraAndMic:
        updateObject.cE = SHOWBOAT.LocalAvatarDataManager.cameraEnabled;
        updateObject.mE = SHOWBOAT.LocalAvatarDataManager.micEnabled;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.CameraAndMic
        );
        break;
      case SHOWBOAT.ChangeReason.DeviceDebug:
        updateObject.cE = SHOWBOAT.LocalAvatarDataManager.cameraEnabled;
        updateObject.mE = SHOWBOAT.LocalAvatarDataManager.micEnabled;
        updateObject.cC = SHOWBOAT.LocalAvatarDataManager.currentCameraName;
        updateObject.cM = SHOWBOAT.LocalAvatarDataManager.currentMicName;
        updateObject.cS = SHOWBOAT.LocalAvatarDataManager.currentSpeakerName;
        updateObject.cD = SHOWBOAT.LocalAvatarDataManager.cameraDeviceList;
        updateObject.mD = SHOWBOAT.LocalAvatarDataManager.micDeviceList;
        updateObject.sD = SHOWBOAT.LocalAvatarDataManager.speakerDeviceList;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.DeviceDebug
        );
        break;
      case SHOWBOAT.ChangeReason.ForceMute:
        updateObject.fM = SHOWBOAT.LocalAvatarDataManager.isForceMuted;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.ForceMute
        );
        break;
      case SHOWBOAT.ChangeReason.LoadComplete:
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.LoadComplete
        );
        break;
      case SHOWBOAT.ChangeReason.LaserEnabled:
        updateObject.lE = SHOWBOAT.LocalAvatarDataManager.laserEnabled;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.LaserEnabled
        );
        break;
      case SHOWBOAT.ChangeReason.FaceNumber:
        updateObject.fN = SHOWBOAT.LocalAvatarDataManager.face;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.FaceNumber
        );
        break;
      case SHOWBOAT.ChangeReason.ColorNumber:
        updateObject.cN = SHOWBOAT.LocalAvatarDataManager.color;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.ColorNumber
        );
        break;
      case SHOWBOAT.ChangeReason.NameTag:
        updateObject.gN = SHOWBOAT.LocalAvatarDataManager.firstName;
        updateObject.lN = SHOWBOAT.LocalAvatarDataManager.lastName;
        updateObject.cO = SHOWBOAT.LocalAvatarDataManager.company;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.NameTag
        );
        break;
      case SHOWBOAT.ChangeReason.TeleportAvailability:
        updateObject.aT =
          SHOWBOAT.LocalAvatarDataManager.isAvailableForTeleport;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.TeleportAvailability
        );
        break;
      case SHOWBOAT.ChangeReason.AdditionalData:
        updateObject.aD = 
        SHOWBOAT.LocalAvatarDataManager.additionalData;
        SHOWBOAT.SocketIOController.UpdatePlayerData(
          updateObject,
          SHOWBOAT.ChangeReason.AdditionalData
        );
        break;
    }

    /*
            cameraEnabled => cE
            micEnabled => mE
            currentMicName => cM
            currentCameraName => cC
            currentSpeakerName => cS
            micDeviceList => mD
            cameraDeviceList => cD
            speakerDeviceList => sD
            */
  }

  public static updateIsAvailableForTeleport = (
    isAvailableForTeleport: boolean
  ): void => {
    //Execute logic if isAvailableForTeleport is different from what is currently on LocalAvatarDataManager
    if (
      SHOWBOAT.LocalAvatarDataManager.isAvailableForTeleport !=
      isAvailableForTeleport
    ) {
      //Change the value on AvatarData and on the server
      SHOWBOAT.LocalAvatarDataManager.isAvailableForTeleport =
        isAvailableForTeleport;

      ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.TeleportAvailability);
    }
  };

  private static handleAdminKick = (userID: string): void => {
    //Check if there is a banned array already
    let loginCodeArray;

    if (
      localStorage.getItem(
        StringVariableHelper.LocalStorageProperties.BannedLoginCodes
      ) !== null
    ) {
      loginCodeArray = JSON.parse(
        localStorage.getItem(
          StringVariableHelper.LocalStorageProperties.BannedLoginCodes
        )
      );
    } else {
      loginCodeArray = [];
    }

    //Add this login code to the array
    loginCodeArray.push(ServerHelper.loginCode);

    //Put back into localStorage
    localStorage.setItem(
      StringVariableHelper.LocalStorageProperties.BannedLoginCodes,
      JSON.stringify(loginCodeArray)
    );

    //Reload the page
    window.location.reload();
  };

  public static handleAudioDistanceChange = (eventObj: any): void => {
    //If userID is our own, don't do anything to zone config
    if (eventObj.userID === SHOWBOAT.LocalAvatarDataManager.userID) return;

    SHOWBOAT.RemotePlayersZoneConfig.useAutoBubble = eventObj.auto;
    if (!eventObj.auto) {
      SHOWBOAT.RemotePlayersZoneConfig.setAudioBubbleDistance(
        eventObj.distance
      );
    }

    SHOWBOAT.UIEventManager.OnAudioDistanceChange.Raise(eventObj.distance);
  };

  public static getRandomColor = () => {
    return Math.floor(Math.random() * 7);
  };

  public static async getServerToken(): Promise<string | boolean> {
    try {
      //Check if alias was used previously to get correct URL version
      const url =
        ServerHelper.aliasUrlUsed === true
          ? ServerHelper.appAPIUrlAlias
          : ServerHelper.appAPIUrl;

      const dataGetToken = await axios.post(
        url + "token-create",
        JSON.stringify({
          token: ServerHelper.attendeesIDToken,
        })
      );

      SHOWBOAT.Logger.Log("Data get token response:", dataGetToken);

      return dataGetToken.data;
    } catch (error) {
      SHOWBOAT.Logger.Error("Error creating server token");

      return false;
    }
  }

  public static fooValue: number = 0;

  public static fooPosition: number = 0;
  public static fooRotation: number = 0;

  public static async testingFoo() {
    //Networked data
    /*
        setTimeout(async ()=>{
            if(SHOWBOAT.LocalAvatarDataManager.role == "presenter"){

                                
                let claimResult = await SHOWBOAT.SocketIOController.ClaimNetworkedData("PRESENTER#foo6", false);
                console.log("Claim result", claimResult);

                let fooInterval = setInterval(async ()=>{
                    console.log("D");
                    let dataObject = {fooValue: ++ServerHelper.fooValue}
                    SHOWBOAT.SocketIOController.UpdateNetworkedData("PRESENTER#foo6", false, dataObject);
                    console.log("Updating DATA", dataObject);
                }, 2000);

                setTimeout(()=>{
                    clearInterval(fooInterval);
                    SHOWBOAT.SocketIOController.ReleaseNetworkedData("PRESENTER#foo6", false);
                },20000);


            } else {

                setInterval(async ()=>{
                    let data : SHOWBOAT.RequestNetworkedDataResponse = await SHOWBOAT.SocketIOController.RequestNetworkedData("PRESENTER#foo6", false);
                    console.log("DATA", data.data);

                    let ownerInfo : SHOWBOAT.GetNetworkedEntityOwner = await SHOWBOAT.SocketIOController.GetNetworkedDataOwner("PRESENTER#foo6", false);
                    console.log("OWNER", ownerInfo);
                }, 2000);

            }
        },5000);
        */
    //Transform data
    /*
        setTimeout(async ()=>{
            if(SHOWBOAT.LocalAvatarDataManager.role == "presenter"){
                let claimResult = await SHOWBOAT.SocketIOController.ClaimNetworkTransform("PRESENTER#foo4Transform", false);
                console.log("Claim result", claimResult);

                let fooTranformInterval =  setInterval(async ()=>{
                    ServerHelper.fooPosition += 0.1;
                    ServerHelper.fooRotation += 0.2;
                    let position = [ServerHelper.fooPosition, ServerHelper.fooPosition + 1, ServerHelper.fooPosition + 2];
                    let rotation = [ServerHelper.fooRotation, ServerHelper.fooRotation + 1, ServerHelper.fooRotation + 2];
                    SHOWBOAT.SocketIOController.UpdateNetworkedTransform("PRESENTER#foo4Transform", false, position, rotation);
                    console.log("Updating Transform", position, rotation);
                }, 2000);    
                
                setTimeout(()=>{
                    clearInterval(fooTranformInterval);
                    SHOWBOAT.SocketIOController.ReleaseNetworkedTransform("PRESENTER#foo4Transform", false);
                },20000);


            } else {
                setInterval(async ()=>{
                    let data : SHOWBOAT.ObjectPositionData = await SHOWBOAT.SocketIOController.RequestNetworkedTransforms();
                    for(let i=0; i< data.count; ++i){
                        let networkedObjectPositionData : SHOWBOAT.NetworkedObjectPositionData = data.data[i];
                        console.log("networkedObjectPositionData:", networkedObjectPositionData.transformID, networkedObjectPositionData.position, networkedObjectPositionData.rotation);
                    }

                    let ownerInfo : SHOWBOAT.GetNetworkedEntityOwner = await SHOWBOAT.SocketIOController.GetNetworkedTransformOwner("PRESENTER#foo4Transform", false);
                    console.log("OWNER", ownerInfo);

                }, 2000);
            }
        },5000);
        */
    /*
        SHOWBOAT.InterpolationManager.RegisterSnapshotID("foo",["x","y"], 10);
        let x = 0;
        let y = 11;
        SHOWBOAT.InterpolationManager.AddSnapshot("foo",{x,y});
        setInterval(()=>{
            SHOWBOAT.InterpolationManager.AddSnapshot("foo",{x:++x,y:++y});
        }, 200);


        setInterval(()=>{
            let snapshot:SHOWBOAT.Snapshot = SHOWBOAT.InterpolationManager.GetSnapshot("foo");
            if(snapshot){
                console.log("Snapshot:", snapshot.data.x);
            } else {
                console.log("No data");
            }
        }, 51);*/
  }
}

(window as any).ServerHelper = ServerHelper;
