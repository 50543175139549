import { ServerHelper } from "./ServerHelper";
import { StringVariableHelper } from "./StringVariableHelper";

export class UserPermissionsHelper {
  public static canPresent;

  public static canUseLaser;

  public static canControlAvatarAudio;

  public static canTeleport;

  public static canBreakout;

  public static canShareWebcam;

  public static canScreenshare;

  public static canShareSlides;

  public static canShareLocalVideo;

  public static canShareLivestream;

  public static canShareDefault;

  public static Init() {
    UserPermissionsHelper.canPresent =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canPresent
      ] !== false;

    UserPermissionsHelper.canUseLaser =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canUseLaser
      ] !== false;

    UserPermissionsHelper.canControlAvatarAudio =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canControlAvatarAudio
      ] !== false;

    UserPermissionsHelper.canTeleport =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canTeleport
      ] !== false;

    UserPermissionsHelper.canBreakout =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canBreakout
      ] !== false;

    UserPermissionsHelper.canShareWebcam =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canShareWebcam
      ] !== false;

    UserPermissionsHelper.canScreenshare =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canScreenshare
      ] !== false;

    UserPermissionsHelper.canShareSlides =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canShareSlides
      ] !== false;

    UserPermissionsHelper.canShareLocalVideo =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canShareLocalVideo
      ] !== false;

    UserPermissionsHelper.canShareLivestream =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canShareLivestream
      ] !== false;

    UserPermissionsHelper.canShareDefault =
      ServerHelper.userPermissions[
        StringVariableHelper.PresenterPermissions.canShareDefault
      ] !== false;
  }
}

(window as any).UserPermissionsHelper = UserPermissionsHelper;
