import React, { ReactElement, useState, useContext, useRef, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import isEmail from "validator/lib/isEmail";
import InputMask from "react-input-mask";

import {
  BioFormContext,
  BioFormContextType,
} from "../../context/BioFormContext";
import { ServerHelper } from "../../Utilities/ServerHelper";

import styles from "./BioIntakeForm.module.css";
import { BioIntakeFormStyles } from "./BioIntakeFormStyles";
import { ChangeEvent } from "react";

interface Props {
  standalone: boolean;
  onBioFormSubmit: () => any;
}

export default function BioIntakeForm(props: Props): ReactElement {
  const intakeFieldMap: Map<string, SHOWBOAT.IntakeField> = new Map<
    string,
    SHOWBOAT.IntakeField
  >();
  const initialErrorMap: Map<string, boolean> = new Map<string, boolean>();

  let usAddressFields: Array<SHOWBOAT.IntakeField> = [
    {
      name: "Address Line 1",
      visible: true,
      mandatory: SHOWBOAT.ApplicationSkin.intakeFields[5].mandatory,
    },
    { name: "Address Line 2", visible: true, mandatory: false },
    {
      name: "City",
      visible: true,
      mandatory: SHOWBOAT.ApplicationSkin.intakeFields[5].mandatory,
    },
    {
      name: "State",
      visible: true,
      mandatory: SHOWBOAT.ApplicationSkin.intakeFields[5].mandatory,
    },
    {
      name: "Zip Code",
      visible: true,
      mandatory: SHOWBOAT.ApplicationSkin.intakeFields[5].mandatory,
    },
  ];

  let internationalAddressFields: Array<SHOWBOAT.IntakeField> = [
    {
      name: "Address Line 1",
      visible: true,
      mandatory: SHOWBOAT.ApplicationSkin.intakeFields[5].mandatory,
    },
    { name: "Address Line 2", visible: true, mandatory: false },
    {
      name: "City",
      visible: true,
      mandatory: SHOWBOAT.ApplicationSkin.intakeFields[5].mandatory,
    },
    { name: "State/Province/Region", visible: true, mandatory: false },
    { name: "Zip/Postal Code", visible: true, mandatory: false },
    {
      name: "Country",
      visible: true,
      mandatory: SHOWBOAT.ApplicationSkin.intakeFields[5].mandatory,
    },
  ];

  SHOWBOAT.ApplicationSkin.intakeFields.forEach(
    (intakeField: SHOWBOAT.IntakeField) => {
      if (intakeField.name === "Address" && intakeField.visible === true) {
        if (intakeField.type === "US") {
          usAddressFields.forEach((intakeField: SHOWBOAT.IntakeField) => {
            intakeFieldMap.set(intakeField.name, intakeField);
            initialErrorMap.set(intakeField.name, false);
          });
        } else if (intakeField.type === "international") {
          internationalAddressFields.forEach(
            (intakeField: SHOWBOAT.IntakeField) => {
              intakeFieldMap.set(intakeField.name, intakeField);
              initialErrorMap.set(intakeField.name, false);
            }
          );
        }
      } else {
        intakeFieldMap.set(intakeField.name, intakeField);
        initialErrorMap.set(intakeField.name, false);
      }
    }
  );

  //context
  const {
    contextFirstName,
    setContextFirstName,
    contextLastName,
    setContextLastName,
    contextCompany,
    setContextCompany,
    contextEmail,
    setContextEmail,
    contextAddress,
    setContextAddress,
    contextPhone,
    setContextPhone,
  }: BioFormContextType = useContext(BioFormContext);

  /**State store */
  const [firstName, setFirstName] = useState(contextFirstName);

  const [lastName, setLastName] = useState(contextLastName);

  const [company, setCompany] = useState(contextCompany);

  //grabs already-existing value from context if it exists
  const [emailAddress, setEmailAddress] = useState(contextEmail);
  const [emailAddressError, toggleEmailAddressError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(contextPhone);

  const [addressLine1, setAddressLine1] = useState(
    contextAddress.addressLine1 !== undefined ? contextAddress.addressLine1 : ""
  );

  const [addressLine2, setAddressLine2] = useState(
    contextAddress.addressLine2 !== undefined ? contextAddress.addressLine2 : ""
  );

  const [city, setCity] = useState(
    contextAddress.city !== undefined ? contextAddress.city : ""
  );

  /* US ADDRESSES */
  const [state, setState] = useState(
    contextAddress.state !== undefined ? contextAddress.state : ""
  );

  const [zipCode, setZipCode] = useState(
    contextAddress.zipCode !== undefined ? contextAddress.zipCode : ""
  );
  /**/

  /* INTERNATIONAL ADDRESSES */
  const [stateProvinceRegion, setStateProvinceRegion] = useState(
    contextAddress.stateProvinceRegion !== undefined
      ? contextAddress.stateProvinceRegion
      : ""
  );

  const [zipPostalCode, setZipPostalCode] = useState(
    contextAddress.zipPostalCode !== undefined
      ? contextAddress.zipPostalCode
      : ""
  );

  const [country, setCountry] = useState(
    contextAddress.country !== undefined ? contextAddress.country : ""
  );
  /**/

  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

  const [, toggleNeedsDraw] = useState(false);

  const [disableCompany, setDisableCompany] = useState(ServerHelper.useMobileApp ? true : false);
  
  /**/

  const errorMapRef = useRef(initialErrorMap);
  const disableCompanyTimeout = useRef(null);

  useEffect(() => {
    if (ServerHelper.useMobileApp) {
      disableCompanyTimeout.current = setTimeout(() => {
        setDisableCompany(false);
      }, 100)
    }
  }, [])

  /* Input change handlers */
  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("First Name", false);

    SHOWBOAT.UIEventManager.OnFirstNameChanged.Raise(e.target.value);

    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Last Name", false);

    SHOWBOAT.UIEventManager.OnLastNameChanged.Raise(e.target.value);

    setLastName(e.target.value);
  };

  const handleCompanyChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Company", false);

    SHOWBOAT.UIEventManager.OnCompanyChanged.Raise(e.target.value);

    setCompany(e.target.value);
  };

  const handleEmailAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    if (isEmail(e.target.value) || !e.target.value.trim().length) {
      toggleEmailAddressError(false);
    }

    errorMapRef.current = errorMapRef.current.set("Email", false);

    setEmailAddress(e.target.value);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Phone", false);

    setPhoneNumber(e.target.value);
  };

  const handleAddressLine1Change = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Address Line 1", false);

    setAddressLine1(e.target.value);
  };

  const handleAddressLine2Change = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Address Line 2", false);

    setAddressLine2(e.target.value);
  };

  const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("City", false);

    setCity(e.target.value);
  };

  const handleStateChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("State", false);

    setState(e.target.value);
  };

  const handleZipCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Zip Code", false);

    setZipCode(e.target.value);
  };

  const handleStateProvinceRegionChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set(
      "State/Province/Region",
      false
    );

    setStateProvinceRegion(e.target.value);
  };

  const handleZipPostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Zip/Postal Code", false);

    setZipPostalCode(e.target.value);
  };

  const handleCountryChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Country", false);

    setCountry(e.target.value);
  };
  /**/

  /* submit handler */
  const handleBioFormSubmit = (e) => {
    SHOWBOAT.Logger.Log("EVENT:", e.type);

    let formHasError: boolean = false;

    let intakeField: SHOWBOAT.IntakeField;

    toggleNeedsDraw(false);

    //First name
    intakeField = intakeFieldMap.get("First Name");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !firstName.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Last name
    intakeField = intakeFieldMap.get("Last Name");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !lastName.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //company
    intakeField = intakeFieldMap.get("Company");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !company.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //email
    intakeField = intakeFieldMap.get("Email");
    let emailValidErr = false;
    if (emailAddress.trim().length && !isEmail(emailAddress)) {
      if (intakeField && intakeField.visible) {
        toggleEmailAddressError(true);
        emailValidErr = true;
        formHasError = true;
      }
    }
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !emailAddress.trim().length || emailValidErr;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //phone number
    intakeField = intakeFieldMap.get("Phone");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !phoneNumber.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //address line 1
    intakeField = intakeFieldMap.get("Address Line 1");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !addressLine1.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //address line 2
    intakeField = intakeFieldMap.get("Address Line 2");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !addressLine2.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //city
    intakeField = intakeFieldMap.get("City");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !city.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //state
    intakeField = intakeFieldMap.get("State");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !state.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //zip code
    intakeField = intakeFieldMap.get("Zip Code");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !zipCode.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    /* INTERNATIONAL SPECIFIC */
    //state/province/region
    intakeField = intakeFieldMap.get("State/Province/Region");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !stateProvinceRegion.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //zip/postal code
    intakeField = intakeFieldMap.get("Zip/Postal Code");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !zipPostalCode.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //country/region
    intakeField = intakeFieldMap.get("Country");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error: boolean = !country.trim().length;
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }
    /**/

    if (props.standalone) {
      /*TODO: TODO: TODO: TODO:
       *  - SUBMIT STANDALONE DATA TO SERVER
       */
    } else {
      let addressObj: SHOWBOAT.USAddress | SHOWBOAT.InternationalAddress;

      if (SHOWBOAT.ApplicationSkin.intakeFields[5].type === "US") {
        addressObj = {
          addressLine1,
          addressLine2,
          city,
          state,
          zipCode,
        };
      } else {
        addressObj = {
          addressLine1,
          addressLine2,
          city,
          stateProvinceRegion,
          zipPostalCode,
          country,
        };
      }

      //Set values in localStorage to cookie for further visits (if not in dev mode)
      if (process.env.REACT_APP_API_STAGE !== "dev") {
        ServerHelper.stashIntakeDataForLoginCode(
          firstName,
          lastName,
          company,
          emailAddress
        );
      }

      //set values in context on submit
      setContextFirstName(firstName);
      setContextLastName(lastName);
      setContextCompany(company);
      setContextEmail(emailAddress);
      setContextPhone(phoneNumber);
      setContextAddress(addressObj);

      if (formHasError) {
        setFormHasBeenSubmitted(true);
      } else {
        setFormHasBeenSubmitted(true);
        //TODO TODO TODO
        //null out the maps before leaving
        props.onBioFormSubmit();
      }
    }
  };
  /**/

  //materialUI classes
  const classes = BioIntakeFormStyles();

  let formFields: Array<JSX.Element> = [];
  let intakeField: SHOWBOAT.IntakeField;
  let fieldName: string;

  let emailInputClass: string;

  if (
    errorMapRef.current.get("Email") ||
    emailAddressError ||
    intakeFieldMap.get("Email").mandatory
  ) {
    if (ServerHelper.useMobileApp) {
      emailInputClass = `${classes.bioInputMobile}`;
    } else {
      emailInputClass = `${classes.bioInput}`;
    }
  } else {
    if (ServerHelper.useMobileApp) {
      emailInputClass = `${classes.bioInputMobileNotRequired}`;
    } else {
      emailInputClass = `${classes.bioInputNotRequired}`;
    }
  }

  

  //First Name field
  fieldName = "First Name";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        id={fieldName}
        inputProps={{ maxLength: 50 }}
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        InputProps={{
          className: classes.input,
        }}
        variant="outlined"
        classes={
          ServerHelper.useMobileApp
            ? intakeField.mandatory
              ? { root: classes.bioInputMobile }
              : { root: classes.bioInputMobileNotRequired }
            : intakeField.mandatory
            ? { root: classes.bioInput }
            : { root: classes.bioInputNotRequired }
        }
        key="First Name"
        onChange={handleFirstNameChange}
        value={firstName}
        error={errorMapRef.current.get(fieldName)}
      />
    );
  }

  //Last Name field
  fieldName = "Last Name";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        inputProps={{ maxLength: 50 }}
        variant="outlined"
        InputProps={{
          className: classes.input,
        }}
        classes={
          ServerHelper.useMobileApp
            ? intakeField.mandatory
              ? { root: classes.bioInputMobile }
              : { root: classes.bioInputMobileNotRequired }
            : intakeField.mandatory
            ? { root: classes.bioInput }
            : { root: classes.bioInputNotRequired }
        }
        key="Last Name"
        onChange={handleLastNameChange}
        value={lastName}
        error={errorMapRef.current.get(fieldName)}
      />
    );
  }

  //Company field
  fieldName = "Company";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        inputProps={{ maxLength: 50 }}
        variant="outlined"
        InputProps={{
          classes: {
            root: classes.input,
            disabled: classes.inputDisabled
          }
        }}
        disabled={disableCompany}
        classes={
          ServerHelper.useMobileApp
            ? intakeField.mandatory
              ? { root: classes.bioInputMobile }
              : { root: classes.bioInputMobileNotRequired }
            : intakeField.mandatory
            ? { root: classes.bioInput }
            : { root: classes.bioInputNotRequired }
        }
        onChange={handleCompanyChange}
        key="Company"
        value={company}
        error={errorMapRef.current.get(fieldName)}
      />
    );
  }

  //Email Address field
  fieldName = "Email";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={
          intakeField.mandatory
            ? formHasBeenSubmitted
              ? emailAddressError
                ? "Must be a valid email address"
                : "Required"
              : "Required"
            : formHasBeenSubmitted
            ? emailAddressError
              ? "Must be a valid email address"
              : ""
            : ""
        }
        label={intakeField.name}
        inputProps={{ maxLength: 50 }}
        variant="outlined"
        InputProps={{
          className: classes.input,
        }}
        classes={{ root: emailInputClass }}
        onChange={handleEmailAddressChange}
        key="Email"
        value={emailAddress}
        error={errorMapRef.current.get(fieldName) || emailAddressError}
      />
    );
  }

  //Telephone number field
  fieldName = "Phone";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {

    formFields.push(
      <InputMask
        mask={
          SHOWBOAT.ApplicationSkin.intakeFields[5].type === "international"
            ? "+9 (999) 999-9999"
            : "(999) 999-9999"
        }
        value={phoneNumber}
        disabled={false}
        key="input mask"
        onChange={handlePhoneChange}
        maskChar=" "
      >
        {() => (
          <TextField
            helperText={intakeFieldMap.get("Phone").mandatory ? "Required" : ""}
            label="Phone"
            InputProps={{
              className: classes.input,
            }}
            variant="outlined"
            classes={
              ServerHelper.useMobileApp
                ? intakeFieldMap.get("Phone").mandatory
                  ? { root: classes.bioInputMobile }
                  : { root: classes.bioInputMobileNotRequired }
                : intakeFieldMap.get("Phone").mandatory
                ? { root: classes.bioInput }
                : { root: classes.bioInputNotRequired }
            }
            key="Phone"
            error={errorMapRef.current.get("Phone")}
          />
        )}
      </InputMask>
    );
  }

  //address line 1 field
  fieldName = "Address Line 1";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    
    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        inputProps={{ maxLength: 250 }}
        variant="outlined"
        InputProps={{
          className: classes.input,
        }}
        classes={
          ServerHelper.useMobileApp
            ? intakeField.mandatory
              ? { root: classes.bioInputMobile }
              : { root: classes.bioInputMobileNotRequired }
            : intakeField.mandatory
            ? { root: classes.bioInput }
            : { root: classes.bioInputNotRequired }
        }
        onChange={handleAddressLine1Change}
        key="Address Line 1"
        value={addressLine1}
        error={errorMapRef.current.get(fieldName)}
      />
    );
  }

  //address line 2 field
  fieldName = "Address Line 2";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {

    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        inputProps={{ maxLength: 250 }}
        variant="outlined"
        InputProps={{
          className: classes.input,
        }}
        classes={
          ServerHelper.useMobileApp
            ? intakeField.mandatory
              ? { root: classes.bioInputMobile }
              : { root: classes.bioInputMobileNotRequired }
            : intakeField.mandatory
            ? { root: classes.bioInput }
            : { root: classes.bioInputNotRequired }
        }
        onChange={handleAddressLine2Change}
        key="Address Line 2"
        value={addressLine2}
        error={errorMapRef.current.get(fieldName)}
      />
    );
  }

  //city field
  fieldName = "City";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {

    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        inputProps={{ maxLength: 50 }}
        variant="outlined"
        InputProps={{
          className: classes.input,
        }}
        classes={
          ServerHelper.useMobileApp
            ? intakeField.mandatory
              ? { root: classes.bioInputMobile }
              : { root: classes.bioInputMobileNotRequired }
            : intakeField.mandatory
            ? { root: classes.bioInput }
            : { root: classes.bioInputNotRequired }
        }
        onChange={handleCityChange}
        key="City"
        value={city}
        error={errorMapRef.current.get(fieldName)}
      />
    );
  }

  //DISPLAY US-SPECIFIC ADDRESS FIELDS
  if (SHOWBOAT.ApplicationSkin.intakeFields[5].type === "US") {
    //state field
    fieldName = "State";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {
      
      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label={"State"}
          inputProps={{ maxLength: 50 }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          classes={
            ServerHelper.useMobileApp
              ? intakeField.mandatory
                ? { root: classes.bioInputMobile }
                : { root: classes.bioInputMobileNotRequired }
              : intakeField.mandatory
              ? { root: classes.bioInput }
              : { root: classes.bioInputNotRequired }
          }
          onChange={handleStateChange}
          key="State"
          value={state}
          error={errorMapRef.current.get(fieldName)}
        />
      );
    }

    //zip code
    fieldName = "Zip Code";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {

      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="Zip Code"
          inputProps={{
            maxLength: 10,
          }}
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          classes={
            ServerHelper.useMobileApp
              ? intakeField.mandatory
                ? { root: classes.bioInputMobile }
                : { root: classes.bioInputMobileNotRequired }
              : intakeField.mandatory
              ? { root: classes.bioInput }
              : { root: classes.bioInputNotRequired }
          }
          onChange={handleZipCodeChange}
          key="Zip Code"
          value={zipCode}
          error={errorMapRef.current.get(fieldName)}
        />
      );
    }
  }
  //DISPLAY INTERNATIONAL FIELDS
  else {
    fieldName = "State/Province/Region";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) { 

      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="State/Province/Region"
          inputProps={{
            maxLength: 100,
          }}
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          classes={
            ServerHelper.useMobileApp
              ? intakeField.mandatory
                ? { root: classes.bioInputMobile }
                : { root: classes.bioInputMobileNotRequired }
              : intakeField.mandatory
              ? { root: classes.bioInput }
              : { root: classes.bioInputNotRequired }
          }
          onChange={handleStateProvinceRegionChange}
          key="State/Province/Region"
          value={stateProvinceRegion}
          error={errorMapRef.current.get(fieldName)}
        />
      );
    }

    fieldName = "Zip/Postal Code";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {

      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="Zip/Postal Code"
          inputProps={{
            maxLength: 10,
          }}
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          classes={
            ServerHelper.useMobileApp
              ? intakeField.mandatory
                ? { root: classes.bioInputMobile }
                : { root: classes.bioInputMobileNotRequired }
              : intakeField.mandatory
              ? { root: classes.bioInput }
              : { root: classes.bioInputNotRequired }
          }
          onChange={handleZipPostalCodeChange}
          key="Zip/Postal Code"
          value={zipPostalCode}
          error={errorMapRef.current.get(fieldName)}
        />
      );
    }

    fieldName = "Country";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {

      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="Country"
          inputProps={{
            maxLength: 100,
          }}
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          classes={
            ServerHelper.useMobileApp
              ? intakeField.mandatory
                ? { root: classes.bioInputMobile }
                : { root: classes.bioInputMobileNotRequired }
              : intakeField.mandatory
              ? { root: classes.bioInput }
              : { root: classes.bioInputNotRequired }
          }
          onChange={handleCountryChange}
          key="Country"
          value={country}
          error={errorMapRef.current.get(fieldName)}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <form
        className={
          ServerHelper.useMobileApp
          ?
            `${styles.bioForm} ${styles.bioFormMobile}`
          :
          props.standalone
            ? `${styles.bioForm} ${styles.bioFormStandalone}`
            : styles.bioForm
        }
        /* onSubmit={handleBioFormSubmit} */
      >
        {formFields}
      </form>
      
      <Button
        onClick={handleBioFormSubmit}
        variant="contained"
        classes={{ root: ServerHelper.useMobileApp ? `${classes.bioNext} ${classes.bioNextMobile}` : classes.bioNext }}
        id="bioIntakeNext"
        onTouchStart={handleBioFormSubmit}
      >
        NEXT
      </Button>
    </React.Fragment>
  );
}
