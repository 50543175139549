import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ChatIcon from "@material-ui/icons/Chat";
import EmojiIcon from "@material-ui/icons/EmojiEmotions";
import ScrollContainer from "react-indiana-drag-scroll";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import RightArrowIcon from "@material-ui/icons/ArrowForward";
import {
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { orderBy } from "natural-orderby";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import {
  MessageContext,
  MessageContextType,
} from "../../../../context/MessageContext";
import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import ChatMessage from "./ChatSidebarMessage/ChatSidebarMessage";

import styles from "./ChatSidebar.module.css";
import { ChatSidebarStyles } from "./ChatSidebarStyles";

var Filter = require("bad-words");

export default function ChatSidebar(): ReactElement {
  const { setCurrentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  const { chatMessages, toggleNewMessage }: MessageContextType =
    useContext(MessageContext);

  const { jumbotronFullscreenView }: PresenterContextType =
    useContext(PresenterContext);

  const [chatMessage, setChatMessage] = useState("");
  const [showEmojis, toggleShowEmojis] = useState(false);
  const [chatMessagesState, setChatMessagesState] = useState(chatMessages);

  const chatScrollTimer = useRef(null);
  const chatScrollTimerRunning = useRef(false);
  const scrollFunctionCalled = useRef(false);

  let filter = new Filter();

  useEffect(() => {
    //Check for mobile to use CSS hack for vh units
    let chatContentHolder = document.getElementById("chatContentHolder");
    if (chatContentHolder) {
      if (isMobile) {
        if (isPresenter) {
          chatContentHolder.classList.add(`${styles.chatContentHolderMobile}`);
        } else {
          chatContentHolder.classList.add(
            `${styles.chatContentHolderAttendeeMobile}`
          );
        }
      }
    }

    scrollChatWindowToTop();

    let chatWindow = document.getElementsByClassName(
      "indiana-scroll-container"
    )[0];
    //Listen for scrolling to reset chat scroll timer
    chatWindow.addEventListener("scroll", function (e) {
      setScrollTimer();
    });

    return function cleanup() {
      toggleNewMessage(false);

      if (chatScrollTimer.current) {
        clearTimeout(chatScrollTimer.current);
      }

      chatWindow.removeEventListener("scroll", function (e) {
        setScrollTimer();
      });
    };
  }, [isPresenter, toggleNewMessage]);

  useEffect(() => {
    //When chat message comes in, scroll to top if no timer running
    if (chatMessages.length > chatMessagesState.length) {
      //Scroll always for chat message we sent
      //Scroll to top for messages that are not ours when there is no timer
      if (
        chatMessages[chatMessages.length - 1].userID ===
          SHOWBOAT.LocalAvatarDataManager.userID ||
        chatScrollTimerRunning.current === false
      ) {
        scrollChatWindowToTop();
      }
    }

    setChatMessagesState(chatMessages);
  }, [chatMessages]);

  const handleChatMessageChange = (e) => {
    setChatMessage(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    //Don't allow submission if text field is blank
    if (chatMessage === "") return;

    //Refocus canvas if 2D mode is not open
    if (!jumbotronFullscreenView) {
      let aWindow: any = window;
      aWindow.babylonCanvas.focus();
    }

    //Submit message to server
    SHOWBOAT.SocketIOController.SendEventAll("chat", {
      msg: /* filter.clean(chatMessage) */ chatMessage,
      color: SHOWBOAT.LocalAvatarDataManager.avatarData.color,
      face: SHOWBOAT.LocalAvatarDataManager.avatarData.face,
      name: `${SHOWBOAT.LocalAvatarDataManager.avatarData.firstName} ${SHOWBOAT.LocalAvatarDataManager.avatarData.lastName}`,
      date: new Date(),
    });

    //Reset text field
    setChatMessage("");

    //Clear scroll timer
    if (chatScrollTimer.current) {
      clearTimeout(chatScrollTimer.current);
    }
    chatScrollTimerRunning.current = false;
  };

  const handleEmojiClick = () => {
    toggleShowEmojis(!showEmojis);
  };

  const handleEmojiSelect = (e) => {
    //parse emoji code from event object
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    //append emoji code to message input
    setChatMessage(`${chatMessage} ${emoji}`);

    //hide emoji picker
    toggleShowEmojis(false);
  };

  const setScrollTimer = () => {
    //Do not set timer for function calls that scroll the div, only user actions
    if (scrollFunctionCalled.current) {
      scrollFunctionCalled.current = false;
      return;
    }

    if (chatScrollTimer.current) {
      clearTimeout(chatScrollTimer.current);
    }

    chatScrollTimerRunning.current = true;

    chatScrollTimer.current = setTimeout(function () {
      chatScrollTimerRunning.current = false;
    }, 10000);
  };

  const scrollChatWindowToTop = () => {
    //Scroll chat window to top
    let chatWindow = document.getElementsByClassName(
      "indiana-scroll-container"
    )[0];

    if (chatWindow) {
      //Track that we scrolled from a function call, not user action
      scrollFunctionCalled.current = true;

      chatWindow.scrollTop = 0;
    }
  };

  const classes = ChatSidebarStyles();

  //Order so that newest chat messages appear at the top
  let chatItemsOrdered;

  chatItemsOrdered = orderBy(
    chatMessages,
    [(c) => (c as any).message.date],
    ["desc"]
  );

  let chatItemsComponentArray = [];

  chatItemsOrdered.forEach((item) => {
    chatItemsComponentArray.push(item);
  });

  return (
    <React.Fragment>
      <Paper className={classes.chatHeaderHolder}>
        <ChatIcon className={classes.chatIcon} />

        <Typography variant="h1" className={classes.chatHeader}>
          Chat
        </Typography>

        <IconButton
          className={classes.closeIconButton}
          onClick={() => setCurrentSidebarMode("")}
        >
          <RightArrowIcon />
        </IconButton>
      </Paper>

      <div
        className={
          isPresenter
            ? styles.chatContentHolder
            : `${styles.chatContentHolder} ${styles.chatContentHolderAttendee}`
        }
        id="chatContentHolder"
      >
        <div className={styles.chatMessageHolder}>
          <ScrollContainer
            hideScrollbars={false}
            horizontal={false}
            className={styles.scrollContainer}
          >
            {chatItemsComponentArray.map((chatMessage, i) => {
              return (
                <ChatMessage
                  key={(chatMessage as any).id}
                  msg={chatMessage.message.msg}
                  name={chatMessage.message.name}
                  color={chatMessage.message.color}
                  face={chatMessage.message.face}
                  date={chatMessage.message.date}
                  id={(chatMessage as any).id}
                  mobile={false}
                />
              );
            })}
          </ScrollContainer>
        </div>

        {showEmojis && (
          <div className={styles.emojiSelectHolder}>
            <Picker
              theme={SHOWBOAT.ApplicationSkin.theme}
              onSelect={(e) => handleEmojiSelect(e)}
              style={{ width: "100%" }}
            />
          </div>
        )}

        <Paper className={classes.chatInputHolderBackdrop}></Paper>

        <Paper className={classes.chatMessageInputHolder}>
          <TextField
            multiline
            rows={3}
            value={chatMessage}
            variant="outlined"
            onChange={handleChatMessageChange}
            className={classes.chatTextField}
            placeholder="Send a message"
            inputProps={{
              maxLength: 240,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleFormSubmit(e);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.emojiIcon}>
                  <EmojiIcon onClick={handleEmojiClick} />
                </InputAdornment>
              ),
              classes: {
                notchedOutline: classes.noBorder,
                root: classes.chatInput,
                inputAdornedEnd: classes.chatInputAdornedEnd,
              },
              type: "text",
            }}
          ></TextField>

          <Typography variant="body1" className={classes.chatHelperText}>
            This message will be public
          </Typography>

          <Typography variant="body1" className={classes.characterCount}>
            {`${chatMessage.length} / 240`}
          </Typography>
        </Paper>
      </div>
    </React.Fragment>
  );
}
