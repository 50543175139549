import React, { ReactElement, useState, useContext, useEffect, useRef } from 'react';
import $ from 'jquery';

import TeleportMessagePopup from '../TeleportMessagePopup/TeleportMessagePopup';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import ErrorPopup from '../ErrorPopup/ErrorPopup';
import EventEndingPopup from '../EventEndingPopup/EventEndingPopup';
import NewRoomPopup from '../NewRoomPopup/NewRoomPopup';
import { RoomAvailabilityContext, RoomAvailabilityContextType } from '../../../../context/RoomAvailabilityContext';
import { ErrorContext, ErrorContextType } from '../../../../context/ErrorContext';
import { PresenterContext, PresenterContextType } from '../../../../context/PresenterContext';
import { StringVariableHelper } from '../../../../Utilities/StringVariableHelper';
import ForceMovePopup from '../ForceMovePopup/ForceMovePopup';

import styles from "./NotificationsWrapper.module.css";
import { ServerHelper } from '../../../../Utilities/ServerHelper';

interface NotificationsWrapperError {
  type: string,
  data: Object
}

export default function NotificationsWrapper(): ReactElement {

  //access context
  const {
    isTeleportationBlocked
  } : AppContextType = useContext(AppContext);

  const {
    hasServerError,
    hasVideoError,
    toggleHasServerError,
    toggleHasVideoError
  } : ErrorContextType = useContext(ErrorContext);

  const {
    isBackstage,
    isOnStage,
  } : PresenterContextType = useContext(PresenterContext);

  const {
    toggleHasBeenShownNewRoomPopup,
    hasBeenShownNewRoomPopup,
  } : RoomAvailabilityContextType = useContext(RoomAvailabilityContext);

  /* State store */
  const [notifications, setNotifications] = useState([]);
  /**/

  const _notifications = useRef([]);

  const teleportationBlockedRef = useRef();
  const isOnStageRef = useRef();
  const isBackstageRef = useRef();

  const hasBeenShownNewRoomRef = useRef(false);

  const hasServerErrorRef = useRef(false);
  const hasVideoErrorRef = useRef(false);

  useEffect(() => {
    hasServerErrorRef.current = hasServerError;
  }, [hasServerError]);

  useEffect(() => {
    hasVideoErrorRef.current = hasVideoError;
  }, [hasVideoError])

  let errorID : number = 0;
  let eventEndingID : number = 0;
  let forceMoveID : number = 0;
  let popupKey : number = 0;

  $(document).ready(function () {
    $('.dismissButton').on("click", function (e) {
      var $thisParent = $(this).closest('div');
      $thisParent.nextAll().each(function (index) {
        $(this).stop().animate({
          top: `-=${$thisParent.outerHeight(true)}`
        }, 300)
      });
    });
  })

  useEffect(() => {

    (teleportationBlockedRef.current as any) = isTeleportationBlocked;

  }, [isTeleportationBlocked]);

  useEffect(() => {

    (isOnStageRef.current as boolean) = isOnStage;

  }, [isOnStage])

  useEffect(() => {

    (isBackstageRef.current as boolean) = isBackstage;

  }, [isBackstage])

  useEffect(() => {
    SHOWBOAT.SocketIOController.OnTeleportRequest.Add(handleReceiveTeleportRequest);
    SHOWBOAT.UIEventManager.OnUIError.Add(handleUIError);
    SHOWBOAT.UIEventManager.OnForceMove.Add(handleForceMove);
    SHOWBOAT.SocketIOController.OnEventEndingWarning.Add(handleEventEndingWarning);
    SHOWBOAT.SocketIOController.OnRoomOpened.Add(handleRoomOpen);
    SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Add(handleServerConnectionErrorToggle);
    SHOWBOAT.LiveswitchUpstreamController.OnVideoConnectionError.Add(handleVideoConnectionErrorToggle);
    return function cleanup() {
      SHOWBOAT.SocketIOController.OnTeleportRequest.Remove(handleReceiveTeleportRequest);
      SHOWBOAT.UIEventManager.OnUIError.Remove(handleUIError);
      SHOWBOAT.SocketIOController.OnEventEndingWarning.Remove(handleEventEndingWarning);
      SHOWBOAT.SocketIOController.OnRoomOpened.Remove(handleRoomOpen);
      SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Remove(handleServerConnectionErrorToggle);
      SHOWBOAT.LiveswitchUpstreamController.OnVideoConnectionError.Remove(handleVideoConnectionErrorToggle);

      $(".dismissButton").off("click");
    }
  }, []);


  /* SERVER DISCONNECT */
  const handleServerConnectionErrorToggle = (isDisplayed: boolean) => {

    //do nothing if server error already shown
    if (hasServerErrorRef.current) {
      return;
    } else {
      if (isDisplayed) {
        toggleHasServerError(true);

        let newError : NotificationsWrapperError = {
          type: 'error',
          data: {
            message: "Temporarily disconnected from server, attempting auto reconnect.",
            errorID: errorID,
            popupKey: popupKey,
          },
        };

        $(".notification").animate({
          top: "+=100px",
        }, 300);

        _notifications.current = [newError, ..._notifications.current];
        setNotifications(_notifications.current);

        errorID = errorID + 1;
        popupKey = popupKey + 1;

      }
    }

  }
  /**/

  /* VIDEO DISCONNECT */
  const handleVideoConnectionErrorToggle = (isDisplayed: boolean) => {

    //do nothing if video error already shown
    if (hasVideoErrorRef.current) {
      return;
    } else {
      if (isDisplayed) {
        toggleHasVideoError(true);

        let newError : NotificationsWrapperError = {
          type: 'error',
          data: {
            message: "Your video stream is temporarily interrupted, attempting auto reconnect.",
            errorID: errorID,
            popupKey: popupKey,
          },
        };

        $(".notification").animate({
          top: "+=100px",
        }, 300);

        _notifications.current = [newError, ..._notifications.current];
        setNotifications(_notifications.current);

        errorID = errorID + 1;
        popupKey = popupKey + 1;

      }
    }
  }
  /**/

  /*TELEPORT REQUESTS*/
  const handleReceiveTeleportRequest = (request: SHOWBOAT.TeleportRequest) => {

    //auto decline if teleportation blocked
    if (teleportationBlockedRef.current) {

      SHOWBOAT.SocketIOController.ReplyToTeleportRequest(request.requestingUserID, false);

    }
    //auto decline if presenter is on stage
    else if (isOnStageRef.current) {

      SHOWBOAT.SocketIOController.ReplyToTeleportRequest(request.requestingUserID, false);

    } else if (ServerHelper.useMobileApp) {
      //TODO: TODO: auto-decline requests if this user is on mobile UI
    }
    //Auto decline if backstage, and sender is not backstage
    else if ((isBackstageRef.current) 
    && (SHOWBOAT.RemoteAvatarDataManager.getAvatarData(request.requestingUserID).partition 
    !== StringVariableHelper.ShowboatPartitions.backstage)) {

      SHOWBOAT.SocketIOController.ReplyToTeleportRequest(request.requestingUserID, false);

    }
    else {

      $(".notification").animate({
        top: "+=132px",
      }, 300);

      _notifications.current = [{ type: 'teleport', data: request }, ..._notifications.current];
      setNotifications(_notifications.current);

    }
  };

  const handleRespondToTeleportRequest = (respondingToUserID: string) => {

    _notifications.current = _notifications.current.filter(function (notification) {
      return (notification.data.requestingUserID !== respondingToUserID);
    });
    setNotifications(_notifications.current);
  }

  //REMOVE REQUEST WHEN ATTENDEE LEAVES
  const reactToAttendeeLeaving = (leavingUserID: string) => {

    if (notifications.length === 0) {
      return;
    } else {
      let boolean : Boolean;

      _notifications.current.map(notification => {

        if (notification.type === 'teleport') {
          if (notification.data.requestingUserID === leavingUserID) {
            boolean = true;
          }
        };

        return null;
      });

      if (boolean) {
        _notifications.current = _notifications.current.filter(function (notification) {
          return (notification.data.requestingUserID !== leavingUserID);
        });
      } else {
        return;
      }
    }

    setNotifications(_notifications.current);

  }
  /**/

  /**UI ERRORS*/
  const handleUIError = (error: string) => {

    let newError: NotificationsWrapperError;

    if (error === "Teleporting to this person is disabled while you are backstage") {
      newError = {
        type: 'error',
        data: {
          message: error,
          errorID: errorID,
          popupKey: popupKey,
        }
      };

      $(".notification").animate({
        top: "+=100px",
      }, 300);

    } else {
      newError  = {
        type: 'error',
        data: {
          message: error,
          errorID: errorID,
          popupKey: popupKey,
        },
      };

      $(".notification").animate({
        top: "+=80px",
      }, 300);
    }


    _notifications.current = [newError, ..._notifications.current];
    setNotifications(_notifications.current);

    errorID = errorID + 1;
    popupKey = popupKey + 1;

  };

  const handleUIErrorClose = (errorID: number) => {

    let clickedElementPosition = _notifications.current.findIndex(notif => notif.data.errorID === errorID);

    let lowerArray = [];

    for (let i = 0; i <= _notifications.current.length; i++) {
      if (i > clickedElementPosition) {
        lowerArray.push(_notifications.current[i]);
      }
    };

    _notifications.current = _notifications.current.filter(function (notification) {
      return (notification.data.errorID !== errorID);
    });
    setNotifications(_notifications.current);

  }
  /**/

  /** FORCE MOVE NOTIFICATIONS */
  const handleForceMove = (seconds: number) => {
    let notification = {
      type: "force move",
      data: {
        popupKey: popupKey,
        forceMoveID: forceMoveID,
        seconds
      }
    }

    $(".notification").animate({
      top: "+=100",
    }, 300);

    _notifications.current = [notification, ..._notifications.current];
    setNotifications(_notifications.current);

    forceMoveID = forceMoveID + 1;
    popupKey = popupKey + 1;
  }

  const handleForceMoveClose = (forceMoveID: number) => {
    _notifications.current = _notifications.current.filter(function (
      notification
    ) {
      return notification.data.forceMoveID !== forceMoveID;
    });

    setNotifications(_notifications.current);
  }
  /**/

  /*EVENT ENDING NOTIFICATIONS*/

  const handleEventEndingWarning = () => {
    let notification = {
      type: 'event ending',
      data: {
        eventEndingID: eventEndingID,
        popupKey: popupKey
      }
    };

    $(".notification").animate({
      top: "+=100",
    }, 300);

    _notifications.current = [notification, ..._notifications.current];
    setNotifications(_notifications.current);

    eventEndingID = eventEndingID + 1;
    popupKey = popupKey + 1;
  }

  const handleEventEndingClose = (eventEndingID: number) => {
    _notifications.current = _notifications.current.filter(function (notification) {
      return (notification.data.eventEndingID !== eventEndingID);
    });
    setNotifications(_notifications.current);
  }

  /**/

  /** NEW ROOM NOTIFICATION */

  const handleRoomOpen = (roomOpeningObj: SHOWBOAT.RoomOpening) => {

    if (hasBeenShownNewRoomRef.current) {
      return;
    }

    //Check if the room is our current room
    if (roomOpeningObj.roomID === SHOWBOAT.LocalAvatarDataManager.roomID) {
      return;
    }

    //set context has-been-shown new room to true

    if (roomOpeningObj.totalRoomCount === 2) {

      let notification = {
        type: 'room',
        data: {
          message: 'room',
          popupKey: popupKey,
        }
      };

      $(".notification").animate({
        top: "+=100px",
      }, 300);

      _notifications.current = [notification, ..._notifications.current];
      setNotifications(_notifications.current);

      popupKey = popupKey + 1;

      toggleHasBeenShownNewRoomPopup(true);

    } else {
      return;
    }

  };

  useEffect(() => {

    hasBeenShownNewRoomRef.current = hasBeenShownNewRoomPopup;

  }, [hasBeenShownNewRoomPopup])

  const handleRoomOpenClose = (popupKey: number) => {
    _notifications.current = _notifications.current.filter(function (notification) {
      return (notification.data.popupKey !== popupKey);
    });
    setNotifications(_notifications.current);
  }

  /**/

  return (
    <>
      <div
        className={SHOWBOAT.LocalAvatarDataManager.role === StringVariableHelper.ShowboatRoles.presenter
        ? `${styles.notificationsHolder} ${styles.notificationsHolderPresenter}`
        : styles.notificationsHolder
      }
        id="notificationWrapper"
      >

        {/* <Button
          onClick={handleForceMove}
          variant="contained"
          style={{
            zIndex: 9999,
          }}
        >
          TEST FORCE MOVE
        </Button> */}
        {notifications.map((notification, i) => {
          if (notification.type === 'teleport') {
            return (
              <TeleportMessagePopup
                reactToAttendeeLeaving={reactToAttendeeLeaving}
                respondToTeleportRequest={handleRespondToTeleportRequest}
                requestingUserID={notification.data.requestingUserID}
                toUserId={notification.data.toUserId}
                id={i}
                key={notification.data.requestingUserID}
              />
            )
          } else if (notification.type === 'error') {
            return (
              <ErrorPopup
                key={notification.data.popupKey}
                errorID={notification.data.errorID}
                handleErrorClose={handleUIErrorClose}
                message={notification.data.message}
              />
            )
          } else if (notification.type === 'event ending') {
            return (
              <EventEndingPopup
                handleEventEndingClose={handleEventEndingClose}
                eventEndingID={notification.data.eventEndingID}
                key={notification.data.popupKey}
              />
            )
          } else if (notification.type === 'room') {
            return (
              <NewRoomPopup
                handlePopupClose={handleRoomOpenClose}
                popupKey={notification.data.popupKey}
                key={notification.data.popupKey}
              />
            )
          } else if (notification.type === "force move") {
            return (
              <ForceMovePopup
                forceMoveID={notification.data.forceMoveID}
                countdownSeconds={Math.floor(notification.data.seconds)}
                handleForceMoveClose={handleForceMoveClose}
                key={notification.data.popupKey}
              />
            )
          }

          return null;
        })}
      </div>
    </>
  )
}
