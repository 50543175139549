import React, { ReactElement, useState, useContext, useEffect } from "react";
import { Button, Link, Paper, Typography } from "@material-ui/core";
import PlayIcon from "@material-ui/icons/PlayCircleFilledOutlined";

import EventCountdown from "../EventCountdown/EventCountdown";
import { IntakeContext, IntakeContextType } from "../../context/IntakeContext";

import styles from "./WelcomeElement.module.css";
import { WelcomeElementStyles } from "./WelcomeElementStyles";
import { isMobile } from "react-device-detect";
import { ServerHelper } from "../../Utilities/ServerHelper";
import { StringVariableHelper } from "../../Utilities/StringVariableHelper";
import MobileNotSupportedError from "./MobileNotSupportedError/MobileNotSupportedError";

interface Props {
  failReason: string;
  onEnterClick: () => void;
  enterClicked?: boolean;
}

export default function WelcomeElement(props: Props): ReactElement {
  const { homeWasClicked, unblockEnter }: IntakeContextType =
    useContext(IntakeContext);

  const [showPlayButton, toggleShowPlayButton] = useState(true);

  const [mobileButtonDisable, setMobileButtonDisable] = useState(true);

  const showMobileUnsupported =
    !ServerHelper.enableMobile && !ServerHelper.useMobileHybridApp;

  useEffect(() => {
    //Check if button should be disabled
    if (
      ServerHelper.loginFailReason !==
      StringVariableHelper.LoginFailReasons.NotStarted
    ) {
      setMobileButtonDisable(false);
    }
  }, []);

  const handleVideoPlayClick = () => {
    let video = document.getElementById("welcomeVideo") as HTMLVideoElement;

    video.play();

    toggleShowPlayButton(false);
  };

  const checkIfHideEnterButton = () => {
    //If fail reason is DeletedLoginCode, hide enter button
    if (
      ServerHelper.loginFailReason ===
      StringVariableHelper.LoginFailReasons.DeletedLoginCode
    )
      return true;

    //Hide for capacity full
    if (
      ServerHelper.loginFailReason ===
      StringVariableHelper.LoginFailReasons.CapacityFull
    ) {
      return true;
    }

    //Do not hide for any other fail reasons aside from EventEnded (or no fail reason)
    if (
      ServerHelper.loginFailReason !==
      StringVariableHelper.LoginFailReasons.Ended
    )
      return false;

    //FailReason is equal to "Ended"

    //Check if doors closed is enabled and if the current time is after that time

    //Hide the button if we are not using doors closed time
    if (!ServerHelper.enableDoors) return true;

    //Hide button if no doors closed time in ServerHelper
    if (!ServerHelper.doorsClosed) return true;

    let currentTime = new Date().getTime();
    let doorsClosedTime = new Date(ServerHelper.doorsClosed).getTime();

    //If current time is less than doors closed time, show enter button
    if (currentTime < doorsClosedTime) {
      return false;
    } else {
      //Hide button if current time is the same or greater than doors closed time
      return true;
    }
  };

  const classes = WelcomeElementStyles();

  let welcomeElement: ReactElement;

  if (SHOWBOAT.ApplicationSkin.landingPageGraphicType === "Video") {
    welcomeElement = (
      <Paper
        elevation={0}
        classes={{
          root: ServerHelper.useMobileApp
            ? props.failReason ===
                StringVariableHelper.LoginFailReasons.CapacityFull ||
              props.failReason ===
                StringVariableHelper.LoginFailReasons.DeletedLoginCode ||
              props.failReason === StringVariableHelper.LoginFailReasons.Ended
              ? `${classes.welcomeElementHolder} ${classes.welcomeElementHolderMobile} ${classes.welcomeElementHolderMobileError}`
              : `${classes.welcomeElementHolder} ${classes.welcomeElementHolderMobile}`
            : props.failReason === "NotStarted" && !homeWasClicked
            ? `${classes.welcomeElementHolder} ${classes.welcomeElementHolderCountdown}`
            : `${classes.welcomeElementHolder}`,
        }}
      >
        <video
          controls
          id="welcomeVideo"
          className={styles.welcomeVideo}
          src={SHOWBOAT.ApplicationSkin.landingPageGraphicURL}
        ></video>

        {showPlayButton === true && (
          <PlayIcon
            className={classes.playIcon}
            onClick={handleVideoPlayClick}
          />
        )}
      </Paper>
    );
  } else {
    welcomeElement = (
      <Paper
        elevation={0}
        classes={{
          root: ServerHelper.useMobileApp
            ? props.failReason ===
              StringVariableHelper.LoginFailReasons.CapacityFull
              ? `${classes.welcomeElementHolder} ${classes.welcomeElementHolderMobile} ${classes.welcomeElementHolderMobileErrorCapacityFull}`
              : props.failReason ===
                  StringVariableHelper.LoginFailReasons.DeletedLoginCode ||
                props.failReason === StringVariableHelper.LoginFailReasons.Ended
              ? `${classes.welcomeElementHolder} ${classes.welcomeElementHolderMobile} ${classes.welcomeElementHolderMobileError}`
              : `${classes.welcomeElementHolder} ${classes.welcomeElementHolderMobile}`
            : props.failReason === "NotStarted" && !homeWasClicked
            ? `${classes.welcomeElementHolder} ${classes.welcomeElementHolderCountdown}`
            : `${classes.welcomeElementHolder}`,
        }}
      >
        <img
          className={
            ServerHelper.useMobileApp
              ? `${styles.welcomeImage} ${styles.welcomeImageMobile}`
              : styles.welcomeImage
          }
          crossOrigin="anonymous"
          alt="welcome page element"
          src={SHOWBOAT.ApplicationSkin.landingPageGraphicURL}
        />

        <div
          className={
            ServerHelper.useMobileApp
              ? props.failReason === "NotStarted"
                ? `${styles.linkHolder} ${styles.linkHolderMobile} ${styles.linkHolderMobileNotStarted}`
                : checkIfHideEnterButton()
                ? `${styles.linkHolder} ${styles.linkHolderMobile} ${styles.linkHolderMobileNoEnterButton}`
                : `${styles.linkHolder} ${styles.linkHolderMobile}`
              : props.failReason === "NotStarted"
              ? homeWasClicked
                ? styles.linkHolder
                : `${styles.linkHolder} ${styles.linkHolderNotStarted}`
              : styles.linkHolder
          }
        >
          <Link
            className={classes.link}
            target="_blank"
            href="https://showboat.live/privacy"
            style={{
              marginRight: 7,
            }}
          >
            Privacy
          </Link>
          <Typography style={{ fontSize: 10, display: "inline" }}>•</Typography>
          <Link
            className={classes.link}
            target="_blank"
            href="https://showboat.live/terms"
            style={{
              marginLeft: 7,
            }}
          >
            Terms of Use
          </Link>
        </div>
      </Paper>
    );
  }

  return (
    <React.Fragment>
      {ServerHelper.useMobileApp && (
        <div className={styles.eventTimeHolder}>
          <Typography variant="h2" classes={{ root: classes.eventDate }}>
            {!props.enterClicked &&
              !ServerHelper.bypass &&
              SHOWBOAT.ApplicationSkin.eventTime}
          </Typography>
        </div>
      )}

      {/* Check if event countdown needs to be rendered */}
      {props.failReason === "NotStarted" && !homeWasClicked ? (
        <React.Fragment>

          {/*  Ensure mobile rejection still gets rendered if not supported (and if not on hybrid code) */}
          {ServerHelper.useMobileApp && showMobileUnsupported ? (
            <MobileNotSupportedError
              holderClasses={`${classes.welcomeElementHolder} ${classes.welcomeElementHolderMobile}`}
            />
          ) : (
            <React.Fragment>
              <EventCountdown />

              {welcomeElement}

              {ServerHelper.useMobileApp && (
                <Button
                  onClick={props.onEnterClick}
                  className={`${classes.mobileEnterButton} ${classes.mobileEnterButtonNotStarted}`}
                  disabled={!unblockEnter && mobileButtonDisable}
                >
                  ENTER
                </Button>
              )}
            </React.Fragment>
          )}

        </React.Fragment>
      ) : (
        <React.Fragment>
          {ServerHelper.useMobileApp ? (
            <React.Fragment>
              {!showMobileUnsupported ? (
                <React.Fragment>
                  {welcomeElement}

                  {!checkIfHideEnterButton() && (
                    <Button
                      className={
                        ServerHelper.loginFailReason ===
                        StringVariableHelper.LoginFailReasons.Ended
                          ? `${classes.mobileEnterButton} ${classes.mobileEnterButtonEnded}`
                          : classes.mobileEnterButton
                      }
                      onClick={props.onEnterClick}
                      disabled={!unblockEnter && mobileButtonDisable}
                      onTouchStart={props.onEnterClick}
                    >
                      ENTER
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                <MobileNotSupportedError
                  holderClasses={`${classes.welcomeElementHolder} ${classes.welcomeElementHolderMobile}`}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>{welcomeElement}</React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
