import { makeStyles, createStyles } from '@material-ui/core/styles';

const RoomOptionsSidebarItemStyles = makeStyles((theme: any) => createStyles({
  roomItemHolder: {
    width: "100%",
    position: "relative",
    height: 50,
    backgroundColor: theme.palette.controlCenterButton.main,
    marginBottom: 5,
  },
  roomOptionName: {
    fontWeight: 400,
    fontSize: 16,
    position: "absolute",
    top: 14,
    left: 16,
    color: theme.palette.text.primary
  },
  changeRoomIconButton: {
    padding: 6,
    position: "absolute",
    top: 7,
    right: 10,
  },
  peopleIcon: {
    color: theme.palette.roomTabIcon.main,
    position: "absolute",
    top: 14,
    left: 105,
    fontSize: 20,

  },
  roomOptionCount: {
    fontSize: 16,
    position: "absolute",
    left: 131,
    bottom: 13,
  }
})
)

export { RoomOptionsSidebarItemStyles };